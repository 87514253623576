import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  Input,
  InputGroup,
  Stack,
  Tooltip,
  useBoolean,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Legend,
  BarElement,
} from "chart.js";
import {
  getBuildingFacilityReportCost,
  getBuildingUtilityInfoDetail,
  refetchBuildingUtilityInfo,
} from "../api";
import {
  IBuildingEnergyInfo,
  IBuildingEnergyInfoTotal,
  IReportCost,
  ITinyReport,
} from "../types";
import AddWaterUtilityModal from "../components/modal/AddWaterUtilityModal";
import BuildingUtilityDataModal from "../components/modal/BuildingUtilityDataModal";

export default function Utility() {
  const { buildingId } = useParams();
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const {
    isOpen: isUtilityDataModalOpen,
    onClose: onUtilityDataModalClose,
    onOpen: onUtilityDataModalOpen,
  } = useDisclosure();
  const toast = useToast();
  const [startDate, setStartDate] = useState(
    moment().subtract(15, "months").format("YYYY-MM")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(3, "months").format("YYYY-MM")
  );
  const [monthToggle, setMonthToggle] = useBoolean(true);
  const [yearToggle, setYearToggle] = useBoolean(false);
  const [searchType, setSearchType] = useState<string>("month");
  const [chartData, setChartData] = useState<any[]>([]);
  const [chartTotalData, setChartTotalData] = useState<any>(undefined);
  const [costData, setCostData] = useState<IReportCost[]>([]);

  const onMonthClick = () => {
    setSearchType("month");
    setMonthToggle.on();
    setYearToggle.off();
  };
  const onYearClick = () => {
    setSearchType("year");
    setYearToggle.on();
    setMonthToggle.off();
  };
  const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };
  const onModalOpen = () => {
    if (buildingId) {
      onUtilityDataModalOpen();
    } else {
      toast({
        status: "error",
        title: "건물을 선택해주세요.",
      });
    }
  };
  const {
    isPending: isBuildingUtilityDetailLoading,
    data: buildingUtilityDetailData,
    refetch: buildingUtilityInfoDetailRefetch,
  } = useQuery<IBuildingEnergyInfo[] | IBuildingEnergyInfoTotal[]>({
    queryKey: [
      "building_utility_info_detail",
      buildingId,
      startDate,
      endDate,
      searchType,
    ],
    queryFn: getBuildingUtilityInfoDetail,
    // enabled: false,
    refetchOnWindowFocus: false,
  });
  const {
    data: buildingFacilityReportData,
    refetch: buildingFacilityReportRefetch,
  } = useQuery<IReportCost[]>({
    queryKey: [
      "building_report_cost",
      buildingId,
      startDate,
      endDate,
      searchType,
    ],
    queryFn: getBuildingFacilityReportCost,
    // enabled: false,
    refetchOnWindowFocus: false,
  });
  const mutation = useMutation({
    mutationKey: ["refetchBuildingUtilityInfo"],
    mutationFn: refetchBuildingUtilityInfo,
    onSuccess: () => {
      console.log("에너지 정보 호출 완료");
      buildingUtilityInfoDetailRefetch();
      Sentry.captureMessage("에너지 정보 다시 가져오기 완료", "log");
      // buildingFacilityReportRefetch();
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("에너지 정보 저장 실패! 서버 오류");
      toast({
        status: "error",
        title:
          "에너지 정보 가져오기에 실패했습니다. 잠시 후 다시 시도해 주세요.",
      });
      Sentry.captureException(e.response.data);
    },
  });
  const isRefetchMutating = useIsMutating({
    mutationKey: ["refetchBuildingUtilityInfo"],
  });
  const onClickSearch = () => {
    if (buildingId) {
      if (moment(endDate).diff(startDate, "years", true) > 1) {
        toast({
          status: "error",
          title: "1년 이하로 기간을 설정해주세요.",
        });
      } else {
        buildingUtilityInfoDetailRefetch();
        buildingFacilityReportRefetch();
      }
    } else {
      toast({
        status: "error",
        title: "건물을 선택해주세요",
      });
    }
  };
  const onClickRefetch = () => {
    if (buildingId) {
      mutation.mutate({
        building_id: buildingId,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };
  useEffect(() => {
    setChartData([]);
    setChartTotalData(undefined);
    if (buildingUtilityDetailData) {
      if (searchType === "month") {
        setChartData(buildingUtilityDetailData);
      } else {
        setChartTotalData(buildingUtilityDetailData[0]);
      }
    }
  }, [buildingUtilityDetailData, searchType]);

  useEffect(() => {
    setCostData([]);
    if (buildingFacilityReportData) {
      setCostData(buildingFacilityReportData);
    }
  }, [buildingFacilityReportData]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    ChartTooltip,
    Legend
  );

  const electricityOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1 | 2,
    // responsive: false,
    plugins: {
      title:
        searchType === "month"
          ? {
              display: true,
              text: "월 전기 사용량",
              padding: 5,
            }
          : {
              display: true,
              text: "총 전기 사용량",
              padding: 20,
            },
      legend:
        searchType === "month"
          ? {
              position: "top" as const,
              labels: {
                usePointStyle: true,
                // 범례 도형 모양과 관련된 속성으로, false일 경우엔 기본 직사각형 도형으로 표시됩니다.
                padding: 10,
                // 범례 간 가로 간격을 조정할 수 있습니다. 범례의 상하 padding을 지정하는 기능은 따로 지원되지 않아요. ㅠㅠ

                font: {
                  // 범례의 폰트 스타일도 지정할 수 있습니다.
                  family: "'Noto Sans KR', 'serif'",
                  lineHeight: 1,
                },
              },
            }
          : {
              display: false,
            },
      tooltip: {
        // 툴팁 스타일링
        // backgroundColor: "rgba(194, 133, 0, 0.4)",
        backgroundColor: "rgba(110, 126, 152, 0.9)",
        padding: 10,
        bodySpacing: 10,
        font: {
          family: "'Noto Sans KR', sans-serif",
          weight: "bold",
        },
        usePointStyle: true,
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: (context: any) => {
            // 툴팁에서 x축 값
            let title = "";

            return title;
          },
          label: (context: any) => {
            if (searchType === "year") {
              //   console.log(context);
              //   return context.label + ": " + context.formattedValue;
              return " " + context.formattedValue;
            }
          },
        },
      },
    },
  };
  const gasOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1 | 2,
    // responsive: false,
    plugins: {
      title:
        searchType === "month"
          ? {
              display: true,
              text: "월 가스 사용량",
              padding: 5,
            }
          : {
              display: true,
              text: "총 가스 사용량",
              padding: 20,
            },
      legend:
        searchType === "month"
          ? {
              position: "top" as const,
              labels: {
                usePointStyle: true,
                padding: 10,
                font: {
                  family: "'Noto Sans KR', 'serif'",
                  lineHeight: 1,
                },
              },
            }
          : {
              display: false,
            },
      tooltip: {
        // 툴팁 스타일링
        backgroundColor: "rgba(110, 126, 152, 0.9)",
        padding: 10,
        bodySpacing: 10,

        font: {
          family: "'Noto Sans KR', sans-serif",
          weight: "bold",
        },
        usePointStyle: true,
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: (context: any) => {
            let title = "";
            return title;
          },
          label: (context: any) => {
            if (searchType === "year") {
              //   console.log(context);
              //   return context.label + ": " + context.formattedValue;
              return " " + context.formattedValue;
            }
          },
        },
      },
    },
  };
  const waterOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1 | 2,
    // responsive: false,
    plugins: {
      title:
        searchType === "month"
          ? {
              display: true,
              text: "월 수도 사용량",
              padding: 5,
            }
          : {
              display: true,
              text: "총 수도 사용량",
              padding: 20,
            },
      legend:
        searchType === "month"
          ? {
              position: "top" as const,
              labels: {
                usePointStyle: true,
                padding: 10,
                font: {
                  family: "'Noto Sans KR', 'serif'",
                  lineHeight: 1,
                },
              },
            }
          : {
              display: false,
            },
      tooltip: {
        // 툴팁 스타일링
        backgroundColor: "rgba(110, 126, 152, 0.9)",
        padding: 10,
        bodySpacing: 10,

        font: {
          family: "'Noto Sans KR', sans-serif",
          weight: "bold",
        },
        usePointStyle: true,
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: (context: any) => {
            let title = "";
            return title;
          },
          label: (context: any) => {
            if (searchType === "year") {
              //   console.log(context);
              //   return context.label + ": " + context.formattedValue;
              return " " + context.formattedValue;
            }
          },
        },
      },
    },
  };
  const spendOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1 | 2,
    // responsive: false,
    plugins: {
      title:
        searchType === "month"
          ? {
              display: true,
              text: "월 소비량",
              padding: 5,
            }
          : {
              display: true,
              text: "총 소비량",
              padding: 20,
            },
      legend:
        searchType === "month"
          ? {
              position: "top" as const,
              labels: {
                usePointStyle: true,
                padding: 10,
                font: {
                  family: "'Noto Sans KR', 'serif'",
                  lineHeight: 1,
                },
              },
            }
          : {
              display: false,
            },
      tooltip: {
        enabled: false,
        intersect: false,
        filter: (item: any) => item.parsed.y !== null,
        callbacks: {
          title: (context: any) => {
            let title = "";
            return title;
          },
          label: (context: any) => {
            if (searchType === "month") {
              const tooltipData = costData.map((d) => d.data);
              // console.log(tooltipData[context.dataIndex]);
              var multistringText: any[] = [];
              tooltipData[context.dataIndex]?.map((data: ITinyReport) =>
                data.cost !== 0
                  ? multistringText.push(
                      moment(data.report_date).format("MM/DD") +
                        " " +
                        data.report_type +
                        " [" +
                        data.report_name +
                        "] > " +
                        data.cost.toLocaleString() +
                        " 원"
                    )
                  : null
              );

              if (multistringText.length > 0) return multistringText;
            }
            if (searchType === "year") {
              //   return context.label + ": " + context.formattedValue;
              return " " + context.formattedValue;
            }
          },
        },
        // 커스텀 툴팁
        external: function (context: any) {
          var tooltipModel = context.tooltip;
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Hide if no tooltip
          if (tooltipEl) {
            // 스크롤 가능한 툴팁은 제외
            const isScrollTooltip =
              tooltipEl.querySelectorAll("tr").length > 4 ? true : false;
            if (tooltipModel.opacity === 0) {
              if (!isScrollTooltip) {
                tooltipEl.style.opacity = "0";
                return;
              }
            }
          }

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            // tooltipEl.classList.add("scrollbar");
            document.body.appendChild(tooltipEl);
          }

          function getBody(bodyItem: any) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = "<thead>";

            titleLines.forEach(function (title: string) {
              innerHtml += "<tr><th>" + title + "</th></tr>";
            });
            innerHtml += "</thead><tbody >";

            bodyLines[0].forEach(function (body: string, i: number) {
              var colors = tooltipModel.labelColors[0];
              var style = "background:" + colors.backgroundColor;
              style += "; border-color:" + colors.borderColor;
              style += "; border-width: 2px !important";
              style += "; width: 10px !important";
              style += "; height: 10px !important";
              style += "; display: inline-block !important";
              style += "; margin-right: 3px !important";
              var box = `<span style="${style}"></span>`;
              innerHtml += `<tr><td>${box}${body}</td></tr>`;
            });
            innerHtml += "</tbody>";

            var tableRoot = tooltipEl.querySelector("table");
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }
          var position = context.chart.canvas.getBoundingClientRect();
          const { offsetLeft: positionX, offsetTop: positionY } =
            context.chart.canvas;

          // Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left = positionX + tooltipModel.caretX - 50 + "px";
          tooltipEl.style.top = positionY + tooltipModel.caretY + "px";
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = "13px";
          tooltipEl.style.fontStyle = "bold";
          tooltipEl.style.padding = "10px " + "10px";
          tooltipEl.style.color = "white";
          tooltipEl.style.borderRadius = "4px";
          tooltipEl.style.backgroundColor = "rgba(110, 126, 152, 0.9)";
          tooltipEl.style.maxHeight = "100px";
          tooltipEl.style.overflowY = "auto";
          tooltipEl.style.scrollBehavior = "smooth";
          tooltipEl.style.transition = "all .1s ease";
          tooltipEl.style.transform = "translate(-50%, 0)";
        },
      },
    },
  };
  const labels =
    searchType === "month"
      ? chartData?.map((data) => data.search_date)
      : chartTotalData
      ? chartTotalData.years
      : [];

  const spendLabels = costData.map((data) => data.search_date);
  // const spendLabels = ["2023-11", "2023-12", "2024-01", "2024-02"];

  const electricityData = {
    labels,
    datasets:
      !isBuildingUtilityDetailLoading && searchType === "month"
        ? [
            {
              label: "전년도 사용량",
              data: chartData
                ? chartData.map((data) => data.electricity_usage_past)
                : [],
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "전기사용량",
              data: chartData
                ? chartData.map((data) => data.electricity_usage)
                : [],
              borderColor: "rgb(53, 162, 235)",
              backgroundColor: "rgba(53, 162, 235, 0.5)",
            },
          ]
        : [
            {
              label: "전기 총 사용량",
              data: chartTotalData
                ? chartTotalData.total_electricity_usage.map(
                    (data: any) => data.total
                  )
                : [],
              borderColor: ["rgb(255, 99, 132)", "rgb(53, 162, 235)"],
              backgroundColor: [
                "rgba(255, 99, 132, 0.5)",
                "rgba(53, 162, 235, 0.5)",
              ],
            },
          ],
  };
  const gasData = {
    labels,
    datasets:
      !isBuildingUtilityDetailLoading && searchType === "month"
        ? [
            {
              label: "전년도 사용량",
              data: chartData
                ? chartData.map((data) => data.gas_usage_past)
                : [],
              borderColor: "rgb(260, 150, 110)",
              backgroundColor: "rgba(260, 150, 110, 0.5)",
            },
            {
              label: "가스사용량",
              data: chartData ? chartData.map((data) => data.gas_usage) : [],
              borderColor: "rgb(100, 200, 100)",
              backgroundColor: "rgba(100, 200, 100, 0.5)",
              // backgroundColor: "rgba(100, 200, 90, 0.5)",
            },
          ]
        : [
            {
              label: "가스 총 사용량",
              data: chartTotalData
                ? chartTotalData.total_gas_usage.map((data: any) => data.total)
                : [],
              borderColor: ["rgb(260, 150, 110)", "rgb(100, 200, 100)"],
              backgroundColor: [
                "rgba(260, 150, 110, 0.5)",
                "rgba(100, 200, 100, 0.5)",
              ],
            },
          ],
  };
  const waterData = {
    labels,
    datasets:
      !isBuildingUtilityDetailLoading && searchType === "month"
        ? [
            {
              label: "전년도 사용량",
              data: chartData
                ? chartData.map((data) => data.water_usage_past)
                : [],
              borderColor: "rgb(250, 201, 77)",
              backgroundColor: "rgba(250, 201, 77, 0.5)",
            },
            {
              label: "수도사용량",
              data: chartData ? chartData.map((data) => data.water_usage) : [],
              borderColor: "rgb(132, 129, 221)",
              backgroundColor: "rgba(132, 129, 221, 0.5)",
              // backgroundColor: "rgba(100, 200, 90, 0.5)",
            },
          ]
        : [
            {
              label: "수도 총 사용량",
              data: chartTotalData
                ? chartTotalData.total_water_usage.map(
                    (data: any) => data.total
                  )
                : [],
              borderColor: ["rgb(250, 201, 77)", "rgb(132, 129, 221)"],
              backgroundColor: [
                "rgba(250, 201, 77, 0.5)",
                "rgba(132, 129, 221, 0.5)",
              ],
            },
          ],
  };
  const spendData = {
    labels,
    datasets:
      searchType === "month"
        ? [
            {
              label: "전년도 소비량",
              data: [],
              borderColor: "rgb(250, 201, 77)",
              backgroundColor: "rgba(250, 201, 77, 0.5)",
            },
            {
              label: "소비량",
              data: costData ? costData.map((d) => d.total_cost) : [],
              borderColor: "rgb(115, 197, 197)",
              backgroundColor: "rgba(115, 197, 197, 0.5)",
              // backgroundColor: "rgba(100, 200, 90, 0.5)",
            },
          ]
        : [
            {
              label: "총 소비량",
              data: costData ? costData.map((d) => d.total_cost) : [],
              borderColor: ["rgb(250, 201, 77)", "rgb(115, 197, 197)"],
              backgroundColor: [
                "rgba(250, 201, 77, 0.5)",
                "rgba(115, 197, 197, 0.5)",
              ],
            },
          ],
  };
  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: "md",
    }
  );
  return (
    <Box
      py={5}
      px={{ base: 5, md: 10 }}
      h={"88vh"}
      w={"100%"}
      overflowY={"scroll"}
      overflowX={"hidden"}
    >
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        // direction={{
        //   sm: "column",
        //   md: "row",
        // }}
        // spacing={{
        //   sm: 4,
        //   md: 0,
        // }}
        direction={isBreakPoint ? "column" : "row"}
        spacing={isBreakPoint ? 4 : 0}
      >
        <Flex alignItems={"center"} w={isBreakPoint ? "100%" : "50%"}>
          <InputGroup size="sm" mr={2} w={isBreakPoint ? "65%" : "100%"}>
            <Input
              w={"100%"}
              placeholder="날짜를 선택해주세요"
              type="month"
              value={startDate}
              min={moment(endDate).subtract(1, "years").format("YYYY-MM")}
              max={endDate}
              onChange={onChangeStartDate}
            />
            <Input
              w={"100%"}
              placeholder="날짜를 선택해주세요"
              type="month"
              value={endDate}
              // max={moment().subtract(3, "months").format("YYYY-MM")}
              max={moment().format("YYYY-MM")}
              onChange={onChangeEndDate}
            />
          </InputGroup>
          {/* <Spacer /> */}
          <ButtonGroup size={"sm"} mr={2}>
            <Button
              variant={"outline"}
              colorScheme="green"
              onClick={onClickSearch}
            >
              검색
            </Button>
            <Button
              isLoading={isRefetchMutating ? true : false}
              onClick={onClickRefetch}
            >
              가져오기
            </Button>
          </ButtonGroup>
        </Flex>
        <Flex alignItems={"center"}>
          <ButtonGroup size={"sm"} mr={2}>
            <Button
              variant={monthToggle ? "outline" : "solid"}
              colorScheme="blackAlpha"
              onClick={onYearClick}
            >
              기간총합
            </Button>
            <Button
              variant={yearToggle ? "outline" : "solid"}
              colorScheme="blackAlpha"
              onClick={onMonthClick}
            >
              월간
            </Button>
          </ButtonGroup>
          <Button size={"sm"} onClick={onModalOpen}>
            사용량 보기
          </Button>
        </Flex>
      </Stack>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
          //   "2xl": "repeat(5, 1fr)",
        }}
        gap={7}
        mt={5}
        // height={"30vh"}
      >
        <Box
          py={3}
          px={3}
          //   minH={"40vh"}
          h={"35vh"}
          //   w={"50%"}
          maxH={"50vh"}
          maxW={"100%"}
          borderRadius={"3xl"}
          borderWidth={2}
        >
          <Bar options={electricityOptions} data={electricityData} />
        </Box>
        <Box
          py={3}
          px={3}
          //   minH={"40vh"}
          h={"35vh"}
          //   w={"50%"}
          maxH={"50vh"}
          maxW={"100%"}
          borderRadius={"3xl"}
          borderWidth={2}
        >
          <Bar options={gasOptions} data={gasData} />
        </Box>
        <Tooltip
          label="수도 사용량은 직접입력해야합니다. 클릭해주세요."
          fontSize={"sm"}
          bg="green.200"
          color="black"
          opacity={90}
          offset={[0, -60]}
          // shouldWrapChildren={true}
        >
          <Box
            py={3}
            px={3}
            h={"35vh"}
            //   w={"50%"}
            maxH={"50vh"}
            maxW={"100%"}
            borderRadius={"3xl"}
            borderWidth={2}
            _hover={{
              bg: "orange.100",
              cursor: "pointer",
            }}
            onClick={onAddOpen}
          >
            <Bar options={waterOptions} data={waterData} />
          </Box>
        </Tooltip>
        <Box
          py={3}
          px={3}
          h={"35vh"}
          maxH={"50vh"}
          maxW={"100%"}
          borderRadius={"3xl"}
          borderWidth={2}
        >
          <Line options={spendOptions} data={spendData} />
        </Box>
      </Grid>
      <AddWaterUtilityModal
        isOpen={isAddOpen}
        onClose={onAddClose}
        buildingId={buildingId}
        chartRefetch={buildingUtilityInfoDetailRefetch}
      />
      <BuildingUtilityDataModal
        isOpen={isUtilityDataModalOpen}
        onClose={onUtilityDataModalClose}
        buildingId={Number(buildingId)}
        chartRefetch={buildingUtilityInfoDetailRefetch}
      />
    </Box>
  );
}
