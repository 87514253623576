import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IModalProps, ITinyUser, IUserManagementList } from "../../types";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useParams } from "react-router-dom";
import { ICreateTodoVariables, createTodo, getUser } from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export default function CreateTodoModal({ isOpen, onClose }: IModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { buildingId } = useParams();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [participantList, setParticipantList] = useState<
    IUserManagementList[] | any[]
  >([]);
  const { data: users } = useQuery<IUserManagementList[]>({
    queryKey: ["usermanagement_users"],
    queryFn: getUser,
  });
  const mutation = useMutation({
    mutationFn: createTodo,
    onSuccess: (data) => {
      onCloseReset();
      queryClient.invalidateQueries({ queryKey: ["todo_month"] });
      queryClient.invalidateQueries({ queryKey: ["todo_dates"] });
      queryClient.invalidateQueries({ queryKey: ["last_updated"] });
    },
    onError: (error) => {
      console.log(error);
      toast({
        status: "error",
        title: "일정 등록에 실패하였습니다.",
        description: "다시 시도해 주세요.",
      });
    },
  });
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    if (users) {
      const participant: IUserManagementList | undefined = users.find(
        (x) => x.id === Number(e.target.value)
      );
      if (participant) {
        if (!participantList.includes(participant)) {
          setParticipantList([...participantList, participant]);
        } else {
          toast({
            status: "warning",
            title: "이미 추가되었습니다.",
          });
        }
      }
    }
  };
  const onCloseReset = () => {
    reset();
    onClose();
    setParticipantList([]);
  };
  const onSubmit = (data: any) => {
    console.log(data);
    if (buildingId) {
      mutation.mutate({
        ...data,
        building_id: Number(buildingId),
        participants: participantList,
      });
    } else {
      toast({
        status: "error",
        title: "건물을 선택해주세요.",
      });
    }
  };
  // console.log(users);
  console.log(participantList);
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>일정 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"start"}>
            <Input
              {...register("date", {
                required: "required",
              })}
              placeholder="날짜를 선택해주세요"
              size="lg"
              //   type="datetime-local"
              type="date"
              defaultValue={moment().format("YYYY-MM-DD")}
              //   max={new Date().toISOString().substring(0, 10)}
            />
            <Input
              {...register("title", {
                required: "required",
              })}
              placeholder="제목"
              type="text"
            />
            <Textarea
              {...register("description", {
                required: "required",
              })}
              placeholder="추가 내용"
            />
            <Select
              placeholder="관련 사용자를 선택해주세요."
              onChange={onChange}
            >
              {users && users.length > 0
                ? users.map((u) => (
                    <option key={u.id} value={u.id}>
                      {u.user.username}
                    </option>
                  ))
                : null}
            </Select>
            <HStack px={1} py={2}>
              {participantList.length > 0
                ? participantList.map((p) => (
                    <Tag
                      key={p.id}
                      borderRadius="full"
                      variant="subtle"
                      colorScheme="green"
                    >
                      <TagLabel>{p.user.username}</TagLabel>
                      <TagCloseButton />
                    </Tag>
                  ))
                : null}
            </HStack>
          </VStack>
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              저장
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
