import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ToastId,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import { IBuildingInfo } from "../../types";
import {
  addBuilding,
  addBuildingElevatorGeneralInfo,
  addBuildingUtilityInfo,
} from "../../api";

export interface IBuildingInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingInfo: IBuildingInfo | undefined;
}

export default function AddBuildingInfoModal({
  isOpen,
  onClose,
  buildingInfo,
}: IBuildingInfoModalProps) {
  // const [buildingInfo, setBuildingInfo] = useState<IBuildingInfo | null>(null);
  const [buildingResult, setBuildingResult] = useState<any>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBuildingInfo>();
  const queryClient = useQueryClient();
  const toast = useToast();
  const toastElevator = useRef<ToastId>();
  const elevatorInfoMutation = useMutation({
    mutationFn: addBuildingElevatorGeneralInfo,
    mutationKey: ["getElevatorInfo"],
    onMutate: () => {
      toastElevator.current = toast({
        status: "info",
        title: "엘레베이터 정보 수집중입니다.",
      });
    },
    onSuccess: () => {
      console.log("엘레베이터 기본 정보 저장 완료");
      if (toastElevator.current) {
        toast.update(toastElevator.current, {
          status: "success",
          title: "엘레베이터 정보 수집 완료!",
        });
      }
      Sentry.captureMessage("엘레베이터 수집 완료", "log");
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("엘레베이터 정보 저장 실패!");
      Sentry.captureException(e.response.data);
      // TODO 엘레베이터 수집 오류시 빌딩 정보는 생성 할것인지? 에러 시 확인
      // queryClient.refetchQueries({ queryKey: ["building_list"] });
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ["building_list"] });
    },
  });
  const buildingUtilityMutation = useMutation({
    mutationFn: addBuildingUtilityInfo,
    onMutate: () => {
      toast({
        status: "info",
        title: "에너지 정보 수집중입니다.",
        description: "시간이 걸릴 수도 있으니 기다려주세요.",
      });
    },
    onSuccess: () => {
      console.log("에너지 정보 저장 완료");
      toast({
        status: "success",
        title: "사용 에너지 정보 수집 완료!",
      });
      Sentry.captureMessage("에너지 정보 수집 완료", "log");
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("에너지 정보 저장 실패!");
      toast({
        status: "error",
        title: "기본 에너지 정보 가져오기에 실패했습니다",
        description: "대시 보드 내에서 다시 수집 가능합니다.",
      });
      Sentry.captureException(e.response.data);
    },
  });
  const mutation = useMutation({
    mutationFn: addBuilding,
    onSuccess: (data) => {
      console.log(data);
      toast({
        status: "success",
        title: "등록되었습니다!",
        description: "빌딩 등록 완료!",
        duration: 1500,
      });
      Sentry.captureMessage("빌딩 등록 완료", "log");
    },
    onSettled: async (data) => {
      if (data) {
        // // 엘레베이터 수집 시, 수집 중 안내 blank 생성 ?
        await elevatorInfoMutation.mutate({
          address: data.road_address,
          building_id: data.id,
        });
        if (!elevatorInfoMutation.isPending) {
          await buildingUtilityMutation.mutate({
            sigungu_code: data.sigungu_code.toString(),
            bjdong_code: data.bjdong_code.toString(),
            bun: data.bun.toString(),
            ji: data.ji.toString(),
            building_id: data.id,
          });
        }
      }
      onClose();
      reset();
    },
    onError: (e: any) => {
      // TODO 500 에러 확인하기
      console.log(e.response.data);
      if (e.response.data.address) {
        toast({
          status: "error",
          title: e.response.data.address[0],
          description: "이미 등록 된 주소입니다. 관리자에게 문의하여 주세요.",
        });
      } else if (e.response.data.name) {
        toast({
          status: "error",
          // title: e.response.data.name[0],
          title: "빌딩 이름이 입력되지 않았습니다.",
          description: "빌딩 이름을 입력해주세요.",
        });
      } else {
        console.log(e.response.data);
        toast({
          status: "error",
          title: "필수 값을 확인해주세요.",
        });
      }
    },
  });

  const onSubmit = async (data: IBuildingInfo) => {
    await mutation.mutate(data);
  };
  return (
    <Modal
      blockScrollOnMount={false}
      scrollBehavior="inside"
      size={"2xl"}
      onClose={onClose}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>건축물 대장 정보</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <FormControl isRequired>
              <FormLabel>건물 이름</FormLabel>
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", {
                  required: true,
                })}
                type="text"
                defaultValue={buildingInfo?.name}
              />
              {/* <FormHelperText></FormHelperText> */}
            </FormControl>
            <FormControl isRequired>
              <FormLabel>지번 주소</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("address", { required: true })}
                type="text"
                defaultValue={buildingInfo?.address}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>도로명 주소</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("road_address", { required: true })}
                type="text"
                defaultValue={buildingInfo?.road_address}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>우편번호</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("zip_code", { required: true })}
                type="text"
                defaultValue={buildingInfo?.zip_code}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>주용도코드명</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("purpose_name", { required: true })}
                type="text"
                defaultValue={buildingInfo?.purpose_name}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>기타용도</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("etc_purpose_name", { required: true })}
                type="text"
                defaultValue={buildingInfo?.etc_purpose_name}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>높이</FormLabel>
              <Input
                // readOnly
                {...register("height", { required: true })}
                type="float"
                defaultValue={buildingInfo?.height}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>지상층수</FormLabel>
              <Input
                // readOnly
                {...register("ground_floor_cnt", { required: true })}
                type="number"
                defaultValue={buildingInfo?.ground_floor_cnt}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>지하층수</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("underground_floor_cnt", { required: true })}
                type="number"
                defaultValue={buildingInfo?.underground_floor_cnt}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>승용승강기수</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("elevator_cnt", { required: true })}
                type="number"
                defaultValue={buildingInfo?.elevator_cnt}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>비상용승강기수</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("emergency_elevator_cnt", { required: true })}
                type="number"
                defaultValue={buildingInfo?.emergency_elevator_cnt}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>옥내기계식대수(대)</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("indoor_mech_cnt", { required: true })}
                type="number"
                defaultValue={buildingInfo?.indoor_mech_cnt}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>옥내기계식면적(㎡)</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("indoor_mech_area", { required: true })}
                type="float"
                defaultValue={buildingInfo?.indoor_mech_area}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>허가일</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("permission_date", { required: true })}
                type="number"
                defaultValue={buildingInfo?.permission_date}
              />
            </FormControl>
            <FormControl>
              <FormLabel>착공일</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("construction_start_date")}
                type="number"
                defaultValue={buildingInfo?.construction_start_date}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>사용승인일</FormLabel>
              <Input
                // readOnly={buildingInfo ? true : false}
                placeholder="YYYYMMDD"
                {...register("use_approved_date", { required: true })}
                type="number"
                defaultValue={buildingInfo?.use_approved_date}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>시군구코드</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("sigungu_code", { required: true })}
                type="text"
                defaultValue={buildingInfo?.sigungu_code}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>법정동코드</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("bjdong_code", { required: true })}
                type="text"
                defaultValue={buildingInfo?.bjdong_code}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>번</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("bun", { required: true })}
                type="text"
                defaultValue={buildingInfo?.bun}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>지</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("ji", { required: true })}
                type="text"
                defaultValue={buildingInfo?.ji}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>관리건축물대장번호</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("register_pk", { required: true })}
                type="text"
                defaultValue={buildingInfo?.register_pk}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>대지면적(㎡)</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("plat_area", { required: true })}
                type="float"
                defaultValue={buildingInfo?.plat_area}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>건폐율(%)</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("coverage_ratio", { required: true })}
                type="number"
                defaultValue={buildingInfo?.coverage_ratio}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>연면적(㎡)</FormLabel>
              <Input
                // readOnly
                {...register("total_area", { required: true })}
                type="float"
                defaultValue={buildingInfo?.total_area}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>용적률(%)</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                {...register("volume_coverage_ratio", { required: true })}
                type="number"
                defaultValue={buildingInfo?.volume_coverage_ratio}
              />
            </FormControl>
            <FormControl>
              <FormLabel>내진능력</FormLabel>
              <Input
                readOnly={buildingInfo ? true : false}
                placeholder="eg. Ⅶ-0.204g"
                {...register("earthquake_resist_ability", { required: true })}
                type="text"
                defaultValue={
                  buildingInfo ? buildingInfo?.earthquake_resist_ability : ""
                }
              />
            </FormControl>
            {/* {mutation.isError ? (
              <Text color="red.500">값을 확인하여 주세요.</Text>
            ) : null} */}
          </VStack>
          <HStack mt={4} mb={4}>
            <Button onClick={onClose} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              확인
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
