import {
  Button,
  FocusLock,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IBuildingFacility } from "../../types";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBuildingFacility, editBuildingFacility } from "../../api";
import { EditIcon } from "@chakra-ui/icons";

export interface IFireSafetyInsuranceProps {
  buildingId: number;
  data: IBuildingFacility[] | undefined;
}
export default function AddFireSafetyInsurancePopover({
  buildingId,
  data,
}: IFireSafetyInsuranceProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef<HTMLButtonElement>(null);
  const [insuranceData, setInsuranceData] = useState<IBuildingFacility[]>();
  // const [insurance, setInsurance] = useState<IBuildingFacility | undefined>(
  //   undefined
  // );
  const onPopoverOpen = () => {
    console.log(data);
    setInsuranceData(data);
    onOpen();
  };
  const onPopoverClose = () => {
    onClose();
    setInsuranceData([]);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBuildingFacility>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createBuildingFacility,
    onSuccess: () => {
      console.log("소방보험 저장 완료");
      toast({
        status: "success",
        title: "등록되었습니다!",
        description: "소방보험 등록 완료!",
        duration: 1500,
      });
      onPopoverClose();
      queryClient.refetchQueries({ queryKey: ["fire_insurance"] });
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("소방보험 저장 실패!");
      toast({
        status: "error",
        title: "오류!",
        description: "소방보험 등록 실패!",
        duration: 1500,
      });
    },
  });
  const editMutation = useMutation({
    mutationFn: editBuildingFacility,
    onSuccess: () => {
      console.log("소방보험 수정 완료");
      onPopoverClose();
      queryClient.refetchQueries({ queryKey: ["fire_insurance"] });
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("소방보험 저장 실패!");
      toast({
        status: "error",
        title: "오류!",
        description: "소방보험 수정 실패!",
        duration: 1500,
      });
    },
  });
  const onSubmit = (insuranceData: IBuildingFacility) => {
    if (buildingId) {
      if (insuranceData.start_date === "") {
        delete insuranceData["start_date"];
      }
      if (insuranceData.end_date === "") {
        delete insuranceData["end_date"];
      }
      if (data && data.length > 0) {
        editMutation.mutate({
          ...insuranceData,
          type: "fire_insurance",
          building: buildingId,
        });
      } else {
        mutation.mutate({
          ...insuranceData,
          type: "fire_insurance",
          building: buildingId,
        });
      }
    }
  };
  console.log(data);
  return (
    <Popover
      initialFocusRef={firstFieldRef}
      //   returnFocusOnClose={false}
      placement="auto"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onPopoverClose}
    >
      <PopoverTrigger>
        {data && data.length > 0 ? (
          <IconButton
            onClick={onPopoverOpen}
            aria-label="Search Info"
            variant="outline"
            colorScheme="purple"
            size="xs"
            icon={<EditIcon />}
            mb={1}
            // mr={1}
          />
        ) : (
          <Button
            onClick={onPopoverOpen}
            size={"sm"}
            variant={"outline"}
            colorScheme="purple"
          >
            등록
          </Button>
        )}
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          {insuranceData ? (
            <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <Input
                  hidden
                  {...register("id", {
                    required: true,
                  })}
                  type="number"
                  value={insuranceData.length > 0 ? insuranceData[0].id : ""}
                />
              </FormControl>
              <FormControl>
                <FormLabel>이름</FormLabel>
                {insuranceData.length > 0 ? (
                  <Input
                    isInvalid={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: true,
                    })}
                    type="text"
                    defaultValue={insuranceData[0].name}
                  />
                ) : (
                  <Input
                    isInvalid={Boolean(errors.name?.message)}
                    {...register("name", {
                      required: true,
                    })}
                    type="text"
                    defaultValue=""
                    // value={insuranceData.length > 0 ? insuranceData[0].name : ""}
                  />
                )}
              </FormControl>
              <FormControl>
                <FormLabel>보험시작일</FormLabel>
                <Input
                  {...register("start_date")}
                  placeholder="날짜를 선택해주세요"
                  size="lg"
                  //   type="datetime-local"
                  type="date"
                  value={
                    insuranceData.length > 0 ? insuranceData[0].start_date : ""
                  }
                  // defaultValue={new Date().toISOString().substring(0, 10)}
                  max={new Date().toISOString().substring(0, 10)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>보험종료일</FormLabel>
                <Input
                  {...register("end_date")}
                  placeholder="날짜를 선택해주세요"
                  size="lg"
                  //   type="datetime-local"
                  type="date"
                  value={
                    insuranceData.length > 0 ? insuranceData[0].end_date : ""
                  }
                  // defaultValue={new Date().toISOString().substring(0, 10)}
                  // max={new Date().toISOString().substring(0, 10)}
                />
              </FormControl>
              <HStack mt={3} justifyContent="end">
                <Button type="submit" colorScheme="teal">
                  등록
                </Button>
              </HStack>
            </Stack>
          ) : null}
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}
