import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { IBuildingFloorInfo } from "../../types";
import { IEditBuildingFloorVariable, editBuildingFloor } from "../../api";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

export interface FloorTabProps {
  buildingFloorInfo: IBuildingFloorInfo[] | undefined;
  buildingId: number;
}

export default function BuildingFloorTab({
  buildingFloorInfo,
  buildingId,
}: FloorTabProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditBuildingFloorVariable>();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: editBuildingFloor,
    onSuccess: (data) => {
      console.log(data);
      toast({
        title: "층별 정보 업데이트 완료!",
        status: "success",
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "층별 정보 업데이트가 실패했습니다.",
        status: "error",
      });
    },
  });
  const onSubmit = (data: IEditBuildingFloorVariable) => {
    // console.log({ ...data, buildingId: buildingId });
    console.log(data);
    mutation.mutate({ ...data, buildingId: buildingId });
  };
  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: "md",
    }
  );
  return (
    <>
      {buildingFloorInfo !== undefined ? (
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList
            ml={2}
            // maxW={"95%"}
            w={isBreakPoint ? "78vw" : "42vw"}
            // w={"50%"}
            overflowX={"auto"}
            sx={{
              scrollbarWidth: "5",
              WebkitOverflowScrolling: "touch",
              msOverflowStyle: "-ms-autohiding-scrollbar",
              // "::-webkit-scrollbar": { display: "none" },
              // boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
              // border: "0 none",
            }}
          >
            {buildingFloorInfo.map((floor, idx) => (
              <Tab fontSize={"md"} flexShrink={0} key={idx}>
                {floor.floor_number_name}
              </Tab>
            ))}
          </TabList>
          <TabPanels as="form" w={"50%"} onSubmit={handleSubmit(onSubmit)}>
            {buildingFloorInfo.map((floor, idx) => (
              <TabPanel key={idx}>
                <VStack>
                  <FormControl>
                    <Input
                      hidden
                      {...register(`data.${idx}.id`)}
                      type="text"
                      defaultValue={floor.id}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>이름</FormLabel>
                    <Input
                      {...register(`data.${idx}.name`)}
                      type="text"
                      defaultValue={floor.name}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>지상/지하</FormLabel>
                    <Input
                      readOnly
                      type="text"
                      defaultValue={floor.floor_code_name}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>층</FormLabel>
                    <Input
                      readOnly
                      type="number"
                      defaultValue={floor.floor_number}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>층이름</FormLabel>
                    <Input
                      readOnly
                      type="text"
                      defaultValue={floor.floor_number_name}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>층용도명</FormLabel>
                    <Input
                      readOnly
                      type="text"
                      defaultValue={floor.main_purpose_name}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>면적(㎡)</FormLabel>
                    <Input readOnly type="number" defaultValue={floor.area} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>등록일</FormLabel>
                    <Input
                      readOnly
                      type="number"
                      defaultValue={floor.created_date}
                    />
                  </FormControl>
                </VStack>
                <HStack mt={4}>
                  {/* <Button onClick={onClose} colorScheme={"red"} w="100%">
                    취소
                  </Button> */}
                  <Button
                    isLoading={mutation.isPending}
                    type="submit"
                    colorScheme={"green"}
                    w="100%"
                  >
                    확인
                  </Button>
                </HStack>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : null}
    </>
  );
}
