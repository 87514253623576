import { Box, HStack, Skeleton } from "@chakra-ui/react";

export default function BuildingSkeleton() {
  return (
    <>
      <Box>
        <Skeleton rounded="2xl" height={300} mb={10} />
        <Skeleton rounded="lg" w="40%" height={7} mb={5} />
        <Skeleton rounded="lg" w="60%" height={7} mb={5} />
      </Box>
      <Box ml={3}>
        <HStack mb={7}>
          <Skeleton rounded="lg" w="20%" height={10} mb={2} />
          <Skeleton rounded="lg" w="20%" height={10} mb={2} />
          <Skeleton rounded="lg" w="20%" height={10} mb={2} />
          <Skeleton rounded="lg" w="20%" height={10} mb={2} />
          <Skeleton rounded="lg" w="20%" height={10} mb={2} />
        </HStack>
        <Skeleton rounded="lg" w="20%" height={7} mb={2} />
        <Skeleton rounded="lg" w="100%" height={10} mb={3} />
        <Skeleton rounded="lg" w="20%" height={7} mb={2} />
        <Skeleton rounded="lg" w="100%" height={10} mb={3} />
        <Skeleton rounded="lg" w="30%" height={7} mb={2} />
        <Skeleton rounded="lg" w="100%" height={10} mb={3} />
        <Skeleton rounded="lg" w="30%" height={7} mb={2} />
        <Skeleton rounded="lg" w="100%" height={10} mb={3} />
      </Box>
    </>
  );
}
