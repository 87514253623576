import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import useUser from "../lib/useUser";
import Welcome from "./Welcome";

export default function Home() {
  const { userLoading, user, isLoggedIn, logInTime } = useUser();
  if (!isLoggedIn) {
    return <Welcome />;
  } else if (user?.status !== "approved") {
    // navigate("/welcome");
    return <Welcome />;
  } else {
    return (
      <Box>
        <Helmet>
          <title>{"Maestro"}</title>
        </Helmet>
        <VStack bg="gray.100" justifyContent={"center"} minH="95vh" spacing={4}>
          <Heading size={"lg"}>건물을 선택해주세요.</Heading>
          <Link to="/building">
            <Button variant={"solid"} colorScheme="green">
              바로가기
            </Button>
          </Link>
        </VStack>
      </Box>
    );
  }
}
