import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IModalProps } from "../../types";
import { FaLock } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { IChangePasswordVariables, changePassword } from "../../api";
import { useNavigate } from "react-router-dom";

export default function ChangePasswordModal({ isOpen, onClose }: IModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IChangePasswordVariables>();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      toast({
        title: "비밀번호가 변경 되었습니다! 다시 로그인 해 주세요!",
        status: "success",
      });
      onClose();
      queryClient.refetchQueries({ queryKey: ["me"] });
      reset();
      navigate("/");
    },
    onError: (error) => {
      console.log(error);
      console.log("mutation failed");
    },
  });
  const onSubmit = ({
    old_password,
    new_password,
  }: IChangePasswordVariables) => {
    mutation.mutate({ old_password, new_password });
  };
  const onCloseReset = () => {
    reset();
    onClose();
  };
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>비밀번호 변경</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.old_password?.message)}
                {...register("old_password", {
                  required: "required",
                })}
                type="password"
                variant={"filled"}
                placeholder="old password"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.new_password?.message)}
                {...register("new_password", {
                  required: "required",
                })}
                type="password"
                variant={"filled"}
                placeholder="new password"
              />
            </InputGroup>
          </VStack>
          {mutation.isError ? (
            <Text color="red.500" textAlign={"center"} fontSize="sm">
              비밀번호를 확인해 주세요.
            </Text>
          ) : null}
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              변경
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
