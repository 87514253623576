import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import { IBuildingElevatorInfo } from "../../types";

export interface BuildingElevatorTabProps {
  buildingElevatorInfo: IBuildingElevatorInfo[] | undefined;
  buildingId: number;
}
export default function BuildingElevatorTab({
  buildingElevatorInfo,
  buildingId,
}: BuildingElevatorTabProps) {
  if (!buildingElevatorInfo || buildingElevatorInfo.length === 0) {
    return (
      <VStack
        bg="gray.100"
        rounded={"lg"}
        h={"50vh"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Heading size={"md"}>수집 된 엘레베이터 정보가 없습니다.</Heading>
      </VStack>
    );
  } else {
    return (
      <>
        <Grid
          // padding={3}
          templateColumns={{
            sm: "1fr",
            md: "1fr 1fr",
            lg: "repeat(2, 1fr)",
            xl: "repeat(2, 1fr)",
            //   "2xl": "repeat(5, 1fr)",
          }}
          gap={3}
        >
          {buildingElevatorInfo && buildingElevatorInfo.length > 0
            ? buildingElevatorInfo.map((elevator, idx) => (
                <VStack alignItems={"start"} key={idx}>
                  <Box
                    w={"100%"}
                    mt={2}
                    padding={3}
                    border={"1px"}
                    borderRadius={"lg"}
                    borderColor={"gray.100"}
                  >
                    <Heading size={"sm"} mb={3}>
                      엘레베이터 {idx + 1}
                    </Heading>
                    <FormControl>
                      <Input
                        hidden
                        // {...register(`data.${idx}.id`)}
                        type="text"
                        defaultValue={elevator.id}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        빌딩명
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.building_name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        등록번호
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.register_number}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        상태
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.status}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        승강기구분
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.division}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        승강기형식
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.form}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        승강기종류
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.type}
                      />
                    </FormControl>
                    {/* <FormControl>
                <FormLabel>승강기대수</FormLabel>
                <Input
                  readOnly
                  type="number"
                  defaultValue={elevator.elevator_number}
                />
              </FormControl> */}
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        운행층수
                      </FormLabel>
                      <Input
                        readOnly
                        type="number"
                        defaultValue={elevator.floor_cnt}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        적재하중
                      </FormLabel>
                      <Input
                        readOnly
                        type="number"
                        defaultValue={elevator.max_load}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        최대정원
                      </FormLabel>
                      <Input
                        readOnly
                        type="number"
                        defaultValue={elevator.max_capacity}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        제조업체명
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.manufacture_company_name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        최초설치일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.first_installation_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        설치일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.installation_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        검사유효기간(시작일자)
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.inspection_start_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        검사유효기간(종료일자)
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.inspection_end_date}
                      />
                    </FormControl>
                    <Divider mt={3} mb={3} borderWidth={1} />

                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        안전관리자명
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.assignee}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        교육이수일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.course_completed_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        교육 유효기간 시작일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.course_valid_start_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        교육 유효기간 종료일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.course_valid_end_date}
                      />
                    </FormControl>
                    <Divider mt={3} mb={3} borderWidth={1} />

                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        보험사명
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.insurance_name}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        보장개시일자
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.insurance_start_date}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel mt={1} mb={2}>
                        보장종료일
                      </FormLabel>
                      <Input
                        readOnly
                        type="text"
                        defaultValue={elevator.insurance_end_date}
                      />
                    </FormControl>
                  </Box>
                </VStack>
              ))
            : null}
        </Grid>
        <HStack mt={4} mb={4}>
          <Button
            // isLoading={mutation.isLoading}
            type="submit"
            colorScheme={"green"}
            w="100%"
          >
            확인
          </Button>
        </HStack>
      </>
    );
  }
}
