import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { IModalDataProps } from "../../types";
import { Document, Page, pdfjs } from "react-pdf";
import { useState } from "react";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewerModal({
  onClose,
  isOpen,
  data,
}: IModalDataProps) {
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>PDF</ModalHeader>
        <ModalCloseButton />
        <ModalBody h={"80vh"}>
          <Box h={"80vh"}>
            <HStack w={"100%"} alignItems={"center"} justifyContent={"end"}>
              <Button
                size={"sm"}
                onClick={() =>
                  setPageNumber((prev) => (prev === 1 ? prev : prev - 1))
                }
                isDisabled={pageNumber === 1 ? true : false}
              >
                &lt;
              </Button>
              <Button
                size={"sm"}
                onClick={() =>
                  setPageNumber((prev) => (prev === numPages ? prev : prev + 1))
                }
                isDisabled={pageNumber === numPages ? true : false}
              >
                &gt;
              </Button>
            </HStack>
            <Box h={"65vh"} overflowY={"auto"}>
              <Document file={data} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
