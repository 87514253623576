import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import { IEditBuildingUtility, editBuildingUtility } from "../../api";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: string | undefined;
  chartRefetch: () => void;
}

export default function AddWaterUtilityModal({
  isOpen,
  onClose,
  buildingId,
  chartRefetch,
}: IModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditBuildingUtility>();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: editBuildingUtility,
    mutationKey: ["addBuildingUtilityDashBoard"],
    onSuccess: () => {
      console.log("유틸리티 정보 추가 완료");
      reset();
      onClose();
      chartRefetch();
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("유틸리티 정보 저장 실패!");
    },
  });
  const onSubmit = (data: IEditBuildingUtility) => {
    console.log(data);
    if (buildingId) {
      mutation.mutate({ data, building_id: Number(buildingId) });
    }
  };
  return (
    <Modal size={"xl"} onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>데이터 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"start"}>
            <Input
              {...register("search_date")}
              type="month"
              defaultValue={moment().format("YYYY-MM")}
            />
            <Box ml={3}>
              <Text fontSize={"xs"} color={"red"}>
                수집 되지 않은 월 데이터는 추가 / 수집 된 데이터는 수정 됩니다.
              </Text>
            </Box>
            <HStack>
              <InputGroup>
                <Input
                  {...register(`water_usage`, {
                    valueAsNumber: true,
                  })}
                  type="number"
                  placeholder="수도사용량"
                />
                <InputRightAddon children="㎡" />
              </InputGroup>
              <InputGroup>
                <Input
                  {...register(`water_cost`, {
                    valueAsNumber: true,
                  })}
                  type="number"
                  placeholder="수도세"
                />
                <InputRightAddon children="원" />
              </InputGroup>
            </HStack>
          </VStack>
          <HStack mt={4} mb={4}>
            <Button onClick={onClose} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              등록
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
