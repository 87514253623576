import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { deleteReport } from "../../api";

interface IAlertProps {
  isOpen: boolean;
  onClose: () => void;
  reportId: number;
  setReportId: (arg: number | null) => void;
}
export default function ReportDeleteAlertDialog({
  isOpen,
  onClose,
  reportId,
  setReportId,
}: IAlertProps) {
  const cancelRef = useRef(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteReport,
    onSuccess: (data) => {
      console.log(data);
      toast({
        title: "삭제되었습니다!",
        status: "success",
        description: "리포트 삭제 완료!",
      });
      queryClient.invalidateQueries({ queryKey: ["reports"] });
      setReportId(null);
      onClose();
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "삭제 실패!",
        status: "error",
        description: "삭제 실패, 다시 시도해 주세요!",
      });
      onClose();
    },
  });
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            삭제
          </AlertDialogHeader>
          <AlertDialogBody>정말로 삭제하시겠습니까?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() =>
                reportId ? mutation.mutate(reportId) : console.log("리포트없음")
              }
            >
              삭제
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
