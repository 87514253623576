import {
  Box,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  useBreakpointValue,
  Tag,
  VStack,
  HStack,
  Image,
  Divider,
  Icon,
  Mark,
  List,
  ListItem,
  ListIcon,
  IconButton,
  Spinner,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../calendar.css";
import moment from "moment";
import "moment/locale/ko";
import { useEffect, useState } from "react";
import { useIsFetching, useQuery } from "@tanstack/react-query";
import {
  IDashboardBuilding,
  ILastUpdated,
  INotice,
  IStat,
  ITodo,
} from "../types";
import {
  getBuildingLastUpdated,
  getBuildingName,
  getDashboardNotice,
  getDashboardStat,
  getDashboardTodos,
  getDashboardTodosMonth,
  getTodoDates,
  getWeather,
} from "../api";
import { useParams } from "react-router-dom";
import { FaCamera, FaClipboardCheck, FaFireAlt } from "react-icons/fa";
import {
  ArrowBackIcon,
  CheckCircleIcon,
  QuestionIcon,
  WarningIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";

export default function Dashboard() {
  const { buildingId } = useParams();
  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: true,
      lg: false,
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "lg",
    }
  );
  // const [value, setValue] = useState(new Date());
  const [lat, setLat] = useState("37.56667"); // 서울 위도
  const [lon, setLon] = useState("126.97806"); // 서울 경도
  const [date, setDate] = useState<string>();
  const [todoData, setTodoData] = useState<ITodo[]>();
  const [flag, setFlag] = useBoolean(true); // 달력 월별 - true, 일별 - false
  const [flagStat, setFlagStat] = useBoolean(true); // 스탯 월별 - true, 일별 - false
  const [stat, setStat] = useState<IStat>();

  const [activeStartDate, setActiveStartDate] = useState<Date | null>(
    new Date()
  );
  // const onChange = (e: any) => {
  //   console.log(e);
  // };
  const { data: buildingNameData } = useQuery<IDashboardBuilding>({
    queryKey: ["building_name", buildingId],
    queryFn: getBuildingName,
  });
  const { data: weatherData, refetch: weatherRefetch } = useQuery<any>({
    queryKey: ["weather", lat, lon],
    queryFn: getWeather,
    // enabled: false,
  });
  const { data: noticeData } = useQuery<INotice>({
    queryKey: ["notice", buildingId],
    queryFn: getDashboardNotice,
  });

  const { data: todoDates } = useQuery<string[]>({
    queryKey: ["todo_dates", buildingId],
    queryFn: getTodoDates,
  });

  const { data: lastUpdated } = useQuery<ILastUpdated[]>({
    queryKey: ["last_updated", buildingId],
    queryFn: getBuildingLastUpdated,
  });

  const { data: todoMonth } = useQuery<ITodo[]>({
    queryKey: ["todo_month", buildingId, moment().format("YYYY-MM-DD")],
    queryFn: getDashboardTodosMonth,
    // refetchOnMount: false,
  });

  const { data: todoDay, refetch: todoRefetch } = useQuery<ITodo[]>({
    queryKey: ["todo", buildingId, date],
    queryFn: getDashboardTodos,
    enabled: false,
  });
  const { data: statDataDay, refetch: statDayRefetch } = useQuery<IStat>({
    queryKey: ["stat", buildingId, date, "day"],
    queryFn: getDashboardStat,
    enabled: false,
  });
  const { data: statDataMonth, refetch: statMonthRefetch } = useQuery<IStat>({
    queryKey: [
      "stat",
      buildingId,
      moment(activeStartDate).format("YYYY-MM-DD"),
      "month",
    ],
    queryFn: getDashboardStat,
    enabled: false,
  });

  const onClickDay = (value: any) => {
    // setSearchType("day");
    setDate(moment(value).format("YYYY-MM-DD"));
    setFlag.off();
    setFlagStat.off();
  };

  const onClickBackToMonth = () => {
    setTodoData(todoMonth);
    setFlag.on();
  };

  const onClickStat = () => {
    setStat(statDataMonth); // 현재 달 고정? 아니면 캘린더에 따라 갈지
    setFlagStat.on();
  };

  // const todoDates = ["2024-02-01", "2024-02-14", "2024-02-21", "2024-02-22"];
  const isFetchingTodo = useIsFetching({ queryKey: ["todo"] });
  const isFetchingTodoMonth = useIsFetching({ queryKey: ["todo-month"] });

  useEffect(() => {
    if (buildingNameData) {
      setLat(buildingNameData.latitude);
      setLon(buildingNameData.longitude);
      weatherRefetch();
    }
  }, [buildingNameData]);

  useEffect(() => {
    if (todoMonth) {
      setTodoData(todoMonth);
    }
  }, [todoMonth]);

  useEffect(() => {
    if (date) {
      todoRefetch();
      statDayRefetch();
    }
  }, [date]);

  useEffect(() => {
    if (todoDay) {
      setTodoData(todoDay);
    }
  }, [todoDay]);

  useEffect(() => {
    // setSearchType("month")
    const searchDate = moment(activeStartDate).format("YYYY-MM-DD");
    console.log(searchDate);
    if (searchDate) {
      statMonthRefetch();
    }
  }, [activeStartDate]);

  useEffect(() => {
    setStat(statDataDay);
  }, [statDataDay]);

  useEffect(() => {
    setStat(statDataMonth);
  }, [statDataMonth]);
  // console.log(buildingNameData);
  // console.log(weatherData);
  // console.log(noticeData);
  // console.log(todoData);
  // console.log(flag);
  // console.log(activeStartDate);
  // console.log(statDataMonth);
  // console.log(statDataDay);
  // console.log(stat);
  return (
    // <Box px={10} h={isBreakPoint ? "80vh" : "90vh"} overflowY={"scroll"}>
    <Box
      py={5}
      px={{ base: 5, md: 10 }}
      h={isBreakPoint ? "85vh" : { md: "90vh", lg: "90vh" }}
      overflowY={"auto"}
    >
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
          //   "2xl": "repeat(5, 1fr)",
        }}
        gap={7}
        w={"100%"}
        // h={"78vh"}
        // h={"50vh"}
      >
        <Box
          //   display={"flex"}
          px={3}
          py={3}
          borderWidth={2}
          borderRadius={"lg"}
          //   alignItems={"center"}
          //   justifyContent={"center"}
          // h={"15vh"}
          // h={"100%"}
        >
          <HStack alignItems={"start"}>
            <StatGroup ml={4} w={"95%"}>
              <Stat size={{ base: "sm", md: "sm", lg: "md" }}>
                <StatLabel
                  fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                >
                  소비
                </StatLabel>
                <StatNumber>
                  {stat ? stat.spent.toLocaleString() : "-"}
                </StatNumber>
                {stat?.spent_past !== undefined &&
                Math.abs(stat?.spent - stat.spent_past) === 0 ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    -
                  </StatHelpText>
                ) : stat?.spent_past !== undefined ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    <StatArrow
                      type={
                        stat.spent > stat.spent_past ? "increase" : "decrease"
                      }
                    />
                    {Math.abs(stat.spent - stat.spent_past).toLocaleString()}
                  </StatHelpText>
                ) : null}
              </Stat>
              <Spacer />
              <Stat size={{ base: "sm", md: "sm", lg: "md" }}>
                <StatLabel
                  fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                >
                  일정
                </StatLabel>
                <StatNumber>{stat ? stat.todos : "-"}</StatNumber>
                {stat?.todos_past !== undefined &&
                Math.abs(stat?.todos - stat?.todos_past) === 0 ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    -
                  </StatHelpText>
                ) : stat?.todos_past !== undefined ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    <StatArrow
                      type={
                        stat.todos > stat?.todos_past ? "increase" : "decrease"
                      }
                    />
                    {Math.abs(stat.todos - stat?.todos_past)}
                  </StatHelpText>
                ) : null}
              </Stat>
              <Spacer />
              <Stat size={{ base: "sm", md: "sm", lg: "md" }}>
                <StatLabel
                  fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                >
                  보고서
                </StatLabel>
                <StatNumber>{stat ? stat.reports : "-"}</StatNumber>
                {stat?.reports_past !== undefined &&
                Math.abs(stat?.reports - stat?.reports_past) === 0 ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    -
                  </StatHelpText>
                ) : stat?.reports_past !== undefined ? (
                  <StatHelpText
                    fontSize={{ base: "x-small", md: "small", lg: "medium" }}
                  >
                    <StatArrow
                      type={
                        stat.reports > stat?.reports_past
                          ? "increase"
                          : "decrease"
                      }
                    />
                    {Math.abs(stat.reports - stat?.reports_past)}
                  </StatHelpText>
                ) : null}
              </Stat>
              {/* <Spacer />
              <Stat>
                <StatLabel>민원</StatLabel>
                <StatNumber>10</StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />3
                </StatHelpText>
              </Stat> */}
            </StatGroup>
            <Tooltip
              label="월 통계"
              bg="gray.200"
              color="black"
              hidden={flagStat ? true : false}
            >
              <IconButton
                aria-label="stat-all"
                size={"xs"}
                variant="outline"
                colorScheme="green"
                icon={<ArrowBackIcon />}
                onClick={onClickStat}
                hidden={flagStat ? true : false}
              />
            </Tooltip>
          </HStack>
        </Box>
        <Box px={2} py={2} borderWidth={2} borderRadius={"lg"}>
          <HStack mt={2} justifyContent={"space-around"}>
            <VStack alignItems={"start"} justifyContent={"center"}>
              {/* <Tag size={"lg"} variant="outline" colorScheme="orange">
                {buildingNameData ? buildingNameData.name : "-"}
              </Tag> */}
              <Heading
                size={{ base: "sm", md: "md" }}
                borderWidth={2}
                borderRadius={"lg"}
                borderColor={"orange.300"}
                backgroundColor={"orange.100"}
                // color={"green.500"}
                py={{ base: 1, md: 2 }}
                px={{ base: 2, md: 3 }}
              >
                {buildingNameData ? buildingNameData.name : "-"}
              </Heading>
              <HStack justifyContent={"center"}>
                {lastUpdated ? (
                  isBreakPoint ? (
                    <>
                      <Heading size={"xs"}>
                        {lastUpdated[0].last_updated}
                      </Heading>
                      <Tag
                        px={{ base: 3, md: 3, lg: 0 }}
                        whiteSpace={"nowrap"}
                        size={"sm"}
                        colorScheme="orange"
                        // w={{ base: "50%", md: "40%" }}
                        // w={"fit-content"}
                        justifyContent={"center"}
                      >
                        {lastUpdated[0].name}
                      </Tag>
                    </>
                  ) : (
                    <>
                      <Heading size={"xs"}>
                        마지막 업데이트: {lastUpdated[0].last_updated}
                      </Heading>
                      <Tag
                        size={"sm"}
                        colorScheme="orange"
                        // w={"60%"}
                        justifyContent={"center"}
                      >
                        {lastUpdated[0].name}
                      </Tag>
                    </>
                  )
                ) : (
                  <Heading size={"xs"}>마지막 업데이트: -</Heading>
                )}
              </HStack>
            </VStack>
            <Divider
              orientation="vertical"
              borderWidth={1}
              borderColor={"gray.200"}
              height={"50px"}
            />
            {weatherData && (
              <Box>
                <HStack>
                  <VStack alignItems={"center"} justifyContent={"center"}>
                    <Heading
                      ml={isBreakPoint ? 1.5 : 0}
                      size={{ base: "sm", md: "md" }}
                    >
                      {Math.round(weatherData.main.temp)}°
                    </Heading>
                    <Heading size={{ base: "xs", md: "sm" }}>
                      {isBreakPoint
                        ? moment().format("dddd").slice(0, 1)
                        : moment().format("dddd")}
                    </Heading>
                  </VStack>
                  <Box>
                    <Image
                      objectFit={"cover"}
                      maxH="70px"
                      w="100%"
                      h="100%"
                      src={
                        process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                        `icons/${weatherData.weather[0].icon}.png`
                          ? process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                            `icons/${weatherData.weather[0].icon}.png`
                          : process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                            `icons/unknown.png`
                      }
                    />
                  </Box>
                </HStack>
              </Box>
            )}
          </HStack>
        </Box>
      </Grid>
      <Stack
        mt={5}
        alignItems={"center"}
        direction={
          isBreakPoint
            ? "column"
            : {
                sm: "column",
                lg: "row",
              }
        }
        spacing={
          isBreakPoint
            ? 4
            : {
                sm: 4,
                md: 7,
              }
        }
        height={"70vh"}
        width={"100%"}
      >
        <Box
          borderWidth={2}
          borderRadius={"lg"}
          // h={"100%"}
          h={"inherit"}
          w={{ sm: "100%", lg: "50%" }}
        >
          <Calendar
            onClickDay={onClickDay}
            // onChange={onChange}
            // value={value}
            activeStartDate={
              activeStartDate === null ? undefined : activeStartDate
            }
            onActiveStartDateChange={({ activeStartDate }) =>
              setActiveStartDate(activeStartDate)
            }
            formatDay={(locale, date) => moment(date).format("DD")}
            // formatDay={(locale, date) =>
            //   date.toLocaleString("en", { day: "numeric" })
            // }
            locale="ko-KO"
            prev2Label={null}
            next2Label={null}
            showNeighboringMonth={false}
            maxDate={new Date(Date.now() + 60 * 60 * 24 * 7 * 4 * 6 * 1000)} //6개월까지
            minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
            maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
            tileClassName={({ date, view }) => {
              if (todoDates) {
                if (
                  todoDates.find((x) => x === moment(date).format("YYYY-MM-DD"))
                ) {
                  return "mark";
                }
              }
            }}
          />
        </Box>
        <Box
          h={isBreakPoint ? "50%" : "100%"}
          w={isBreakPoint ? "100%" : { sm: "100%", lg: "50%" }}
        >
          <Grid
            templateRows={"1fr 1fr"}
            templateColumns={"repeat(2, 1fr)"}
            h={"100%"}
            gap={5}
          >
            <GridItem>
              <Box
                px={3}
                py={3}
                borderWidth={2}
                borderRadius={"lg"}
                h={isBreakPoint ? "30vh" : "100%"}
              >
                <VStack h={"100%"} alignItems={"start"}>
                  <HStack w={"100%"} justifyContent={"space-between"}>
                    <Heading
                      // w={"100%"}
                      bg="yellow.200"
                      // bg="rgba(255, 255, 0, 0.7)"
                      size={"xs"}
                      px={2}
                      py={1}
                      rounded={"full"}
                    >
                      {flag
                        ? "이번 달 일정"
                        : `${moment(date).format("MM/DD")} 오늘의 일정`}
                    </Heading>
                    <Tooltip
                      label="월 일정"
                      bg="gray.200"
                      color="black"
                      hidden={flag ? true : false}
                    >
                      <IconButton
                        aria-label="month"
                        size={"xs"}
                        variant="outline"
                        colorScheme="green"
                        icon={<ArrowBackIcon />}
                        onClick={onClickBackToMonth}
                        hidden={flag ? true : false}
                      />
                    </Tooltip>
                  </HStack>
                  {(isFetchingTodo === 1 && isFetchingTodoMonth === 0) ||
                  (isFetchingTodo === 0 && isFetchingTodoMonth === 1) ? (
                    <Spinner
                      thickness="4px"
                      speed="1s"
                      emptyColor="gray.200"
                      color="green.500"
                      size="lg"
                    />
                  ) : (
                    <Box h={"90%"} w={"100%"} py={1} overflowY={"auto"}>
                      <List spacing={3}>
                        {flag ? null : moment(date).format("MM/DD") ===
                          moment().format("MM/DD") ? (
                          <>
                            {weatherData &&
                            weatherData.weather[0].icon === "02d" ? (
                              <ListItem
                                display={"flex"}
                                alignItems={"center"}
                                // justifyContent={"center"}
                              >
                                <ListIcon
                                  as={CheckCircleIcon}
                                  color="green.500"
                                />
                                <Text fontSize={"sm"}>
                                  날씨가 맑은 날 해야 할 일이 뭘까요?
                                </Text>
                              </ListItem>
                            ) : (weatherData &&
                                weatherData.weather[0].icon === "09d") ||
                              (weatherData &&
                                weatherData.weather[0].icon === "10d") ? (
                              <ListItem
                                display={"flex"}
                                alignItems={"center"}
                                // justifyContent={"center"}
                              >
                                <ListIcon
                                  as={CheckCircleIcon}
                                  color="green.500"
                                />
                                <Text fontSize={"sm"}>
                                  우천 시 확인 사항을 체크해주세요.
                                </Text>
                              </ListItem>
                            ) : (weatherData &&
                                weatherData.weather[0].icon === "13d") ||
                              (weatherData &&
                                weatherData.weather[0].icon === "13n") ? (
                              <ListItem
                                display={"flex"}
                                alignItems={"center"}
                                // justifyContent={"center"}
                              >
                                <ListIcon
                                  as={CheckCircleIcon}
                                  color="green.500"
                                />
                                <Text fontSize={"sm"}>
                                  제설작업이 필요할 수도 있어요.
                                </Text>
                              </ListItem>
                            ) : null}
                          </>
                        ) : null}

                        {todoData
                          ? todoData.map((data) => (
                              <ListItem
                                display={"flex"}
                                alignItems={"center"}
                                key={data.id}
                                // justifyItems={"center"}
                                // justifyContent={"center"}
                              >
                                <ListIcon
                                  as={CheckCircleIcon}
                                  color="green.500"
                                />
                                {flag ? (
                                  <Heading
                                    size={"xs"}
                                    mr={1}
                                    justifyContent={"center"}
                                  >
                                    {moment(data.date).format("MM/DD")}
                                  </Heading>
                                ) : null}
                                <Tooltip
                                  label={data.description}
                                  bg="gray.200"
                                  color="black"
                                >
                                  <Text
                                    fontSize={"sm"}
                                    style={{ cursor: "default" }}
                                    justifyContent={"center"}
                                  >
                                    {data.title}
                                  </Text>
                                </Tooltip>
                              </ListItem>
                            ))
                          : null}
                      </List>
                    </Box>
                  )}
                </VStack>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                px={3}
                py={3}
                borderWidth={2}
                borderRadius={"lg"}
                h={isBreakPoint ? "30vh" : "100%"}
              >
                <VStack h={"100%"} alignItems={"start"}>
                  <Box display="flex" alignItems="center">
                    {/* <Icon
                  boxSize={"4"}
                  color={"black"}
                  as={FaClipboardCheck}
                  mr={1}
                /> */}
                    <Heading
                      // w={"100%"}
                      bg="rgba(194, 82, 214, 0.3)"
                      size={"xs"}
                      px={2}
                      py={1}
                      rounded={"full"}
                    >
                      점검
                    </Heading>
                  </Box>
                  {noticeData ? (
                    <Box h={"100%"} w={"100%"} overflowY={"auto"}>
                      <VStack
                        alignItems={"start"}
                        justifyContent={"center"}
                        py={1}
                      >
                        <Heading size={"xs"} display={"flex"}>
                          종합정밀점검:
                        </Heading>
                        <Text
                          mb={2}
                          px={1}
                          color={
                            moment().month() + 1 ===
                            noticeData.total_fire_inspection_month
                              ? "red.500"
                              : "black"
                          }
                        >
                          {noticeData.total_fire_inspection_date[0]} ~{" "}
                          {noticeData.total_fire_inspection_date[1]}
                        </Text>
                        <Heading size={"xs"} display={"flex"}>
                          작동기능점검:
                        </Heading>
                        <Text
                          px={1}
                          color={
                            moment().month() + 1 ===
                            noticeData.functional_fire_inspection_month
                              ? "red.500"
                              : "black"
                          }
                        >
                          {noticeData.functional_fire_inspection_date[0]} ~{" "}
                          {noticeData.functional_fire_inspection_date[1]}
                        </Text>
                      </VStack>
                    </Box>
                  ) : null}
                </VStack>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                px={3}
                py={3}
                borderWidth={2}
                borderRadius={"lg"}
                h={isBreakPoint ? "30vh" : "100%"}
              >
                <VStack h={"100%"} alignItems={"start"}>
                  <Box display="flex" alignItems="center">
                    {/* <Icon
                  boxSize={"4"}
                  color={"rgba(255, 99, 132, 0.4)"}
                  as={FaFireAlt}
                  mr={1}
                /> */}
                    <Heading
                      // w={"100%"}
                      bg="rgba(255, 99, 132, 0.4)"
                      size={"xs"}
                      px={2}
                      py={1}
                      rounded={"full"}
                    >
                      소방
                    </Heading>
                  </Box>
                  {noticeData?.fire_check &&
                  noticeData.fire_check.length > 0 ? (
                    <Box h={"100%"} w={"100%"} overflowY={"auto"}>
                      <VStack
                        alignItems={"start"}
                        justifyContent={"center"}
                        py={1}
                      >
                        {noticeData?.fire_check.map((data, idx) =>
                          data.status === "late" ? (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              key={idx}
                            >
                              <Icon
                                as={WarningTwoIcon}
                                boxSize={4}
                                color="red.400"
                                mr={1}
                              />

                              {data.type === "fire_assignee" ? (
                                <VStack
                                  alignItems={"start"}
                                  justifyContent={"center"}
                                >
                                  <Heading
                                    size={"xs"}
                                    // display={"flex"}
                                    px={1}
                                    // lineHeight={"tall"}
                                  >
                                    <Mark
                                      bg="green.100"
                                      color="black"
                                      px={2}
                                      rounded={"full"}
                                    >
                                      {data.name}
                                    </Mark>{" "}
                                    님의 선임 종료일이
                                  </Heading>
                                  <Heading
                                    size={"xs"}
                                    // display={"flex"}
                                    px={1}
                                  >
                                    <Mark
                                      bg="orange.200"
                                      color="black"
                                      px={2}
                                      rounded={"full"}
                                    >
                                      {data.end_date}
                                    </Mark>
                                    입니다.
                                  </Heading>
                                </VStack>
                              ) : (
                                <VStack
                                  alignItems={"start"}
                                  justifyContent={"center"}
                                >
                                  <Heading
                                    size={"xs"}
                                    // display={"flex"}
                                    px={1}
                                  >
                                    <Mark
                                      bg="green.100"
                                      color="black"
                                      px={2}
                                      rounded={"full"}
                                    >
                                      {data.name}
                                    </Mark>
                                    의 보험 만료일이
                                  </Heading>
                                  <Heading
                                    size={"xs"}
                                    // display={"flex"}
                                    px={1}
                                  >
                                    <Mark
                                      bg="orange.200"
                                      color="black"
                                      px={2}
                                      rounded={"full"}
                                    >
                                      {data.end_date}
                                    </Mark>
                                    입니다.
                                  </Heading>
                                </VStack>
                              )}
                            </Box>
                          ) : data.status === "incomplete" ? (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              key={idx}
                            >
                              <Icon
                                as={QuestionIcon}
                                boxSize={4}
                                color="orange.300"
                                mr={1}
                              />
                              {data.type === "fire_assignee" ? (
                                <Box display={"flex"}>
                                  <Heading
                                    size={"xs"}
                                    px={2}
                                    lineHeight={"tall"}
                                  >
                                    <Mark
                                      bg="green.100"
                                      color="black"
                                      px={1}
                                      rounded={"full"}
                                    >
                                      {data.name}
                                    </Mark>{" "}
                                    님의 선임 종료일을 등록해주세요.
                                  </Heading>
                                </Box>
                              ) : (
                                <VStack
                                  alignItems={"start"}
                                  justifyContent={"center"}
                                >
                                  <Heading size={"xs"} display={"flex"} px={1}>
                                    <Mark
                                      bg="green.100"
                                      color="black"
                                      px={2}
                                      rounded={"full"}
                                    >
                                      {data.name}
                                    </Mark>
                                    의 보험 만료일을 등록해주세요.
                                  </Heading>
                                </VStack>
                              )}
                            </Box>
                          ) : null
                        )}
                      </VStack>
                    </Box>
                  ) : (
                    <VStack
                      bg="gray.100"
                      rounded={"lg"}
                      h={"100%"}
                      w={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Heading size={"xs"} px={1}>
                        소방안전관리자 & 화재보험을 등록해주세요
                      </Heading>
                    </VStack>
                  )}
                </VStack>
              </Box>
            </GridItem>
            <GridItem>
              <Box
                px={3}
                py={3}
                borderWidth={2}
                borderRadius={"lg"}
                h={isBreakPoint ? "30vh" : "100%"}
              >
                <VStack h={"100%"} alignItems={"start"}>
                  <Heading
                    // w={"100%"}
                    bg="blue.100"
                    size={"xs"}
                    px={2}
                    py={1}
                    rounded={"full"}
                  >
                    승강기
                  </Heading>
                  {noticeData?.elevator_check &&
                  noticeData?.elevator_check.length > 0 ? (
                    <Box h={"100%"} w={"100%"} overflowY={"auto"}>
                      <VStack alignItems={"start"} justifyContent={"center"}>
                        <>
                          {noticeData.elevator_check.map((data, idx) => (
                            <VStack
                              alignItems={"start"}
                              justifyContent={"center"}
                              key={idx}
                            >
                              <Heading
                                size={"xs"}
                                // display={"flex"}
                                px={1}
                                // lineHeight={"tall"}
                              >
                                <Mark
                                  bg="green.100"
                                  color="black"
                                  px={2}
                                  rounded={"full"}
                                >
                                  {data.assignee}
                                </Mark>{" "}
                                님의 교육 만료일이
                              </Heading>
                              <Heading size={"xs"} px={1}>
                                <Mark
                                  bg="orange.200"
                                  color="black"
                                  px={2}
                                  rounded={"full"}
                                >
                                  {data.elevator_course_valid_end_date}
                                </Mark>
                                입니다.
                              </Heading>
                            </VStack>
                          ))}
                        </>
                      </VStack>
                    </Box>
                  ) : (
                    <VStack
                      bg="gray.100"
                      rounded={"lg"}
                      h={"100%"}
                      w={"100%"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Heading size={"xs"}>알림없음</Heading>
                    </VStack>
                  )}
                </VStack>
              </Box>
            </GridItem>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
}
