import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  FocusLock,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IBuildingFacility } from "../../types";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editBuildingFacility } from "../../api";

export interface IFireSafetyAssigneeProps {
  data: IBuildingFacility | undefined;
}
export default function EditFireSafetyAssigneeInfoPopover({
  data,
}: IFireSafetyAssigneeProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBuildingFacility>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const editMutation = useMutation({
    mutationFn: editBuildingFacility,
    onSuccess: () => {
      console.log("소방안전관리자 수정 완료");
      onClose();
      queryClient.refetchQueries({ queryKey: ["fire_assignee"] });
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("소방안전관리자 저장 실패!");
      toast({
        status: "error",
        title: "오류!",
        description: "소방안전관리자 수정 실패!",
        duration: 1500,
      });
    },
  });
  const onSubmit = (editData: IBuildingFacility) => {
    console.log(data);
    console.log(data?.building);
    if (data?.building !== undefined) {
      if (editData.start_date === "") {
        delete editData["start_date"];
      }
      if (editData.end_date === "") {
        delete editData["end_date"];
      }
      editMutation.mutate({
        ...editData,
        type: "fire_assignee",
        building: data.building,
      });
    }
  };
  return (
    <Popover
      initialFocusRef={firstFieldRef}
      //   returnFocusOnClose={false}
      placement="bottom"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <IconButton
          onClick={onOpen}
          aria-label="Search Info"
          variant="outline"
          colorScheme="teal"
          size="xs"
          icon={<EditIcon />}
          mb={1}
          // mr={1}
        />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <Input
                hidden
                {...register("id", {
                  required: true,
                })}
                type="number"
                defaultValue={data?.id}
              />
              <FormLabel>이름</FormLabel>
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", {
                  required: true,
                })}
                type="text"
                defaultValue={data ? data.name : ""}
              />
            </FormControl>
            <FormControl>
              <FormLabel>선임종료일</FormLabel>
              <Input
                {...register("end_date")}
                placeholder="날짜를 선택해주세요"
                size="lg"
                //   type="datetime-local"
                type="date"
                defaultValue={data ? data.end_date : ""}
                // defaultValue={new Date().toISOString().substring(0, 10)}
                // max={new Date().toISOString().substring(0, 10)}
              />
            </FormControl>
            <HStack mt={3} justifyContent="end">
              <Button type="submit" colorScheme="teal">
                등록
              </Button>
            </HStack>
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}
