import { useQuery, useQueryClient } from "@tanstack/react-query";
import { NavLink, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Grid,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import { RepeatIcon } from "@chakra-ui/icons";
import { useEffect } from "react";
import {
  IBuildingElevatorInfo,
  IBuildingFloorInfo,
  IBuildingInfo,
} from "../types";
import {
  getBuilding,
  getBuildingElevator,
  getBuildingFloorDetail,
  getBuildingList,
} from "../api";
import BuildingInfoTab from "../components/building/BuildingInfoTab";
import BuildingFloorTab from "../components/building/BuildingFloorTab";
import BuildingSkeleton from "../components/building/BuildingSkeleton";
import BuildingElevatorTab from "../components/building/BuildingElevatorTab";
import BuildingDeleteAlertDialog from "../components/alertDialog/BuildingDeleteAlertDialog";
import BuildingPhotoUpload from "../components/modal/BuildingPhotoUploadModal";
import FindAddressModal from "../components/modal/FindAddressModal";
import RefreshBuildingInfoAlertDialog from "../components/alertDialog/RefreshBuildingInfoAlertDialog";

export default function Building() {
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();
  const {
    isOpen: isPhotoUploadOpen,
    onOpen: onPhotoUploadOpen,
    onClose: onPhotoUploadClose,
  } = useDisclosure();
  const {
    isOpen: isRefreshOpen,
    onOpen: onRefreshOpen,
    onClose: onRefreshClose,
  } = useDisclosure();
  const {
    isOpen: isAddBuildingOpen,
    onOpen: onAddBuildingOpen,
    onClose: onAddBuildingClose,
  } = useDisclosure();
  const { buildingId } = useParams();
  const queryClient = useQueryClient();
  // const [buildingData, setBuildingData] = useState<IBuildingInfo>();
  // const [buildingFloorData, setBuildingFloorData] =
  //   useState<IBuildingFloorInfo[]>();
  // const [buildingElevatorData, setBuildingElevatorData] =
  //   useState<IBuildingElevatorInfo[]>();
  const { isPending: isBuildingListLoading, data: buildingList } = useQuery<
    IBuildingInfo[]
  >({
    queryKey: [`building_list`],
    queryFn: getBuildingList,
  });
  const {
    isPending: isBuildingLoading,
    data: building,
    refetch: buildingRefetch,
  } = useQuery<IBuildingInfo>({
    queryKey: [`building`, buildingId],
    queryFn: getBuilding,
    enabled: false,
  });
  const {
    isPending: isBuildingFloorLoading,
    data: buildingFloor,
    refetch: buildingFloorRefetch,
  } = useQuery<IBuildingFloorInfo[]>({
    queryKey: [`building_floor`, buildingId],
    queryFn: getBuildingFloorDetail,
    enabled: false,
  });
  const {
    isPending: isBuildingElevatorLoading,
    data: buildingElevator,
    refetch: buildingElevatorRefetch,
  } = useQuery<IBuildingElevatorInfo[]>({
    queryKey: ["elevators", buildingId],
    queryFn: getBuildingElevator,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
    enabled: false,
  });
  const onClickRefresh = () => {
    onRefreshOpen();
  };
  const onClickDelete = () => {
    console.log(`${buildingId} clicked`);
    // setBuildingIdDelete(buildingId);
    onAlertDialogOpen();
  };
  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: "md",
    }
  );
  useEffect(() => {
    queryClient.removeQueries({ queryKey: ["building"] }); // 업데이트 이슈 캐시 쿼리 삭제
    if (buildingId) {
      buildingRefetch();
      buildingFloorRefetch();
      buildingElevatorRefetch();
    }
  }, [buildingId]);

  if (buildingList && buildingList.length > 0) {
    return (
      <Box
        py={5}
        px={{ base: 5, md: 10 }}
        h={isBreakPoint ? "88vh" : "100%"}
        overflowY={isBreakPoint ? "auto" : "hidden"}
      >
        {buildingId ? (
          <>
            <Grid
              templateColumns={{
                sm: "1fr",
                md: "1fr 1fr",
                lg: "repeat(2, 1fr)",
                xl: "repeat(2, 1fr)",
                //   "2xl": "repeat(5, 1fr)",
              }}
              gap={7}
              mb={3}
              // h={"78vh"}
            >
              {isBuildingLoading ? (
                <BuildingSkeleton />
              ) : (
                <>
                  <Card>
                    <CardBody>
                      <Box
                        position="relative"
                        overflow={"hidden"}
                        mb={3}
                        rounded="2xl"
                        height={"50vh"}
                        maxH={"50vh"}
                        // height="300"
                        // height="30%"
                        // width="100%"
                      >
                        {building?.building_photo &&
                        building?.building_photo?.length > 0 ? (
                          <Image
                            objectFit={"contain"}
                            // objectFit={"cover"}
                            minH="230"
                            // minH="250"
                            w="100%"
                            h="100%"
                            src={
                              process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                              building?.building_photo[0].file_url
                            }
                          />
                        ) : (
                          <Image
                            objectFit={"cover"}
                            minH="200"
                            w="100%"
                            h="100%"
                            src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                            alt="Green double couch with wooden legs"
                            // borderRadius="lg"
                          />
                        )}
                        <Button
                          variant={"unstyled"}
                          position="absolute"
                          top={-2}
                          right={-5}
                          onClick={onPhotoUploadOpen}
                          color="gray"
                        >
                          <FaCamera size="16px" />
                        </Button>
                      </Box>
                      <Stack h={"20%"} mt="6" spacing="3">
                        <Heading size={"md"}>{building?.name}</Heading>
                        <Text>{building?.road_address}</Text>
                      </Stack>
                    </CardBody>
                    <CardFooter justifyContent={"end"} alignItems={"center"}>
                      <Button
                        mr={3}
                        onClick={onClickRefresh}
                        size={"md"}
                        colorScheme="green"
                        variant={"outline"}
                      >
                        <RepeatIcon />
                      </Button>
                      <Button
                        colorScheme="red"
                        variant={"outline"}
                        onClick={onClickDelete}
                      >
                        삭제
                      </Button>
                    </CardFooter>
                  </Card>
                  <Tabs
                    variant="soft-rounded"
                    colorScheme="orange"
                    h={"100%"}
                    py={2}
                    // borderWidth={2}
                    // borderRadius={"lg"}
                    // borderColor={"gray.100"}
                  >
                    <TabList
                      ml={2}
                      overflowX={"auto"}
                      sx={{
                        scrollbarWidth: "5",
                        WebkitOverflowScrolling: "touch",
                        msOverflowStyle: "-ms-autohiding-scrollbar",
                        // "::-webkit-scrollbar": { display: "none" },
                        // boxShadow: "inset 0 -2px 0 rgba(0, 0, 0, 0.1)",
                        // border: "0 none",
                      }}
                    >
                      <Tab flexShrink={0}>기본 정보</Tab>
                      <Tab flexShrink={0}>층별 정보</Tab>
                      <Tab flexShrink={0}>승강기정보</Tab>
                    </TabList>
                    <TabPanels
                      h={isBreakPoint ? "50vh" : "78vh"}
                      overflowY={"auto"}
                    >
                      <TabPanel>
                        {!isBuildingLoading ? (
                          <BuildingInfoTab
                            buildingInfo={building}
                            buildingId={Number(buildingId)}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel>
                        {!isBuildingFloorLoading ? (
                          <BuildingFloorTab
                            buildingFloorInfo={buildingFloor}
                            buildingId={Number(buildingId)}
                          />
                        ) : null}
                      </TabPanel>
                      <TabPanel>
                        {!isBuildingElevatorLoading ? (
                          <BuildingElevatorTab
                            buildingElevatorInfo={buildingElevator}
                            buildingId={Number(buildingId)}
                          />
                        ) : null}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Grid>
            <BuildingDeleteAlertDialog
              isOpen={isAlertDialogOpen}
              onClose={onAlertDialogClose}
              buildingId={Number(buildingId)}
            />
            <BuildingPhotoUpload
              isOpen={isPhotoUploadOpen}
              onClose={onPhotoUploadClose}
              buildingId={Number(buildingId)}
              buildingRefetch={buildingRefetch}
            />
            <RefreshBuildingInfoAlertDialog
              isOpen={isRefreshOpen}
              onClose={onRefreshClose}
              buildingInfo={building}
              buildingRefetch={buildingRefetch}
              buildingFloorRefetch={buildingFloorRefetch}
            />
          </>
        ) : (
          <Box h={"85vh"} overflowY={"scroll"}>
            <SimpleGrid
              mt={5}
              mb={5}
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(300px, 1fr))"
            >
              {buildingList?.map((building) => (
                <Card key={building.id}>
                  <CardBody alignItems={"flex-start"}>
                    <Box
                      position="relative"
                      overflow={"hidden"}
                      mb={3}
                      rounded="2xl"
                      height="230"
                      // width="100%"
                    >
                      {building.building_photo.length > 0 ? (
                        <Image
                          objectFit={"contain"}
                          // objectFit={"cover"}
                          minH="230"
                          // minH="250"
                          w="100%"
                          h="100%"
                          src={
                            process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                            building.building_photo[0].file_url
                          }
                          // alt="building photo"
                          // borderRadius="lg"
                        />
                      ) : (
                        <Image
                          objectFit={"cover"}
                          minH="200"
                          w="100%"
                          h="100%"
                          src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                          alt="Green double couch with wooden legs"
                        />
                      )}
                    </Box>

                    <Stack mt="6" spacing="3">
                      <Heading size={"md"}>{building.name}</Heading>
                      <Text>{building.road_address}</Text>
                    </Stack>
                  </CardBody>
                  <CardFooter justifyContent={"end"}>
                    <Link as={NavLink} to={building.id.toString()}>
                      <Button>바로가기</Button>
                    </Link>
                  </CardFooter>
                </Card>
              ))}
            </SimpleGrid>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <VStack bg="gray.100" justifyContent={"center"} minH="90vh">
        <Heading size={"lg"}>아직 등록 된 건물이 없어요 </Heading>
        <Text>건물을 등록해 주세요.</Text>
        <Button
          colorScheme="green"
          variant={"solid"}
          onClick={onAddBuildingOpen}
        >
          건물 등록
        </Button>
        <FindAddressModal
          isOpen={isAddBuildingOpen}
          onClose={onAddBuildingClose}
        />
      </VStack>
    );
  }
}
