import { createBrowserRouter } from "react-router-dom";
import Root from "./routes/Root";
import NotFound from "./routes/NotFound";
import Utility from "./routes/Utility";
import Building from "./routes/Building";
import Welcome from "./routes/Welcome";
import AccessDenied from "./routes/AccessDenied";
import FireSafety from "./routes/FireSafety";
import Home from "./routes/Home";
import Facility from "./routes/Facility";
import Dashboard from "./routes/Dashboard";
import Report from "./routes/Report";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
        // element: <Building />,
      },
      {
        path: "/welcome",
        element: <Welcome />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
          {
            path: ":buildingId",
            element: <Dashboard />,
          },
        ],
      },
      {
        path: "/utility",
        element: <Utility />,
        children: [
          {
            path: ":buildingId",
            element: <Utility />,
          },
        ],
      },
      {
        path: "/building",
        element: <Building />,
        children: [
          {
            path: ":buildingId",
            element: <Building />,
          },
        ],
      },
      {
        path: "/fire-safety",
        element: <FireSafety />,
        children: [
          {
            path: ":buildingId",
            element: <FireSafety />,
          },
        ],
      },
      {
        path: "/facility",
        element: <Facility />,
        children: [
          {
            path: ":buildingId",
            element: <Facility />,
          },
        ],
      },
      {
        path: "/report",
        element: <Report />,
        children: [
          {
            path: ":buildingId",
            element: <Report />,
          },
        ],
      },

      {
        path: "/access-denied",
        element: <AccessDenied />,
      },
    ],
  },
]);

export default router;
