import {
  Box,
  Button,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import SignUpModal from "../components/modal/SignUpModal";

export default function Welcome() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Helmet>
        <title>{"Maestro"}</title>
      </Helmet>
      <VStack
        bg="gray.100"
        justifyContent={"center"}
        minH="95vh"
        spacing={4}
        bgImage={
          process.env.REACT_APP_AWS_S3_RESOURCE_URL + "welcome/background2.jpeg"
        }
        bgSize={"cover"}
        bgPosition={"center"}
      >
        <Button
          variant={"solid"}
          size={"lg"}
          // color={"rgba(255, 188, 0, 1)"}
          bgColor={"rgba(255, 188, 0, 1)"}
          _hover={{
            bg: "rgba(255, 162, 0, 1)",
          }}
          onClick={onOpen}
        >
          시작하기
        </Button>
        <HStack>
          <Text>문의:</Text>
          <Link href="mailto:buildingmaestro@gmail.com">
            buildingmaestro@gmail.com
          </Link>
        </HStack>
      </VStack>
      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </>
  );
}
