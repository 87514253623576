import {
  Flex,
  Heading,
  Icon,
  IconButton,
  Link,
  List,
  ListItem,
  Select,
  Slide,
  Spacer,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { IBuildingInfo } from "../types";
import {
  FaBuilding,
  FaChalkboardTeacher,
  FaChartLine,
  FaFileContract,
  FaFireAlt,
  FaHammer,
  FaPlus,
} from "react-icons/fa";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { getBuildingList } from "../api";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import FindAddressModal from "./modal/FindAddressModal";

export interface ISidebarProps {
  isOpen: boolean;
  onToggle: () => void;
}
export default function Sidebar({ isOpen, onToggle }: ISidebarProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { buildingId: buildingIdUpdate } = useParams(); // 화면 내 이동시 buildingId 업데이트
  const {
    isOpen: isFindAddressModalOpen,
    onOpen: onFindAddressModalOpen,
    onClose: onFindAddressModalClose,
  } = useDisclosure();
  const [buildingId, setBuildingId] = useState<string>();
  const { isPending: isBuildingLoading, data: buildingData } = useQuery<
    IBuildingInfo[]
  >({
    queryKey: ["building_list"],
    queryFn: getBuildingList,
    refetchOnWindowFocus: false,
  });
  const onChangeBuilding = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setBuildingId(e.target.value);
  };
  useEffect(() => {
    if (pathname !== "/") {
      const path = pathname.split("/");
      if (path[1] !== "/") {
        navigate(`${path[1]}/${buildingId}`);
        if (isOpen) {
          onToggle(); // 사이드네비바 닫기
        }
      }
    }
  }, [buildingId]);
  // TODO 테스트 좀 더 해보기
  useEffect(() => {
    if (buildingIdUpdate) {
      setBuildingId(buildingIdUpdate);
    } else if (buildingIdUpdate === undefined) {
      setBuildingId("");
      // if (
      //   pathname !== "/building/"
      // ) {
      //   navigate("/building");
      // }
      // TODO building 으로 갈지 / 로 갈지..?
      // navigate("/");
      navigate("/building");
    }
    // setBuildingId(buildingIdUpdate);
  }, [buildingIdUpdate]);

  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: true,
      lg: false,
    },
    {
      // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
      // (Defaults to 'base')
      fallback: "lg",
    }
  );

  const sideBarItems = [
    {
      name: "대시보드",
      to: "/dashboard",
      icon: FaChalkboardTeacher,
      show: buildingId ? true : false, // 빌딩 선택 시에만 표시
    },
    {
      name: "사용량",
      to: "/utility",
      icon: FaChartLine,
      show: buildingId ? true : false, // 빌딩 선택 시에만 표시
    },
    {
      name: "건물정보",
      to: "/building",
      icon: FaBuilding,
      show: true, // 항상 사이드네비 표시
    },
    {
      name: "소방 및 안전",
      to: "/fire-safety",
      icon: FaFireAlt,
      show: buildingId ? true : false, // 빌딩 선택 시에만 표시
    },
    {
      name: "시설물 관리",
      to: "/facility",
      icon: FaHammer,
      show: buildingId ? true : false, // 빌딩 선택 시에만 표시
    },
    {
      name: "보고서",
      to: "/report",
      icon: FaFileContract,
      show: buildingId ? true : false, // 빌딩 선택 시에만 표시
    },
  ];
  return (
    <>
      <Slide direction="left" in={isOpen} style={{ top: 81, zIndex: 10 }}>
        <VStack
          // w={"15%"}
          w={{ base: "15%", md: "10%", lg: "15%" }}
          h={"full"}
          px={isBreakPoint ? 2 : 3}
          py={4}
          borderRightWidth={1}
          spacing={7}
          // zIndex={10}
          //   borderRightColor={"gray"}
          // bgColor={"orange.200"}
          bgColor={"white"}
        >
          {!isBreakPoint ? (
            <Flex alignItems={"center"} w={"full"}>
              <Select
                variant="outline"
                borderColor={"gray.600"}
                borderWidth={2}
                _hover={{
                  bg: "orange.100",
                }}
                _focus={{
                  bg: "orange.100",
                }}
                placeholder="건물을 선택해주세요"
                mr={3}
                value={buildingId}
                onChange={onChangeBuilding}
              >
                {buildingData?.map((building) => (
                  <option key={building.id} value={building.id}>
                    {building.name}
                  </option>
                ))}
              </Select>
              <Spacer />
              <IconButton
                aria-label="add building"
                icon={<FaPlus />}
                size={"sm"}
                variant={"ghost"}
                _hover={{
                  bg: "orange.100",
                }}
                onClick={onFindAddressModalOpen}
              />
            </Flex>
          ) : null}

          <List spacing={7} w={"100%"}>
            {sideBarItems.map((item, idx) =>
              item.show ? (
                <ListItem key={idx}>
                  <Link
                    display={"block"}
                    as={NavLink}
                    to={buildingId ? `${item.to}/${buildingId}` : `${item.to}`}
                    w="full"
                    _hover={{
                      bg: "orange.200",
                    }}
                    _focus={{
                      bg: "orange.200",
                    }}
                    _activeLink={{ bg: "orange.400", color: "white" }}
                    borderRadius={"md"}
                    onClick={onToggle}
                  >
                    <Flex alignItems="center" p={2}>
                      <Icon
                        boxSize={"6"}
                        color={"gray.600"}
                        as={item.icon}
                        mr={3}
                      />
                      {/* <Spacer /> */}
                      {!isBreakPoint ? (
                        <Heading fontSize={"md"}>{item.name}</Heading>
                      ) : null}
                    </Flex>
                  </Link>
                </ListItem>
              ) : null
            )}
          </List>
        </VStack>
        <FindAddressModal
          isOpen={isFindAddressModalOpen}
          onClose={onFindAddressModalClose}
        />
      </Slide>
    </>
  );
}
