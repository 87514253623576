import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { FaChild, FaEnvelope, FaLock, FaUserNinja } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { IModalProps, ISignUp } from "../../types";
import { createMasterUser } from "../../api";
import SignUpAlertDialog from "../alertDialog/SignUpAlertDialog";

export default function SignUpModal({ onClose, isOpen }: IModalProps) {
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ISignUp>();
  const toast = useToast();
  const mutation = useMutation({
    mutationFn: createMasterUser,
    onSuccess: (data) => {
      toast({
        title: "등록 되었습니다!",
        status: "success",
      });
      onClose();
      reset();
      Sentry.captureMessage("회원 가입 완료 메세지", "log");
    },
    onError: (error) => {
      console.log("등록 실패하였습니다.");
      Sentry.captureException(error);
    },
    // onSettled: () => {
    //   onAlertOpen(); // 문의 알람
    // },
  });
  const onSubmit = (data: ISignUp) => {
    console.log("submit");
    mutation.mutate({ ...data, role: "master", status: "pending" });
  };
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>회원 가입</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaUserNinja />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.username?.message)}
                {...register("username", {
                  required: "Please write a username",
                })}
                variant={"filled"}
                placeholder="로그인 아이디"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaLock />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.password?.message)}
                {...register("password", {
                  required: "Please write a password",
                })}
                type="password"
                variant={"filled"}
                placeholder="비밀번호"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaEnvelope />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Please write a email",
                })}
                type="text"
                variant={"filled"}
                placeholder="이메일 주소"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaChild />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Please write a name",
                })}
                type="text"
                variant={"filled"}
                placeholder="이름 / 회사명"
              />
            </InputGroup>
          </VStack>
          {/* {mutation.isError ? (
            <Text color="red.500" textAlign={"center"} fontSize="sm">
              값을 확인해주세요.
            </Text>
          ) : null} */}
          <Button
            // isLoading={mutation.isLoading}
            type="submit"
            mt={4}
            mb={4}
            colorScheme={"red"}
            w="100%"
            onClick={onAlertOpen}
          >
            등록
          </Button>
        </ModalBody>
      </ModalContent>
      <SignUpAlertDialog isOpen={isAlertOpen} onClose={onAlertClose} />
    </Modal>
  );
}
