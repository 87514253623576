import {
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function AccessDenied() {
  return (
    <VStack bg="gray.100" justifyContent={"center"} minH="90vh">
      <Heading>권한이 없습니다.</Heading>
      <Text>관리자에게 문의 해 주세요.</Text>
      <HStack>
        <Text as="b">문의:</Text>
        <ChakraLink href="mailto:buildingmaestro@gmail.com" as="b">
          buildingmaestro@gmail.com
        </ChakraLink>
      </HStack>
      <Link to="/">
        <Button colorScheme="red" variant={"link"}>
          Go Back &rarr;
        </Button>
      </Link>
    </VStack>
  );
}
