import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IUser } from "../../types";
import { FaEnvelope, FaPhone, FaUser, FaUserNinja } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { IEditMeVariables, editMe } from "../../api";

interface ProfileProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser | undefined;
}

export default function ProfileModal({ isOpen, onClose, user }: ProfileProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditMeVariables>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: editMe,
    onSuccess: (data) => {
      toast({
        title: "변경 되었습니다!",
        status: "success",
      });
      onClose();
      queryClient.refetchQueries({ queryKey: ["me"] });
      reset();
    },
    onError: (error) => {
      console.log("mutation failed");
    },
  });
  const onSubmit = (data: IEditMeVariables) => {
    mutation.mutate({ ...data });
  };
  const onCloseReset = () => {
    reset();
    onClose();
  };
  // TODO 프로필 클릭시에만 호출 가능?
  // console.log(errors);
  // console.log(user);
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"start"}>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaUserNinja />
                  </Box>
                }
              />
              <Input
                readOnly
                defaultValue={user?.username}
                variant={"filled"}
                placeholder="username"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaUser />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", {
                  required: "Please write a name",
                })}
                defaultValue={user?.name}
                variant={"filled"}
                placeholder="name"
              />
              <Text pl={2} fontSize={"xs"} color="red.500">
                {errors.name?.message}
              </Text>
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaEnvelope />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.email?.message)}
                {...register("email", {
                  required: "Please write a username",
                })}
                defaultValue={user?.email}
                variant={"filled"}
                placeholder="email"
              />
              <Text pl={2} fontSize={"xs"} color="red.500">
                {errors.email?.message}
              </Text>
            </InputGroup>
            <InputGroup>
              <InputLeftElement
                children={
                  <Box color="gray.500">
                    <FaPhone />
                  </Box>
                }
              />
              <Input
                isInvalid={Boolean(errors.phone_number?.message)}
                {...register("phone_number", {
                  required: "Please write a phone_number",
                })}
                defaultValue={user?.phone_number}
                type="tel"
                variant={"filled"}
                placeholder="phone number"
              />
              <Text pl={2} fontSize={"xs"} color="red.500">
                {errors.phone_number?.message}
              </Text>
            </InputGroup>
          </VStack>
          {mutation.isError ? (
            <Text color="red.500" textAlign={"center"} fontSize="sm">
              정보를 확인해 주세요.
            </Text>
          ) : null}
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              저장
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
