import React, { useEffect } from "react";
import useUser from "../lib/useUser";
import { useNavigate } from "react-router-dom";

interface IProtectedPageProps {
  children: React.ReactNode;
}

export default function AccessPage({ children }: IProtectedPageProps) {
  const { userLoading, user, isLoggedIn } = useUser();
  const navigate = useNavigate();
  console.log(user);
  console.log(isLoggedIn);
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/welcome");
    }
    if (!userLoading) {
      if (isLoggedIn) {
        if (user?.status === "approved") {
          // navigate("/");
          navigate("/building");
        }
        // if (user?.status === "onboarding") {
        //   navigate("/onboard");
        // }
        if (user?.status === "pending" || user?.status === "declined") {
          navigate("/access-denied");
        }
      }
    }
  }, [userLoading, isLoggedIn, navigate]);
  return <>{children}</>;
}
