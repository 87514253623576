import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  InputLeftElement,
  Link,
  Tag,
  TagLabel,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IBuildingFacility, IBuildingFacilityRead } from "../../types";
import { useEffect, useRef, useState } from "react";
import { FaCamera } from "react-icons/fa";
import {
  QueryClient,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createFacilityPhoto,
  deleteFacility,
  editBuildingFacility,
  uploadImage,
} from "../../api";
import { useForm } from "react-hook-form";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import PdfViewerModal from "../modal/PdfViewerModal";
import ImageTypeAlertDialog from "../alertDialog/ImageTypeAlertDialog";

export interface IFacilityDetailProps {
  facilityItem: IBuildingFacilityRead | undefined;
  setFacilityItem: (arg: IBuildingFacilityRead | undefined) => void;
  buildingId: number;
  facilityRefetch: () => void;
}
export default function FacilityDetail({
  facilityItem,
  setFacilityItem,
  buildingId,
  facilityRefetch,
}: IFacilityDetailProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isImageAlertOpen,
    onClose: onImageAlertClose,
    onOpen: onImageAlertOpen,
  } = useDisclosure();
  const [name, setName] = useState<string>("");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [pdfPreview, setPdfPreview] = useState<string>("");

  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    watch,
    register,
    formState: { errors },
    reset,
  } = useForm();
  const { ref, ...rest } = register("files");
  const images = watch("files");
  const toast = useToast();

  const mutation = useMutation({
    mutationFn: editBuildingFacility,
    onSuccess: (data: IBuildingFacilityRead) => {
      console.log("facility 수정 완료");
      if (images && images.length > 0) {
        if (["fire_assignee", "fire_insurance"].includes(data.category.type)) {
          if (!images[0].type.includes("pdf")) {
            onImageAlertOpen();
          } else {
            uploadImageMutation.mutate({
              files: images,
              type: "facility",
              id: data.id.toString(),
            });
            reset();
          }
        } else {
          if (!images[0].type.includes("image")) {
            onImageAlertOpen();
          } else {
            uploadImageMutation.mutate({
              files: images,
              type: "facility",
              id: data.id.toString(),
            });
            reset();
          }
        }
      } else {
        facilityRefetch();
      }
      toast({
        status: "success",
        title: "수정 완료 !",
      });
      //   queryClient.refetchQueries({ queryKey: ["fire_assignee"] });
    },
    onError: (e: any) => {
      console.log(e.response.data);
      if (e.response.data.non_field_errors) {
        toast({
          status: "error",
          title: "오류!",
          description: e.response.data.non_field_errors[0],
          duration: 1500,
        });
      } else {
        toast({
          status: "error",
          title: "오류!",
          description: "facility 수정 실패!",
          duration: 1500,
        });
      }
    },
  });
  const createPhotoMutation = useMutation({
    mutationFn: createFacilityPhoto,
    onSuccess: () => {
      facilityRefetch();
      toast({
        status: "success",
        title: "사진 변경 완료!",
        isClosable: true,
        // description: "",
      });
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn: uploadImage,
    onSuccess: (result: any) => {
      if (facilityItem) {
        createPhotoMutation.mutate({
          data: result,
          building_id: buildingId,
          facility_id: Number(facilityItem.id),
        });
      }
    },
  });
  const deleteFacilityMutation = useMutation({
    mutationFn: deleteFacility,
    onSuccess: () => {
      toast({
        status: "success",
        title: "삭제 완료!",
      });
      facilityRefetch();
      setFacilityItem(undefined);
    },
    onError: () => {
      toast({
        status: "error",
        title: "실패!",
        description: "다시 시도해주세요",
      });
    },
  });

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const onChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };
  const onChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };
  const onChangeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const onDelete = () => {
    if (facilityItem) {
      deleteFacilityMutation.mutate({
        buildingId: buildingId,
        facilityId: facilityItem.id,
      });
    }
  };

  const onSubmit = () => {
    if (facilityItem) {
      const facilityData = {
        id: facilityItem.id,
        building: buildingId,
        name: name,
        description: description,
      };
      if (startDate !== "" && endDate !== "") {
        mutation.mutate({
          ...facilityData,
          start_date: startDate,
          end_date: endDate,
        });
      } else if (startDate !== "") {
        mutation.mutate({ ...facilityData, start_date: startDate });
      } else if (endDate !== "") {
        mutation.mutate({ ...facilityData, end_date: endDate });
      } else {
        mutation.mutate(facilityData);
      }
    }
  };

  useEffect(() => {
    if (facilityItem) {
      setName(facilityItem.name);
      setStartDate(facilityItem.start_date ? facilityItem?.start_date : "");
      setEndDate(facilityItem.end_date ? facilityItem?.end_date : "");
      setDescription(facilityItem.description ? facilityItem.description : "");
      reset();
      setImagePreview([]);
    }
  }, [facilityItem]);
  useEffect(() => {
    if (images && images.length > 0) {
      if (facilityItem?.category.type === "fire_assignee") {
        // let reader = new FileReader();
        // reader.readAsDataURL(images[0]);
        // reader.onload = () => {
        //   console.log(reader.result);
        // };
        const pdfPreview = URL.createObjectURL(images[0]);
        setPdfPreview(pdfPreview);
      } else {
        setImagePreview([]); // clear preview
        const imageArray = Array.from(images).map((file: any) =>
          URL.createObjectURL(file)
        );
        setImagePreview((prevImages) => prevImages.concat(imageArray));
      }
    }
  }, [images]);
  console.log(images);
  console.log(imagePreview);
  return (
    <>
      {facilityItem ? (
        <>
          <VStack w={"100%"} maxWidth={"50wh"} key={facilityItem.id} py={2}>
            <Tag size={"md"} variant={"solid"} colorScheme="orange" mb={2}>
              <TagLabel>{facilityItem.category.name}</TagLabel>
            </Tag>
            {["fire_assignee", "fire_insurance"].includes(
              facilityItem.category.type
            ) ? (
              <VStack w={"100%"} justifyContent={"start"}>
                <Box
                  display={"flex"}
                  w={"100%"}
                  h={"10vh"}
                  mb={2}
                  alignItems={"center"}
                  // justifyContent={"start"}
                >
                  <Input
                    type="file"
                    accept=".pdf"
                    ref={(e) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    {...rest}
                    hidden
                  />
                  <Button onClick={() => inputRef.current?.click()} mr={2}>
                    파일 업로드
                  </Button>
                  {images && images.length > 0 ? (
                    <HStack alignItems={"center"} w={"100%"} spacing={3}>
                      {pdfPreview && (
                        <>
                          <Button onClick={onOpen}>파일 보기</Button>
                          <PdfViewerModal
                            isOpen={isOpen}
                            onClose={onClose}
                            data={pdfPreview}
                          />
                        </>
                      )}
                      <Text>
                        {images[0].name.length > 30
                          ? images[0].name.slice(0, 30) + "..."
                          : images[0].name}
                      </Text>
                    </HStack>
                  ) : facilityItem.facility_photo.length > 0 ? (
                    <>
                      <Button onClick={onOpen}>파일 보기</Button>
                      <PdfViewerModal
                        isOpen={isOpen}
                        onClose={onClose}
                        data={
                          process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                          facilityItem.facility_photo[0].file_url
                        }
                      />
                    </>
                  ) : (
                    // <Link
                    //   href={
                    //     process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                    //     facilityItem.facility_photo[0].file_url
                    //   }
                    //   isExternal
                    // >
                    //   <Heading size={"sm"}>
                    //     {facilityItem.category.type === "fire_assignee"
                    //       ? "안전관리자"
                    //       : "화재보험"}{" "}
                    //     PDF
                    //   </Heading>{" "}
                    //   <ExternalLinkIcon mx="2px" />
                    // </Link>
                    <Text>업로드 된 파일 없음</Text>
                  )}
                </Box>
              </VStack>
            ) : (
              <Box
                display={"flex"}
                w={"50%"}
                h={"25vh"}
                mb={2}
                borderWidth={2}
                borderRadius={"lg"}
                justifyContent={"center"}
                alignItems={"center"}
                _hover={{
                  cursor: "pointer",
                  bg: "orange.100",
                }}
                onClick={() => inputRef.current?.click()}
              >
                <Input
                  type="file"
                  accept="image/*"
                  // multiple={true}
                  ref={(e) => {
                    ref(e);
                    inputRef.current = e;
                  }}
                  {...rest}
                  hidden
                />
                {imagePreview.length > 0 ? (
                  imagePreview.map((image, idx) => (
                    <Image
                      objectFit={"contain"}
                      w="100%"
                      h="100%"
                      key={idx}
                      src={image}
                    />
                  ))
                ) : facilityItem.facility_photo.length > 0 ? (
                  <Image
                    objectFit={"contain"}
                    //   objectFit={"cover"}
                    w="100%"
                    h="100%"
                    src={
                      process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                      facilityItem.facility_photo[0].file_url
                    }
                  />
                ) : (
                  <Icon as={FaCamera} boxSize={20} color={"gray"} />
                )}
              </Box>
            )}

            <VStack
              w={"100%"}
              h={
                ["fire_assignee", "fire_insurance"].includes(
                  facilityItem.category.type
                )
                  ? "55vh"
                  : "40vh"
              }
              overflowY={"scroll"}
            >
              <FormControl>
                <FormLabel>이름</FormLabel>
                <Input
                  // isInvalid={Boolean(errors.name?.message)}
                  errorBorderColor="crimson"
                  borderColor={"orange.300"}
                  focusBorderColor="orange.300"
                  //   {...register("name", {
                  //     required: "이름을 등록해주세요.",
                  //   })}
                  type="text"
                  value={name}
                  onChange={onChangeName}
                  // value={facilityItem.name}
                  //   defaultValue={facilityItem.name}
                />
              </FormControl>
              <FormControl>
                <FormLabel>
                  {facilityItem.category.type === "fire_facility" ||
                  facilityItem.category.type === "etc_facility"
                    ? "설치일"
                    : "시작일"}
                </FormLabel>
                <Input
                  // isInvalid={Boolean(errors.name?.message)}
                  errorBorderColor="crimson"
                  borderColor={"orange.300"}
                  focusBorderColor="orange.300"
                  //   {...register("name", {
                  //     required: "이름을 등록해주세요.",
                  //   })}
                  type="date"
                  value={startDate}
                  onChange={onChangeStartDate}
                  //   value={
                  //     facilityItem.start_date ? facilityItem.start_date : ""
                  //   }
                  //   defaultValue={facilityItem.start_date}
                />
              </FormControl>
              {facilityItem.category.type === "fire_assignee" ||
              facilityItem.category.type === "fire_insurance" ? (
                <FormControl>
                  <FormLabel>종료일</FormLabel>
                  <Input
                    // isInvalid={Boolean(errors.name?.message)}
                    errorBorderColor="crimson"
                    borderColor={"orange.300"}
                    focusBorderColor="orange.300"
                    //   {...register("name", {
                    //     required: "이름을 등록해주세요.",
                    //   })}
                    type="date"
                    value={endDate}
                    onChange={onChangeEndDate}
                    // value={
                    //   facilityItem.end_date ? facilityItem.end_date : ""
                    // }
                    //   defaultValue={facilityItem.start_date}
                  />
                </FormControl>
              ) : null}
              <FormControl>
                <FormLabel>비고</FormLabel>
                <Textarea
                  h={"17vh"}
                  // isInvalid={Boolean(errors.name?.message)}
                  errorBorderColor="crimson"
                  borderColor={"orange.300"}
                  focusBorderColor="orange.300"
                  //   type="text"
                  value={description}
                  onChange={onChangeDescription}
                  //   value={facilityItem.name}
                  //   defaultValue={facilityItem.name}
                />
              </FormControl>
            </VStack>
          </VStack>
          <HStack>
            <Button
              w={"100%"}
              variant={"solid"}
              colorScheme="red"
              onClick={onDelete}
            >
              삭제
            </Button>
            <Button
              type="submit"
              w={"100%"}
              variant={"solid"}
              colorScheme="green"
              onClick={onSubmit}
            >
              수정
            </Button>
          </HStack>

          <ImageTypeAlertDialog
            isOpen={isImageAlertOpen}
            onClose={onImageAlertClose}
            data={
              ["fire_assignee", "fire_insurance"].includes(
                facilityItem.category.type
              )
                ? "PDF"
                : "이미지"
            }
          />
        </>
      ) : (
        <VStack>
          <Box
            display={"flex"}
            w={"100%"}
            h={"80vh"}
            bgColor={"gray.100"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Heading size={"md"} color={"gray.500"}>
              선택 된 시설물이 없습니다
            </Heading>
          </Box>
        </VStack>
      )}
    </>
  );
}
