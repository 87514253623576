import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IModalProps, ITinyUser, IUserManagementList } from "../../types";
import { FaUser } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { ICreateUserVariables, createUser, getUser } from "../../api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import UserDeleteAlertDialog from "../alertDialog/UserDeleteAlertDialog";

export default function CreateUserModal({ isOpen, onClose }: IModalProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  // const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [userDelete, setUserDelete] = useState<ITinyUser>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    isOpen: isAlertDialogOpen,
    onOpen: onAlertDialogOpen,
    onClose: onAlertDialogClose,
  } = useDisclosure();
  const { isLoading, data, refetch } = useQuery<IUserManagementList[]>({
    queryKey: ["user_managements"],
    queryFn: getUser,
    retry: false,
    // enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const mutation = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      toast({
        status: "success",
        title: "생성되었습니다!",
        description: "사용자 생성 완료!",
      });
      queryClient.invalidateQueries({ queryKey: ["user_managements"] });
    },
    onError: (e: any) => {
      console.log(e.message);
      if (e.response.data.username) {
        toast({
          status: "error",
          title: "사용자 이름 중복.",
          description: e.response.data.username[0],
        });
        // } else if (username === "" || role === "") {
      } else if (!inputRef.current?.value || role === "") {
        toast({
          status: "error",
          title: "필수 값을 확인해주세요.",
          description: "사용자 이름 또는 권한을 입력해주세요.",
        });
      }
    },
  });
  // const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setUsername(event.target.value);
  // };
  const onChangeRole = (event: any) => {
    setRole(event.target.value);
  };
  const addUser = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    // const newUser: INewUser = { username: username, role: role, status: "" };
    const user: ICreateUserVariables = {
      username: inputRef.current?.value ? inputRef.current.value : "",
      password: inputRef.current?.value ? inputRef.current.value : "",
      role: role,
      status: "pending",
    };
    mutation.mutate(user);
    // setUsername("");
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    setRole("");
  };
  // const clearUser = () => {
  //   setUsers([]);
  // };
  const onCloseReset = () => {
    onClose();
  };
  const onClickDelete = (user: ITinyUser) => {
    // setUsers(users.filter((d) => d.username !== user.username));
    setUserDelete(user);
    onAlertDialogOpen();
  };
  // console.log(data);
  return (
    <Modal size={"xl"} onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>사용자 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form">
          <VStack>
            <HStack w="100%">
              <InputGroup>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaUser />
                    </Box>
                  }
                />
                <Input
                  ref={inputRef}
                  variant={"filled"}
                  placeholder="new username"
                  // value={username}
                  // onChange={onChangeUsername}
                />
              </InputGroup>
              <Select
                w="80%"
                variant="filled"
                placeholder="권한을 선택해주세요"
                value={role}
                onChange={onChangeRole}
              >
                <option value="staff">staff</option>
                <option value="user">user</option>
              </Select>
              <Button
                isLoading={mutation.isPending}
                colorScheme="blue"
                onClick={addUser}
              >
                +
              </Button>
            </HStack>
          </VStack>
          <Box mt={5} maxH={"50vh"} overflowY="auto">
            <TableContainer>
              <Table variant="simple" size={"md"}>
                <Thead>
                  <Tr>
                    <Th>Username</Th>
                    {/* <Th>Password</Th> */}
                    <Th>Role</Th>
                    <Th>Status</Th>
                    <Th>-</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {!isLoading && data
                    ? data?.map((d) => (
                        <Tr key={d.user.username}>
                          <Td>{d.user?.username}</Td>
                          {/* <Td>{user?.username}</Td> */}
                          <Td>{d.user?.role}</Td>
                          <Td>{d.user?.status}</Td>
                          <Td>
                            {" "}
                            <Button
                              size={"sm"}
                              onClick={() => onClickDelete(d.user)}
                            >
                              삭제
                            </Button>
                          </Td>
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>
      </ModalContent>
      <UserDeleteAlertDialog
        isOpen={isAlertDialogOpen}
        onClose={onAlertDialogClose}
        data={userDelete}
        // clearUser={clearUser}
      />
    </Modal>
  );
}
