import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { createBuildingFacility } from "../../api";

interface IAlertProps {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  buildingId: number;
}
export default function AddFaciltyAlertDialog({
  isOpen,
  onClose,
  name,
  buildingId,
}: IAlertProps) {
  const cancelRef = useRef(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createBuildingFacility,
    onSuccess: () => {
      toast({
        status: "success",
        title: "추가되었습니다!",
        description: `${name} 추가되었습니다`,
      });
      onClose();
    },
    onError: (e: any) => {
      console.log(e.response.data.non_field_errors);
      if (e.response.data.non_field_errors) {
        toast({
          status: "error",
          title: "추가 실패",
          description: e.response.data.non_field_errors[0],
        });
        onClose();
      } else {
        toast({
          status: "error",
          title: "추가 실패.",
          description: "다시 시도해주세요.",
        });
      }
    },
  });
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            추가
          </AlertDialogHeader>
          <AlertDialogBody>{name} 추가 하시겠습니까?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() =>
                mutation.mutate({
                  name: name,
                  building: buildingId,
                  type: "fire_facility",
                })
              }
            >
              추가
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
