import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { deleteUser } from "../../api";

interface IAlertProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
  // clearUser: () => void;
}
export default function UserDeleteAlertDialog({
  isOpen,
  onClose,
  data,
}: // clearUser,
IAlertProps) {
  const cancelRef = useRef(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      toast({
        status: "success",
        title: "삭제되었습니다!",
        description: "사용자 삭제 완료!",
      });
      // clearUser();
      queryClient.invalidateQueries({ queryKey: ["user_managements"] });
      onClose();
    },
    onError: (e: any) => {
      console.log(e.message);
      toast({
        status: "error",
        title: "삭제 실패.",
        description: "다시 시도해주세요.",
      });
    },
  });
  console.log(data);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            삭제
          </AlertDialogHeader>
          <AlertDialogBody>정말로 삭제하시겠습니까?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => mutation.mutate(data.id)}
            >
              삭제
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
