import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IBuildingInfo } from "../../types";
import { useForm } from "react-hook-form";
import { IEditBuildingInfoVariable, editBuilding } from "../../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export interface BuildingInfoTabProps {
  buildingInfo: IBuildingInfo | undefined;
  buildingId: number;
}

export default function BuildingInfoTab({
  buildingInfo,
  buildingId,
}: BuildingInfoTabProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IEditBuildingInfoVariable>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: editBuilding,
    onSuccess: (data) => {
      console.log(data);
      toast({
        title: "빌딩 정보 업데이트 완료!",
        status: "success",
      });
      // queryClient.refetchQueries(["building_info_list"]);
      queryClient.refetchQueries({ queryKey: ["building", buildingId] });
    },
    onError: (e: any) => {
      console.log(e);
      console.log("빌딩 정보 업데이트 실패!");
      if (e.response.data.name) {
        toast({
          status: "error",
          // title: e.response.data.name[0],
          title: "빌딩 이름이 입력되지 않았습니다.",
          description: "빌딩 이름을 입력해주세요.",
        });
      } else {
        toast({
          status: "error",
          title: "필수 값을 확인해주세요.",
          // description: "사용자 이름 또는 권한을 입력해주세요.",
        });
      }
    },
  });
  const onSubmit = (data: IEditBuildingInfoVariable) => {
    mutation.mutate({ ...data, buildingId: buildingId });
  };
  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <FormControl>
            <FormLabel>건물 이름</FormLabel>
            <Input
              // isInvalid={Boolean(errors.name?.message)}
              errorBorderColor="crimson"
              borderColor={"orange.300"}
              focusBorderColor="orange.300"
              {...register("name", {
                required: "건물 이름을 등록해주세요.",
              })}
              type="text"
              defaultValue={buildingInfo?.name}
            />
          </FormControl>
          <FormControl>
            <FormLabel>지번 주소</FormLabel>
            <Input
              // variant={"filled"}
              focusBorderColor="gray.200"
              readOnly
              // {...register("address", { required: true })}
              type="text"
              value={buildingInfo?.address}
            />
          </FormControl>
          <FormControl>
            <FormLabel>도로명 주소</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("road_address", { required: true })}
              type="text"
              value={buildingInfo?.road_address}
            />
          </FormControl>
          <FormControl>
            <FormLabel>우편번호</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("zip_code", { required: true })}
              type="number"
              value={buildingInfo?.zip_code}
            />
          </FormControl>
          <FormControl>
            <FormLabel>주용도코드명</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("purpose_name", { required: true })}
              type="text"
              value={buildingInfo?.purpose_name}
            />
          </FormControl>
          <FormControl>
            <FormLabel>기타용도</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("etc_purpose_name", { required: true })}
              type="text"
              value={buildingInfo?.etc_purpose_name}
            />
          </FormControl>
          <FormControl>
            <FormLabel>높이</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("height", { required: true })}
              type="number"
              value={buildingInfo?.height}
            />
          </FormControl>
          <FormControl>
            <FormLabel>지상층수</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("ground_floor_cnt", { required: true })}
              type="number"
              value={buildingInfo?.ground_floor_cnt}
            />
          </FormControl>
          <FormControl>
            <FormLabel>지하층수</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("underground_floor_cnt", {
              //   required: true,
              // })}
              type="number"
              value={buildingInfo?.underground_floor_cnt}
            />
          </FormControl>
          <FormControl>
            <FormLabel>승용승강기수</FormLabel>
            <Input
              // readOnly
              // {...register("elevator_cnt")}
              borderColor={"orange.300"}
              errorBorderColor="crimson"
              focusBorderColor="orange.300"
              min={0}
              type="number"
              defaultValue={buildingInfo?.elevator_cnt}
              // value={elevators || 0} // 자동 엘레베이터 수 업데이트 시
            />
          </FormControl>
          <FormControl>
            <FormLabel>비상용승강기수</FormLabel>
            <Input
              // readOnly
              // {...register("emergency_elevator_cnt")}
              borderColor={"orange.300"}
              errorBorderColor="crimson"
              focusBorderColor="orange.300"
              min={0}
              type="number"
              defaultValue={buildingInfo?.emergency_elevator_cnt}
            />
          </FormControl>
          <FormControl>
            <FormLabel>옥내기계식대수(대)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("indoor_mech_cnt", { required: true })}
              type="number"
              value={buildingInfo?.indoor_mech_cnt}
            />
          </FormControl>
          <FormControl>
            <FormLabel>옥내기계식면적(㎡)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("indoor_mech_area", { required: true })}
              type="number"
              value={buildingInfo?.indoor_mech_area}
            />
          </FormControl>
          <FormControl>
            <FormLabel>허가일</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("permission_date", { required: true })}
              type="number"
              value={buildingInfo?.permission_date}
            />
          </FormControl>
          <FormControl>
            <FormLabel>착공일</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("construction_start_date")}
              type="number"
              value={buildingInfo?.construction_start_date}
            />
          </FormControl>
          <FormControl>
            <FormLabel>사용승인일</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("use_approved_date", { required: true })}
              type="number"
              value={buildingInfo?.use_approved_date}
            />
          </FormControl>
          <FormControl>
            <FormLabel>시군구코드</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("sigungu_code", { required: true })}
              type="text"
              value={buildingInfo?.sigungu_code}
            />
          </FormControl>
          <FormControl>
            <FormLabel>법정동코드</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("bjdong_code", { required: true })}
              type="text"
              value={buildingInfo?.bjdong_code}
            />
          </FormControl>
          <FormControl>
            <FormLabel>번</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("bun", { required: true })}
              type="text"
              value={buildingInfo?.bun}
            />
          </FormControl>
          <FormControl>
            <FormLabel>지</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("ji", { required: true })}
              type="text"
              value={buildingInfo?.ji}
            />
          </FormControl>
          <FormControl>
            <FormLabel>관리건축물대장번호</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("register_pk", { required: true })}
              type="text"
              value={buildingInfo?.register_pk}
            />
          </FormControl>
          <FormControl>
            <FormLabel>대지면적(㎡)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("plat_area", { required: true })}
              type="text"
              value={buildingInfo?.plat_area}
            />
          </FormControl>
          <FormControl>
            <FormLabel>건폐율(%)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("coverage_ratio", { required: true })}
              type="number"
              value={buildingInfo?.coverage_ratio}
            />
          </FormControl>
          <FormControl>
            <FormLabel>연면적(㎡)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("total_area", { required: true })}
              type="number"
              value={buildingInfo?.total_area}
            />
          </FormControl>
          <FormControl>
            <FormLabel>용적률(%)</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("volume_coverage_ratio", {
              //   required: true,
              // })}
              type="number"
              value={buildingInfo?.volume_coverage_ratio}
            />
          </FormControl>
          <FormControl>
            <FormLabel>내진능력</FormLabel>
            <Input
              focusBorderColor="gray.200"
              readOnly
              // {...register("earthquake_resist_ability")}
              type="text"
              value={
                buildingInfo?.earthquake_resist_ability
                  ? buildingInfo?.earthquake_resist_ability
                  : ""
              }
            />
          </FormControl>
          {/* </Grid> */}
        </VStack>

        <HStack mt={4} mb={4}>
          {/* <Button onClick={onClose} colorScheme={"red"} w="100%">
              취소
            </Button> */}
          <Button
            //   isLoading={mutation.isLoading}
            type="submit"
            colorScheme={"green"}
            w="100%"
          >
            수정
          </Button>
        </HStack>
      </Box>
      {/* {!isLoading ? (
          <BuildingElevatorCheckAlertDialog
            isOpen={isAlertDialogOpen}
            onClose={onAlertDialogClose}
            setChangeData={setChangeData}
            elevatorData={elevatorData}
          />
        ) : null} */}
    </>
  );
}
