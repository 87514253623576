import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import AccessPage from "../components/AccessPage";

export default function Root() {
  return (
    <AccessPage>
      <Box h={"100vh"} w={"100%"} overflowY={"hidden"} overflowX={"hidden"}>
        <Header />
        <Outlet />
      </Box>
    </AccessPage>
  );
}
