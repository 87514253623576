import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IBuildingFacility, IBuildingFacilityCategory } from "../../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { createBuildingFacility } from "../../api";
import { useNavigate } from "react-router-dom";

export interface IFacilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
  categoryData: IBuildingFacilityCategory[] | undefined;
  refetch: () => void;
}

export default function AddBuildingFacilityModal({
  isOpen,
  onClose,
  buildingId,
  categoryData,
  refetch,
}: IFacilityModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IBuildingFacility>();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createBuildingFacility,
    onSuccess: () => {
      toast({
        title:
          watch("type") === "fire_insurance"
            ? "보험이 추가되었습니다!"
            : watch("type") === "fire_assignee"
            ? "안전관리자가 추가되었습니다!"
            : "시설물이 추가되었습니다!",
        status: "success",
      });
      onClose();
      refetch();
      reset();
    },
    onError: (error: any) => {
      console.log(error);
      if (error.response.data.non_field_errors) {
        toast({
          title:
            watch("type") === "fire_insurance"
              ? "보험 추가 실패!"
              : watch("type") === "fire_assignee"
              ? "안전관리자 추가 실패!"
              : "시설물 추가 실패!",
          status: "error",
          description: error.response.data.non_field_errors[0],
        });
      } else {
        toast({
          title: "시설물 추가 실패!",
          status: "error",
          description: "서버 에러",
        });
      }
    },
  });
  const onSubmit = (data: IBuildingFacility) => {
    mutation.mutate({ ...data, building: buildingId });
  };
  const onCloseReset = () => {
    reset();
    onClose();
  };
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>시설 관련 정보 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <Select
              {...register("type", {
                required: "required",
              })}
              defaultValue={"fire_facility"}
            >
              {categoryData?.map((category) => (
                <option key={category.id} value={category.type}>
                  {category.name}
                </option>
              ))}
            </Select>
            <Input
              isInvalid={Boolean(errors.name?.message)}
              {...register("name", {
                required: "required",
              })}
              type="text"
              variant={"filled"}
              placeholder={
                watch("type") === "fire_insurance"
                  ? "보험 이름"
                  : watch("type") === "fire_assignee"
                  ? "안전관리자 이름"
                  : "시설물 명"
              }
            />
          </VStack>
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              등록
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
