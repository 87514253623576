import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  Mark,
  Text,
  VStack,
  useBoolean,
  useDisclosure,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import RadioCard from "../lib/useRadio";
import { editBuilding, getBuilding, getBuildingFacility } from "../api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { IBuildingFacility, IBuildingInfo } from "../types";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import FireSafetyDocument from "../lib/fireSafetyDocument";
import AddFireSafetyInsurancePopover from "../components/facility/AddFireSafetyInsurancePopover";
import AddFireSafetyAssigneeInfoPopover from "../components/facility/AddFireSafetyAssigneeInfoPopover";
import EditFireSafetyAssigneeInfoPopover from "../components/facility/EditFireSafetyAssigneeInfoPopover";
import FireSafetyAlert from "../components/alertDialog/FireSafetyAlert";

export default function FireSafety() {
  const { buildingId } = useParams();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isPending,
    isError,
    data: buildingInfo,
    refetch: refetchBuilding,
  } = useQuery<IBuildingInfo>({
    queryKey: ["building", buildingId],
    queryFn: getBuilding,
    enabled: false,
  });
  const [rate, setRate] = useState(buildingInfo?.fire_safety_rate);
  // const [fireAssignee, setFireAssignee] = useState<IBuildingFacility[]>();
  // const [fireInsurance, setFireInsurance] = useState<IBuildingFacility>();
  const [flag, setFlag] = useBoolean();
  const options = ["특급", "1급", "2급", "3급"];

  const toast = useToast();
  const {
    isPending: isfireAssigneeLoading,
    data: fireAssignee,
    refetch: fireAssigneeRefetch,
  } = useQuery<IBuildingFacility[]>({
    queryKey: ["fire_assignee", buildingId, "fire_assignee"],
    queryFn: getBuildingFacility,
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const {
    isPending: isFireInsuranceLoading,
    data: fireInsurance,
    refetch: fireInsuranceRefetch,
  } = useQuery<IBuildingFacility[]>({
    queryKey: ["fire_insurance", buildingId, "fire_insurance"],
    queryFn: getBuildingFacility,
    refetchOnWindowFocus: false,
    // enabled: false,
  });

  const mutation = useMutation({
    mutationFn: editBuilding,
    onSuccess: (data) => {
      console.log(data);
      toast({
        title: "빌딩 정보 업데이트 완료!",
        status: "success",
      });
    },
    onSettled: () => {
      queryClient.refetchQueries({ queryKey: ["building", buildingId] });
      setFlag.off();
    },
    onError: (e: any) => {
      console.log(e);
      console.log("소방등급 수정 실패!");
      toast({
        status: "error",
        // title: e.response.data.name[0],
        title: "소방정보 수정이 실패하였습니다.",
      });
    },
  });

  const handleChange = (value: string) => {
    if (value === "특급") {
      setRate(0);
    } else if (value === "1급") {
      setRate(1);
    } else if (value === "2급") {
      setRate(2);
    } else if (value === "3급") {
      setRate(3);
    } else {
      setRate(999); // 변경 시 default = 2급
      // setRate(2);
    }
  };

  const { getRootProps, getRadioProps, value, setValue } = useRadioGroup({
    // name: "framework",
    defaultValue:
      buildingInfo?.fire_safety_rate === 0
        ? "특급"
        : buildingInfo?.fire_safety_rate === 1
        ? "1급"
        : "2급",
    // onChange: console.log,
    onChange: handleChange,
  });

  const onClickDatabase = (type: string) => {
    let url = "";
    if (type === "fire") {
      url =
        "https://www.law.go.kr/lsBylInfoPLinkR.do?bylSeq=15158321&lsiSeq=247153";
    }
    if (type === "facility") {
      url =
        "https://www.law.go.kr/LSW/lsBylInfoPLinkR.do?lsiSeq=248669&lsNm=%EC%86%8C%EB%B0%A9%EC%8B%9C%EC%84%A4+%EC%84%A4%EC%B9%98+%EB%B0%8F+%EA%B4%80%EB%A6%AC%EC%97%90+%EA%B4%80%ED%95%9C+%EB%B2%95%EB%A5%A0+%EC%8B%9C%ED%96%89%EB%A0%B9&bylNo=0004&bylBrNo=00&bylCls=BE&bylEfYd=20230307&bylEfYdYn=Y";
    }
    window.open(url, "_blank");
  };

  const onSubmit = () => {
    if (buildingInfo) {
      mutation.mutate({
        fire_safety_rate: rate,
        buildingId: Number(buildingId),
      });
    }
  };
  useEffect(() => {
    if (buildingInfo?.fire_safety_rate === 999) {
      onOpen();
      setRate(2); // 기본설정
    }
  }, [buildingInfo]);
  useEffect(() => {
    if (buildingId) {
      refetchBuilding();
      fireAssigneeRefetch();
      fireInsuranceRefetch();
    }
  }, [buildingId]);
  // console.log(rate);
  return (
    <Box
      py={5}
      px={{ base: 5, md: 10 }}
      // h={"100%"}
      height={"90vh"}
      w={"100%"}
      overflowY={{ base: "auto", md: "hidden" }}
      overflowX={{ base: "hidden", md: "hidden" }}
    >
      {/* {buildingInfo?.fire_safety_rate === 999 ? (
        <Heading size={"sm"} mb={3}>
          소방정보가 등록되지 않았습니다. 건물 내 설치 항목에 따라 아래에
          해당하는 정보를 선택해 주세요.
        </Heading>
      ) : null} */}
      <VStack alignItems={"left"}>
        <HStack mb={2}>
          <Heading fontSize={{ base: "sm", md: "md" }}>소방등급 :</Heading>
          <Heading fontSize={{ base: "sm", md: "md" }}>
            {buildingInfo?.fire_safety_rate === 0 ? (
              <Mark
                bg="orange.200"
                color="black"
                fontFamily="NewYork"
                px="2"
                py="1"
                rounded="full"
                ml="1"
              >
                특급
              </Mark>
            ) : buildingInfo?.fire_safety_rate === 1 ? (
              " 1급"
            ) : buildingInfo?.fire_safety_rate === 2 ? (
              " 2급"
            ) : buildingInfo?.fire_safety_rate === 3 ? (
              " 3급"
            ) : (
              "미확인"
            )}
          </Heading>
          <Button
            size={"sm"}
            colorScheme="red"
            variant={flag ? "solid" : "outline"}
            onClick={setFlag.toggle}
          >
            수정
          </Button>
        </HStack>
        <HStack mb={2}>
          <Heading fontSize={{ base: "sm", md: "md" }}>
            소방안전관리자 :
          </Heading>

          {fireAssignee
            ? fireAssignee.map((assignee, idx) => (
                <Box key={idx} display={"flex"}>
                  <Heading
                    as="span"
                    fontSize={{ base: "sm", md: "md" }}
                    mt={1}
                    mr={1}
                  >
                    {idx > 0 ? ", " : null}
                  </Heading>
                  <Heading fontSize={{ base: "sm", md: "md" }} mt={1} mr={1}>
                    {assignee.name}
                  </Heading>
                  <EditFireSafetyAssigneeInfoPopover data={assignee} />
                </Box>
              ))
            : null}
          {fireAssignee?.length === 3 ? null : (
            <AddFireSafetyAssigneeInfoPopover
              buildingId={Number(buildingId)}
              data={fireAssignee}
            />
          )}
        </HStack>
        <HStack mb={3}>
          <Heading fontSize={{ base: "sm", md: "md" }}>화재보험 :</Heading>
          {fireInsurance?.map((insurance, idx) =>
            insurance.name ? (
              <Heading key={idx} fontSize={{ base: "sm", md: "md" }}>
                {insurance.name}
              </Heading>
            ) : (
              <Heading key={idx} fontSize={{ base: "sm", md: "md" }}>
                -
              </Heading>
            )
          )}
          {/* <Heading fontSize={"md"}>
            {fireInsurance !== undefined ? fireInsurance[0].name : "-"}
          </Heading> */}
          <AddFireSafetyInsurancePopover
            buildingId={Number(buildingId)}
            data={fireInsurance}
          />
        </HStack>
      </VStack>
      <Box>
        <HStack>
          <Text as="b" size={"md"} color="orange.500">
            소방안전관리대상물의 범위와 소방안전관리자의 선임 대상별 자격 및
            인원기준
          </Text>
          <IconButton
            size={"xs"}
            variant="outline"
            colorScheme="teal"
            aria-label="Search database"
            icon={<ExternalLinkIcon />}
            onClick={() => onClickDatabase("fire")}
          />
        </HStack>
        <HStack mt={2}>
          <Text as="b" size={"md"} color="orange.500">
            특정소방대상물의 관계인이 특정소방대상물에 설치,관리 해야 하는 소방
            시설의 종류
          </Text>
          <IconButton
            size={"xs"}
            variant="outline"
            colorScheme="teal"
            aria-label="Search database"
            icon={<ExternalLinkIcon />}
            onClick={() => onClickDatabase("facility")}
          />
        </HStack>
        <HStack
          h={"7vh"}
          mt={2}
          hidden={!flag}
          w={"100%"}
          justifyContent={"space-between"}
        >
          <HStack>
            {options.map((value) => {
              const radio = getRadioProps({ value });
              return (
                <RadioCard isDisabled={!flag} key={value} {...radio}>
                  {value}
                </RadioCard>
              );
            })}
          </HStack>
          <Button
            ml={{ base: 0, md: 10 }}
            isLoading={mutation.isPending}
            type="submit"
            colorScheme={"green"}
            onClick={onSubmit}
            size={{ base: "sm", md: "md" }}
          >
            확인
          </Button>
        </HStack>
        <FireSafetyDocument
          value={value}
          buildingId={Number(buildingId)}
          flag={!flag}
        />
      </Box>
      <FireSafetyAlert isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}
