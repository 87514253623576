import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  FocusLock,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { IBuildingEnergyInfo, IEditBuildingUtilityInfo } from "../../types";
import {
  IEditBuildingUtilityVariable,
  IEditBuildingUtilityVariableList,
  deleteBuildingUtility,
  editBuildingUtility,
  getBuildingUtilityExcel,
  getBuildingUtilityInfoDetail,
} from "../../api";
import { EditIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";

interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
  chartRefetch: () => void;
}
export default function BuildingUtilityDataModal({
  isOpen,
  onClose,
  buildingId,
  chartRefetch,
}: IModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<IEditBuildingUtilityVariableList>();
  const {
    isOpen: isAddOpen,
    onClose: onAddClose,
    onOpen: onAddOpen,
  } = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, "years").format("YYYY-MM")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM"));
  const [volume, setVolume] = useState<number>(0);
  const [cost, setCost] = useState<number>(0);
  const [tableData, setTableData] = useState<IBuildingEnergyInfo[]>([]);
  const [itemId, setItemId] = useState<number | null>(null);
  const [searchDate, setSearchDate] = useState<string>("");
  const onChangeStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };
  const onChangeEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };
  const {
    isPending: isUtilityInfoPending,
    data: utilityInfo,
    refetch: refetchUtilityInfo,
  } = useQuery<IBuildingEnergyInfo[]>({
    queryKey: [
      "building_utility_info_table",
      buildingId,
      startDate,
      endDate,
      "month",
    ],
    queryFn: getBuildingUtilityInfoDetail,
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const {
    isPending: isExcelLoading,
    data: excelData,
    refetch: excelRefetch,
  } = useQuery<any>({
    queryKey: ["building_utility_info_excel", buildingId, startDate, endDate],
    queryFn: getBuildingUtilityExcel,
    enabled: false,
    refetchOnWindowFocus: false,
  });
  const onCancel = () => {
    setTableData([]);
    setItemId(null);
    onClose();
  };
  const onClick = () => {
    refetchUtilityInfo();
  };
  const mutation = useMutation({
    mutationKey: ["editBuildingUtilityDashBoard"],
    mutationFn: editBuildingUtility,
    onSuccess: () => {
      console.log("유틸리티 정보 추가 완료");
      reset();
      refetchUtilityInfo();
      chartRefetch();
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("유틸리티 정보 저장 실패!");
    },
  });
  const deleteMutation = useMutation({
    mutationFn: deleteBuildingUtility,
    onSuccess: () => {
      console.log("유틸리티 정보 삭제 완료");
      toast({
        title: "삭제되었습니다!",
        status: "success",
        description: "데이터 삭제 완료!",
      });
      refetchUtilityInfo();
    },
    onError: (e: any) => {
      console.log(e);
      console.log("유틸리티 정보 삭제 실패!");
      toast({
        title: "데이터 삭제 실패!",
        status: "error",
      });
    },
  });
  const onClickEdit = (id: number, date: string) => {
    setItemId(id);
    setSearchDate(date);
  };
  const onClickDelete = (utilityId: number) => {
    console.log(utilityId);
    deleteMutation.mutate({
      utility_id: utilityId,
      building_id: buildingId,
    });
  };
  const onClickExcel = () => {
    console.log("EXCEL 파일 생성!");
    excelRefetch();
  };
  const onSubmit = () => {
    if (itemId === 0) {
      console.log("첫번째");
      console.log(watch().data[itemId]); // idx 0 인식이 안되는 오류 ?
      if (buildingId) {
        mutation.mutate({
          data: { ...watch().data[itemId], search_date: searchDate },
          building_id: buildingId,
        });
      }
    }
    if (itemId) {
      console.log("SUBMIT");
      //   console.log(watch().data);
      console.log({ ...watch().data[itemId], search_date: searchDate });
      if (buildingId) {
        mutation.mutate({
          data: { ...watch().data[itemId], search_date: searchDate },
          building_id: buildingId,
        });
      }
    }
    setItemId(null);
  };
  useEffect(() => {
    console.log(utilityInfo);
    if (utilityInfo) {
      setTableData(utilityInfo);
    }
  }, [onClick]);
  useEffect(() => {
    console.log(excelData);
    if (excelData) {
      if (excelData.result === "success") {
        toast({
          title: "엑셀 파일 생성 완료!",
          status: "success",
          description: "다운로드 폴더를 확인해주세요.",
        });
      } else {
        toast({
          title: "엑셀 파일 생성 실패!",
          status: "error",
          description: "다시 시도해주세요.",
        });
      }
    }
  }, [excelData]);
  return (
    <Modal size={"6xl"} onClose={onCancel} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>유틸리티 데이터</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box minH={"50vh"}>
            <HStack>
              <Input
                type="month"
                value={startDate}
                min={moment(endDate).subtract(1, "years").format("YYYY-MM")}
                max={endDate}
                onChange={onChangeStartDate}
              />
              <Input
                type="month"
                // defaultValue={endDate}
                value={endDate}
                // max={moment().subtract(3, "months").format("YYYY-MM")}
                max={moment().format("YYYY-MM")}
                onChange={onChangeEndDate}
              />
              <Button
                w={"20%"}
                variant={"outline"}
                colorScheme="green"
                onClick={onClick}
              >
                검색
              </Button>
              <Button
                w={"20%"}
                variant={"outline"}
                colorScheme="green"
                onClick={onAddOpen}
              >
                데이터 추가
              </Button>
              {/* <Tooltip label="PDF 다운로드">
                <Button
                  w={"10%"}
                  variant={"outline"}
                  colorScheme="green"
                  onClick={onClick}
                >
                  <FaFilePdf />
                </Button>
              </Tooltip> */}
              <Tooltip label="Excel 다운로드" bg="gray.200" color="black">
                <Button
                  w={"10%"}
                  variant={"outline"}
                  colorScheme="green"
                  onClick={onClickExcel}
                >
                  <FaFileExcel />
                </Button>
              </Tooltip>
            </HStack>
            <Box ml={3} mt={2}>
              <Text fontSize={"xs"} color={"red"}>
                검색 최대 기간은 1년 입니다.
              </Text>
            </Box>
            <Box>
              <TableContainer mt={1} w={"100%"}>
                <Table>
                  <Thead>
                    <Tr>
                      <Th hidden isNumeric>
                        아이디
                      </Th>
                      <Th>일자</Th>
                      <Th isNumeric>전기 사용량 (㎡)</Th>
                      <Th isNumeric>전기 요금 (원)</Th>
                      <Th isNumeric>가스 사용량 (㎡)</Th>
                      <Th isNumeric>가스 요금 (원)</Th>
                      <Th isNumeric>수도 사용량 (㎡)</Th>
                      <Th isNumeric>수도 요금 (원)</Th>
                      <Th>-</Th>
                      <Th>-</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tableData
                      ? tableData.map((d, idx) => (
                          <Tr key={d.id}>
                            <Td hidden isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.id`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.id}
                                />
                              ) : (
                                <Text>{d.id ? d.id : null}</Text>
                              )}
                            </Td>
                            <Td>{d.search_date}</Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(
                                    `data.${idx}.electricity_usage`,
                                    {
                                      valueAsNumber: true,
                                    }
                                  )}
                                  type="number"
                                  defaultValue={d.electricity_usage}
                                />
                              ) : (
                                <Text>
                                  {d.electricity_usage
                                    ? d.electricity_usage
                                    : null}
                                </Text>
                              )}
                            </Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.electricity_cost`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.electricity_cost}
                                />
                              ) : (
                                <Text>
                                  {d.electricity_cost
                                    ? d.electricity_cost.toLocaleString()
                                    : null}
                                </Text>
                              )}
                            </Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.gas_usage`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.gas_usage}
                                />
                              ) : (
                                <Text>{d.gas_usage ? d.gas_usage : null}</Text>
                              )}
                            </Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.gas_cost`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.gas_cost}
                                />
                              ) : (
                                <Text>
                                  {d.gas_cost
                                    ? d.gas_cost.toLocaleString()
                                    : null}
                                </Text>
                              )}
                            </Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.water_usage`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.water_usage}
                                />
                              ) : (
                                <Text>
                                  {d.water_usage ? d.water_usage : null}
                                </Text>
                              )}
                            </Td>
                            <Td isNumeric>
                              {itemId === idx ? (
                                <Input
                                  {...register(`data.${idx}.water_cost`, {
                                    valueAsNumber: true,
                                  })}
                                  type="number"
                                  defaultValue={d.water_cost}
                                />
                              ) : (
                                <Text>
                                  {d.water_cost
                                    ? d.water_cost.toLocaleString()
                                    : null}
                                </Text>
                              )}
                            </Td>
                            <Td>
                              {itemId === idx ? (
                                <Button
                                  type="submit"
                                  size={"sm"}
                                  // variant={"outline"}
                                  // colorScheme="whiteAlpha"
                                  colorScheme="red"
                                  // color={"red"}
                                  // onClick={() => onEdit(d.id)}
                                  onClick={onSubmit}
                                >
                                  수정
                                </Button>
                              ) : (
                                <Button
                                  variant={"outline"}
                                  size={"xs"}
                                  colorScheme={"teal"}
                                  onClick={() =>
                                    onClickEdit(idx, d.search_date)
                                  }
                                >
                                  <EditIcon />
                                </Button>
                              )}
                            </Td>
                            <Td>
                              <Button
                                size={"xs"}
                                colorScheme={"red"}
                                onClick={() => onClickDelete(d.id)}
                              >
                                삭제
                              </Button>
                            </Td>
                          </Tr>
                        ))
                      : null}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
