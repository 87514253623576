import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IModalProps } from "../../types";

export default function SignUpAlertDialog({ isOpen, onClose }: IModalProps) {
  const cancelRef = useRef(null);
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            알림
          </AlertDialogHeader>
          <AlertDialogBody>
            회원 등록 완료 후 서비스 이용을 하시려면 문의주세요.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button colorScheme="red" ml={3} ref={cancelRef} onClick={onClose}>
              확인
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
