import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Grid,
  HStack,
  Heading,
  Input,
  List,
  ListItem,
  Select,
  Spacer,
  Spinner,
  Tag,
  TagLabel,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaFileDownload, FaSearch, FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import CreateReportModal from "../components/modal/CreateReportModal";
import { useIsMutating, useQuery } from "@tanstack/react-query";
import { IReport } from "../types";
import { getReport, getReportById } from "../api";
import ReportDeleteAlertDialog from "../components/alertDialog/ReportDeleteAlertDialog";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
// `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Report() {
  const { buildingId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isReportDeleteAlertDialogOpen,
    onOpen: onReportDeleteAlertDialogOpen,
    onClose: onReportDeleteAlertDialogClose,
  } = useDisclosure();
  const [date, setDate] = useState<string>(
    new Date().toISOString().substring(0, 10)
  );
  const [reportType, setReportType] = useState<string>("");
  const [reportId, setReportId] = useState<number | null>(null);
  const [reportDeleteId, setReportDeleteId] = useState<number>();

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const isPDFMutating = useIsMutating({
    mutationKey: ["report_pdf"],
  });

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const onDocumentLoadError = (error: any) => {
    console.log(error);
    console.error();
  };

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };
  const onChangeReportType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setReportType(e.target.value);
  };
  const onClickSearch = () => {
    refetchReport();
  };
  const {
    isLoading,
    data: report,
    refetch: refetchReport,
  } = useQuery<IReport[]>({
    queryKey: [`reports`, date, date, reportType, buildingId],
    queryFn: getReport,
    // refetchOnMount: false,
    // refetchOnWindowFocus: false,
    // enabled: false,
  });
  const {
    isPending: isReportItemLoading,
    data: reportItem,
    refetch: refetchReportItem,
  } = useQuery<IReport>({
    queryKey: [`report`, reportId],
    queryFn: getReportById,
    enabled: false,
  });
  const onDeleteReport = (id: number) => {
    setReportDeleteId(id);
    if (reportDeleteId) {
      onReportDeleteAlertDialogOpen();
    }
  };
  const onClickItem = (id: number) => {
    setReportId(id);
  };

  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: "md",
    }
  );
  useEffect(() => {
    if (reportId && reportId !== undefined) {
      // setNumPages(0);
      setPageNumber(1);
      refetchReportItem();
    }
  }, [reportId]);
  // console.log(reportId);
  // console.log(reportItem);
  return (
    <Box
      py={5}
      px={{ base: 5, md: 10 }}
      h={isBreakPoint ? "82vh" : "90vh"}
      overflowY={"scroll"}
    >
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
          //   "2xl": "repeat(5, 1fr)",
        }}
        gap={7}
        h={"82vh"}
        // w={"100vw"}
      >
        <Box
          px={2}
          py={2}
          borderWidth={2}
          borderRadius={"lg"}
          // w={isBreakPoint ? "80vw" : "100%"}
          w={isBreakPoint ? "90vw" : "100%"}
        >
          <Flex alignItems={"center"}>
            <Input
              placeholder="날짜를 선택해주세요"
              size="md"
              w={"35%"}
              mr={3}
              //   type="datetime-local"
              type="date"
              //   defaultValue={new Date().toISOString().substring(0, 10)}
              value={date}
              max={new Date().toISOString().substring(0, 10)}
              onChange={onChangeDate}
            />
            <Select
              w={"35%"}
              mr={3}
              placeholder="내용을 선택해주세요"
              value={reportType}
              onChange={onChangeReportType}
            >
              <option value="fix">수리</option>
              <option value="work">업무기록</option>
              <option value="other">기타</option>
            </Select>

            <Button
              //   isLoading={isFetching === 1 ? true : false}
              type="submit"
              variant={"outline"}
              color={"orange.400"}
              borderWidth={2}
              borderColor={"orange.300"}
              // colorScheme="orange"
              onClick={onClickSearch}
              mr={3}
              size={{ base: "sm", md: "md" }}
            >
              <FaSearch />
            </Button>
            <Spacer />
            <Button onClick={onOpen} size={{ base: "sm", md: "md" }}>
              추가
            </Button>
          </Flex>
          <VStack w={"100%"} h={"72vh"} overflowY={"scroll"}>
            <List spacing={2} mt={3} w={"100%"}>
              {report
                ? report.map((item, idx) => (
                    <ListItem key={item.id}>
                      <Card
                        as="button"
                        width={"100%"}
                        _hover={{
                          bg: "orange.100",
                        }}
                        _focus={{
                          bg: "orange.100",
                        }}
                        onClick={() => onClickItem(item.id)}
                      >
                        <CardBody w={"100%"}>
                          <HStack
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <HStack spacing={4}>
                              <Tag
                                size={"sm"}
                                variant={"solid"}
                                colorScheme="teal"
                              >
                                <TagLabel>
                                  {item.type === "work"
                                    ? "업무"
                                    : item.type === "fix"
                                    ? "수리"
                                    : item.type === "fix_report"
                                    ? "견적서"
                                    : "기타"}
                                </TagLabel>
                              </Tag>
                              <Text>{item.name}</Text>
                            </HStack>
                            <Box
                              // colorScheme="red"
                              // h="1.75rem"
                              // size="md"
                              // variant={"ghost"}
                              _hover={{
                                // bg: "red.100",
                                color: "red",
                              }}
                              color={"gray.500"}
                              // _focus={{
                              //   bg: "orange.100",
                              // }}

                              onClick={() => onDeleteReport(item.id)}
                            >
                              <FaTimes size="18px" />
                            </Box>
                          </HStack>
                        </CardBody>
                      </Card>
                    </ListItem>
                  ))
                : null}
            </List>
          </VStack>
          <CreateReportModal
            isOpen={isOpen}
            onClose={onClose}
            buildingId={Number(buildingId)}
            date={date}
            parentReportId={reportId}
            setParentReportId={setReportId}
            refetchReportItem={refetchReportItem}
          />
          <ReportDeleteAlertDialog
            isOpen={isReportDeleteAlertDialogOpen}
            onClose={onReportDeleteAlertDialogClose}
            reportId={Number(reportId)}
            setReportId={setReportId}
          />
        </Box>
        <Box
          px={1}
          py={2}
          borderWidth={2}
          borderRadius={"lg"}
          w={isBreakPoint ? "90vw" : "100%"}
        >
          {report && report.length > 0 && reportItem && reportItem.file_url ? (
            <HStack w={"100%"} alignItems={"center"} justifyContent={"end"}>
              {numPages && numPages > 1 ? (
                <>
                  <Button
                    size={"sm"}
                    onClick={() =>
                      setPageNumber((prev) => (prev === 1 ? prev : prev - 1))
                    }
                    isDisabled={pageNumber === 1 ? true : false}
                  >
                    &lt;
                  </Button>
                  <Button
                    size={"sm"}
                    onClick={() =>
                      setPageNumber((prev) =>
                        prev === numPages ? prev : prev + 1
                      )
                    }
                    isDisabled={pageNumber === numPages ? true : false}
                  >
                    &gt;
                  </Button>
                </>
              ) : null}
              <Link
                to={
                  process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                  reportItem.file_url
                }
                target="_self"
              >
                <Button size={"sm"} variant={"outline"}>
                  <FaFileDownload />
                </Button>
              </Link>
            </HStack>
          ) : null}

          {report && report.length > 0 && reportItem && reportItem.file_url ? (
            <VStack>
              {/* S3 cors 정책 확인 */}
              <Box
                h={"80vh"}
                w={"90%"}
                overflowY={"scroll"}
                overflowX={"scroll"}
              >
                {/* <Box display={"flex"} justifyContent={"end"}> */}

                <Document
                  file={
                    process.env.REACT_APP_AWS_S3_RESOURCE_URL +
                    reportItem.file_url
                  }
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onDocumentLoadError}
                  loading="PDF 파일을 불러오는 중입니다."
                >
                  {isBreakPoint ? (
                    <Page
                      height={400}
                      pageNumber={pageNumber}
                      loading="페이지 로딩 중..."
                    />
                  ) : (
                    <Page pageNumber={pageNumber} loading="페이지 로딩 중..." />
                  )}
                </Document>
              </Box>
            </VStack>
          ) : isPDFMutating ? (
            <VStack
              display={"flex"}
              w={"100%"}
              h={"80vh"}
              bgColor={"gray.100"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="4px"
                speed="1s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
              <Heading size={"md"} color={"gray.500"}>
                PDF 생성 중 입니다.
              </Heading>
            </VStack>
          ) : (
            <VStack>
              <Box
                display={"flex"}
                w={"100%"}
                h={"80vh"}
                bgColor={"gray.100"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Heading size={"md"} color={"gray.500"}>
                  선택 된 보고서가 없습니다
                </Heading>
              </Box>
            </VStack>
          )}

          {/* <Image
            src={
              "https://s3.ap-northeast-2.amazonaws.com/beta-resource.buildingmaestro.com/report/115/report_3d812a9b-6b7f-4825-b068-6eedf5e22edd.pdf"
            }
          ></Image> */}
        </Box>
      </Grid>
    </Box>
  );
}
