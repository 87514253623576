import {
  Box,
  Divider,
  IconButton,
  Mark,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import fireSafetyFacilityInfo from "../lib/fireSafetyFacilityInfo.json";
import { AddIcon, ExternalLinkIcon } from "@chakra-ui/icons";
// import AddFaciltyAlertDialog from "../components/AddFacilityAlertDialog";
import { useState } from "react";
import FireSafetyPopover from "../components/facility/FireSafetyPopover";
import AddFaciltyAlertDialog from "../components/alertDialog/AddFacilityAlertDialog";

export interface IValueProps {
  value: string | number;
  buildingId: number | undefined;
  flag: boolean;
}
export default function FireSafetyDocument({
  value,
  buildingId,
  flag,
}: IValueProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {fire_extinguisure} = fireSafetyFacilityInfo;
  const [name, setName] = useState("");
  const onClickAdd = (facilityName: string) => {
    setName(facilityName);
    onOpen();
  };
  console.log(value);
  // console.log(fireSafetyFacilityInfo);
  return (
    <>
      <Box
        mt={3}
        border={"1px"}
        borderRadius={"md"}
        h={
          flag
            ? { base: "fit-content", md: "60vh" }
            : { base: "fit-content", md: "52vh" }
        }
        // h={"fit-content"}
        p={2}
        //   overflow={"auto"}
        //   overflowWrap={"inherit"}
        overflowY={"scroll"}
      >
        {value === "특급" ? (
          <Box>
            <Text as="b">특급 소방안전관리대상물</Text>
            <br />
            <br />
            <Text>
              「소방시설 설치 및 관리에 관한 법률 시행령」 별표 2의
              특정소방대상물 중 다음의 어느 하나에 해당하는 것
            </Text>
            <br />
            <Text>
              1) 50층 이상(지하층은 제외한다)이거나 지상으로부터 높이가 200미터
              이상인 아파트
            </Text>
            <br />
            <Text>
              2) 30층 이상(지하층을 포함한다)이거나 지상으로부터 높이가 120미터
              이상인 특정소방대상물(아파트는 제외한다)
            </Text>
            <br />
            <Text>
              3) 2)에 해당하지 않는 특정소방대상물로서 연면적이 10만제곱미터
              이상인 특정 소방대상물(아파트는 제외한다)
            </Text>
            <Divider mt={3} mb={3} />
            <Text as="b">
              특급 소방안전관리대상물에 선임해야 하는 소방안전관리자의 자격
            </Text>
            <br />
            <br />
            <Text>
              다음의 어느 하나에 해당하는 사람으로서 특급 소방안전관리자
              자격증을 발급 받은 사람
            </Text>
            <br />
            <Text>1) 소방기술사 또는 소방시설관리사의 자격이 있는 사람</Text>
            <br />
            <Text>
              2) 소방설비기사의 자격을 취득한 후 5년 이상 1급
              소방안전관리대상물의 소방안전관리자로 근무한 실무경력(법
              제24조제3항에 따라 소방안전관리자로 선임 되어 근무한 경력은
              제외한다. 이하 이 표에서 같다)이 있는 사람
            </Text>
            <br />
            <Text>
              3) 소방설비산업기사의 자격을 취득한 후 7년 이상 1급
              소방안전관리대상물의 소방안전관리자로 근무한 실무경력이 있는 사람
            </Text>
            <br />
            <Text>4) 소방공무원으로 20년 이상 근무한 경력이 있는 사람</Text>
            <br />
            <Text>
              5) 소방청장이 실시하는 특급 소방안전관리대상물의 소방안전관리에
              관한 시험에 합격한 사람
            </Text>
            <br />
            <Text as="mark">선임인원: 1명 이상</Text>
          </Box>
        ) : value === "1급" ? (
          <Box>
            <Text as="b">1급 소방안전관리대상물</Text>
            <br />
            <br />
            <Text>
              「소방시설 설치 및 관리에 관한 법률 시행령」 별표 2의
              특정소방대상물 중 다음의 어느 하나에 해당하는 것(제1호에 따른 특급
              소방안전관리대상물은 제외한다)
            </Text>
            <br />
            <Text>
              1) 30층 이상(지하층은 제외한다)이거나 지상으로부터 높이가 120미터
              이상인 아파트
            </Text>
            <br />
            <Text>
              2) 연면적 1만5천제곱미터 이상인 특정소방대상물(아파트 및
              연립주택은 제외한다)
            </Text>
            <br />
            <Text>
              3) 2)에 해당하지 않는 특정소방대상물로서 지상층의 층수가 11층
              이상인 특정소방대상물(아파트는 제외한다)
            </Text>
            <br />
            <Text>4) 가연성 가스를 1천톤 이상 저장ᆞ취급하는 시설</Text>
            <Divider mt={3} mb={3} />
            <Text as="b">
              1급 소방안전관리대상물에 선임해야 하는 소방안전관리자의 자격
            </Text>
            <br />
            <br />
            <Text>
              다음의 어느 하나에 해당하는 사람으로서 1급 소방안전관리자 자격증을
              발급받은 사람 또는 제1호에 따른 특급 소방안전관리대상물의
              소방안전관리자 자격증을 발급받은 사람
            </Text>
            <br />
            <Text>
              1) 소방설비기사 또는 소방설비산업기사의 자격이 있는 사람
            </Text>
            <br />
            <Text>2) 소방공무원으로 7년 이상 근무한 경력이 있는 사람</Text>
            <br />
            <Text>
              3) 소방청장이 실시하는 1급 소방안전관리대상물의 소방안전관리에
              관한 시험에 합격한 사람
            </Text>
            <br />
            <Text as="mark">선임인원: 1명 이상</Text>
          </Box>
        ) : value === "2급" ? (
          <Box>
            <Text as="b">2급 소방안전관리대상물</Text>
            <br />
            <br />
            <Text>
              2급 소방안전관리대상물의 범위 「소방시설 설치 및 관리에 관한 법률
              시행령」 별표 2의 특정소방대상물 중 다음의 어느 하나에 해당하는
              것(제1호에 따른 특급 소방안전관리대상물 및 제 2호에 따른 1급
              소방안전관리대상물은 제외한다)
            </Text>
            <br />
            <Box>
              1) 「소방시설 설치 및 관리에 관한 법률 시행령」 별표 4 제1호다목에
              따라{" "}
              <Mark
                bg="yellow.300"
                color="black"
                // fontFamily="NewYork"
                px="1"
                py="1"
                rounded="full"
              >
                옥내소화전설비
              </Mark>
              <Tooltip label="설비 추가" fontSize={"md"}>
                <IconButton
                  size={"xs"}
                  variant="outline"
                  colorScheme="green"
                  borderWidth={2}
                  aria-label="Add facility"
                  icon={<AddIcon />}
                  mb={1}
                  mr={1}
                  onClick={() => onClickAdd("옥내소화전")}
                />
              </Tooltip>
              <FireSafetyPopover
                info={fireSafetyFacilityInfo.fire_extinguisher}
              />
              를 설치해야 하는 특정소방대상물, 같은 호 라목에 따라{" "}
              <Mark
                bg="yellow.300"
                color="black"
                // fontFamily="NewYork"
                px="1"
                py="1"
                rounded="full"
              >
                스프링클러설비
              </Mark>
              <Tooltip label="설비 추가" fontSize={"md"}>
                <IconButton
                  size={"xs"}
                  variant="outline"
                  colorScheme="green"
                  borderWidth={2}
                  aria-label="Add facility"
                  icon={<AddIcon />}
                  mb={1}
                  mr={1}
                  onClick={() => onClickAdd("스프링클러")}
                  //   onClick={() => onClickDatabase("facility")}
                />
              </Tooltip>
              <FireSafetyPopover info={fireSafetyFacilityInfo.fire_sprinkler} />
              를 설치해야 하는 특정소방대상물 또는 같은 호 바목에 따라{" "}
              <Mark
                bg="yellow.300"
                color="black"
                // fontFamily="NewYork"
                px="1"
                py="1"
                rounded="full"
              >
                물분무등소화설비
              </Mark>
              <Tooltip label="설비 추가" fontSize={"md"}>
                <IconButton
                  size={"xs"}
                  variant="outline"
                  colorScheme="green"
                  borderWidth={2}
                  aria-label="Add facility"
                  icon={<AddIcon />}
                  mb={1}
                  mr={1}
                  onClick={() => onClickAdd("물분무등")}
                  //   onClick={() => onClickDatabase("facility")}
                />
              </Tooltip>
              <FireSafetyPopover info={fireSafetyFacilityInfo.etc_sprinkler} />
              [화재안전기준에 따라 호스릴(hose reel) 방식의 물분무등소화설비만을
              설치할 수 있는 특정소방대상물은 제외한다]를 설치해야 하는
              특정소방대상물
            </Box>
            <br />
            <Text>
              2) 가스 제조설비를 갖추고 도시가스사업의 허가를 받아야 하는 시설
              또는 가연 성 가스를 100톤 이상 1천톤 미만 저장ᆞ취급하는 시설
            </Text>
            <br />
            <Text>3) 지하구</Text>
            <br />
            <Text>
              4) 「공동주택관리법」 제2조제1항제2호의 어느 하나에 해당하는
              공동주택(「소방시설 설치 및 관리에 관한 법률 시행령」 별표 4
              제1호다목 또는 라목에 따른 옥내소화전설비 또는 스프링클러설비가
              설치된 공동주택으로 한정한다)
            </Text>
            <br />
            <Text>
              5) 「문화재보호법」 제23조에 따라 보물 또는 국보로 지정된
              목조건축물
            </Text>
            <Divider mt={3} mb={3} />
            <Text as="b">
              2급 소방안전관리대상물에 선임해야 하는 소방안전관리자의 자격
            </Text>
            <br />
            <br />
            <Text>
              다음의 어느 하나에 해당하는 사람으로서 2급 소방안전관리자 자격증을
              발급받은 사람, 제1호에 따른 특급 소방안전관리대상물 또는 제2호에
              따른 1급 소방안 전관리대상물의 소방안전관리자 자격증을 발급받은
              사람
            </Text>
            <br />
            <Text>
              1) 위험물기능장ᆞ위험물산업기사 또는 위험물기능사 자격이 있는 사람
            </Text>
            <br />
            <Text>2) 소방공무원으로 3년 이상 근무한 경력이 있는 사람</Text>
            <br />
            <Text>
              3) 소방청장이 실시하는 2급 소방안전관리대상물의 소방안전관리에
              관한 시험에 합격한 사람
            </Text>
            <br />
            <Text>
              4) 「기업활동 규제완화에 관한 특별조치법」 제29조, 제30조 및
              제32조에 따라 소방안전관리자로 선임된 사람(소방안전관리자로 선임된
              기간으로 한정한다)
            </Text>
            <br />
            <Text as="mark">선임인원: 1명 이상</Text>
          </Box>
        ) : (
          <Box>
            <Text as="b">3급 소방안전관리대상물</Text>
            <br />
            <br />
            <Text>
              3급 소방안전관리대상물의 범위 「소방시설 설치 및 관리에 관한 법률
              시행령」 별표 2의 특정소방대상물 중 다음의 어느 하나에 해당하는
              것(제1호에 따른 특급 소방안전관리대상물, 제2호 에 따른 1급
              소방안전관리대상물 및 제3호에 따른 2급 소방안전관리대상물은
              제외한다)
            </Text>
            <br />
            <Text>
              1) 「소방시설 설치 및 관리에 관한 법률 시행령」 별표 4 제1호마목에
              따라{" "}
              <Mark
                bg="yellow.300"
                color="black"
                // fontFamily="NewYork"
                px="2"
                py="2"
                rounded="full"
              >
                간이스프링클러설비
              </Mark>
              <Tooltip label="설비 추가" fontSize={"md"}>
                <IconButton
                  size={"xs"}
                  variant="outline"
                  colorScheme="green"
                  borderWidth={2}
                  aria-label="Add facility"
                  icon={<AddIcon />}
                  mb={1}
                  mr={1}
                  onClick={() => onClickAdd("간이스프링클러")}
                  //   onClick={() => onClickDatabase("facility")}
                />
              </Tooltip>
              <FireSafetyPopover
                info={fireSafetyFacilityInfo.fire_sprinkler_sub}
              />
              (주택전용 간이스프링클러설비는 제외한다)를 설치해야 하는
              특정소방대상물
            </Text>
            <br />
            <Text>
              2) 「소방시설 설치 및 관리에 관한 법률 시행령」 별표 4 제2호다목에
              따른{" "}
              <Mark
                bg="yellow.300"
                color="black"
                // fontFamily="NewYork"
                px="2"
                py="2"
                rounded="full"
              >
                자동화재탐지설비
              </Mark>
              <Tooltip label="설비 추가" fontSize={"md"}>
                <IconButton
                  size={"xs"}
                  variant="outline"
                  colorScheme="green"
                  borderWidth={2}
                  aria-label="Add facility"
                  icon={<AddIcon />}
                  mb={1}
                  mr={1}
                  onClick={() => onClickAdd("자동화재탐지")}
                  //   onClick={() => onClickDatabase("facility")}
                />
              </Tooltip>
              <FireSafetyPopover info={fireSafetyFacilityInfo.fire_detector} />
              를 설치해야 하는 특정소방대상물
            </Text>
            <Divider mt={3} mb={3} />
            <Text as="b">
              3급 소방안전관리대상물에 선임해야 하는 소방안전관리자의 자격
            </Text>
            <br />
            <br />
            <Text>
              다음의 어느 하나에 해당하는 사람으로서 3급 소방안전관리자 자격증을
              발급받은 사람 또는 제1호부터 제3호까지의 규정에 따라 특급
              소방안전관리대상물, 1급 소방안전관리대상물 또는 2급
              소방안전관리대상물의 소방안전관리자 자격증을 발급받은 사람
            </Text>
            <br />
            <Text>1) 소방공무원으로 1년 이상 근무한 경력이 있는 사람</Text>
            <br />
            <Text>
              2) 소방청장이 실시하는 3급 소방안전관리대상물의 소방안전관리에
              관한 시험에 합격한 사람
            </Text>
            <br />
            <Text>
              3) 「기업활동 규제완화에 관한 특별조치법」 제29조, 제30조 및
              제32조에 따라 소방안전관리자로 선임된 사람(소방안전관리자로 선임된
              기간으로 한정한다)
            </Text>
            <br />
            <Text as="mark">선임인원: 1명 이상</Text>
          </Box>
        )}
      </Box>
      {buildingId ? (
        <AddFaciltyAlertDialog
          isOpen={isOpen}
          onClose={onClose}
          name={name}
          buildingId={buildingId}
        />
      ) : null}
    </>
  );
}
