import {
  Box,
  Button,
  FormControl,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IBuildingFacility } from "../../types";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { FaUpload } from "react-icons/fa";
import { AddIcon } from "@chakra-ui/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import {
  createReport,
  createReportPDF,
  createReportPhoto,
  getBuildingFacility,
  uploadImage,
} from "../../api";
import moment from "moment";
import AddFacilityModal from "./AddFacilityModal";
import ImageLimitAlertDialog from "../alertDialog/ImageLimitAlertDialog";
import PdfViewerModal from "./PdfViewerModal";

export interface IReportModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
  date: string;
  parentReportId: number | null;
  setParentReportId: (arg: number | null) => void;
  refetchReportItem: () => void;
}
export default function CreateReportModal({
  isOpen,
  onClose,
  buildingId,
  date,
  parentReportId,
  setParentReportId,
  refetchReportItem,
}: IReportModalProps) {
  const {
    isOpen: isAddFaclityOpen,
    onOpen: onAddFaclityOpen,
    onClose: onAddFaclityClose,
  } = useDisclosure();
  const {
    isOpen: isImageAlertOpen,
    onOpen: onImageAlertOpen,
    onClose: onImageAlertClose,
  } = useDisclosure();
  const {
    isOpen: isPDFViewerOpen,
    onClose: onPDFViewerClose,
    onOpen: onPDFViewerOpen,
  } = useDisclosure();
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [pdfPreview, setPdfPreview] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { ref, ...rest } = register("files");
  const images = watch("files");
  const [reportId, setReportId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    isLoading: isFacilityLoading,
    data: facilityData,
    refetch: facilityRefetch,
  } = useQuery<IBuildingFacility[]>({
    queryKey: ["facility_report", buildingId, "facility"],
    queryFn: getBuildingFacility,
    enabled: false,
  });
  const mutation = useMutation({
    mutationFn: createReport,
    onSuccess: (data) => {
      // console.log(data);
      setReportId(data.id);
      // if (data.type !== "fix_report") {
      if (images && images.length > 0 && images.length < 5) {
        uploadImageMutation.mutate({
          files: images,
          type: "report",
          id: data.id.toString(),
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["reports"] });
        onReset();

        // PDF 생성
        if (data.id) {
          createReportPDFMutation.mutate({ report_id: Number(data.id) });
        }
      }
      // } else {
      //   queryClient.invalidateQueries({ queryKey: ["reports"] });
      //   onReset();
      // }

      toast({
        title: "리포트가 추가되었습니다!",
        status: "success",
      });

      // onClose();
      // reset();
    },
    onError: (error) => {
      console.log(error);
      Sentry.captureException(error);
    },
  });
  const createPhotoMutation = useMutation({
    mutationFn: createReportPhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reports"] });
      toast({
        status: "success",
        title: "리포트 이미지 업로드 완료!",
        isClosable: true,
      });
      onReset();

      if (reportId) {
        createReportPDFMutation.mutate({ report_id: Number(reportId) });
      }
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn: uploadImage,
    onSuccess: (result: any) => {
      if (reportId) {
        createPhotoMutation.mutate({ data: result, report_id: reportId });
      }
    },
  });
  const createReportPDFMutation = useMutation({
    mutationKey: ["report_pdf"],
    mutationFn: createReportPDF,
    onSuccess: () => {
      console.log("PDF 생성 완료");
      toast({
        title: "보고서 PDF 생성 완료되었습니다!",
        status: "success",
      });
      Sentry.captureMessage("리포트 생성 완료 메세지", "log");
      // refetchReportItem();
    },
    onSettled: () => {
      if (parentReportId) {
        console.log("있고");
        refetchReportItem();
      } else {
        console.log("없고");
        setParentReportId(reportId);
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
    },
  });
  const onReset = () => {
    // setWorkType([]);
    setImagePreview([]);
    setPdfPreview("");
    reset();
    onClose();
  };
  const onSubmit = (data: any) => {
    if (data.cost === "") {
      data.cost = 0;
    }
    console.log(data);
    // if (data.type === "fix_report") {
    //   console.log("여기");
    //   mutation.mutate({ ...data, files: images[0], building_id: buildingId });
    // } else {
    //   mutation.mutate({ ...data, building_id: buildingId });
    // }
    mutation.mutate({ ...data, building_id: buildingId });
  };
  useEffect(() => {
    if (buildingId) {
      facilityRefetch();
    }
  }, [buildingId]);
  useEffect(() => {
    if (images && images.length > 4) {
      // alert("이미지 개수는 최대 4개 입니다.");
      onImageAlertOpen();
    }
    if (images && images.length > 0 && images.length < 5) {
      if (watch("type") === "fix_report") {
        console.log(images[0]);
        const pdfPreview = URL.createObjectURL(images[0]);
        setPdfPreview(pdfPreview);
      } else {
        setImagePreview([]); // clear preview
        const imageArray = Array.from(images).map((file: any) =>
          URL.createObjectURL(file)
        );
        setImagePreview((prevImages) => prevImages.concat(imageArray));
      }
    }
  }, [images]);
  // console.log(imagePreview);
  // console.log(watch());
  // console.log(images);
  return (
    <Modal size={"xl"} onClose={onReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>보고서 작성</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"left"}>
            <Input
              {...register("date", {
                required: "required",
              })}
              placeholder="날짜를 선택해주세요"
              size="lg"
              //   type="datetime-local"
              type="date"
              defaultValue={date}
              max={new Date().toISOString().substring(0, 10)}
            />
            <Select
              {...register("type", {
                required: "required",
              })}
              // disabled={watch("building_id") ? false : true}
              w="80%"
              variant="filled"
              placeholder="내용을 선택해주세요"
              // defaultValue={reportType}
            >
              <option value="fix">수리</option>
              <option value="fix_report">견적서</option>
              <option value="work">업무기록</option>
              <option value="other">기타</option>
            </Select>
            <HStack hidden={watch("type") === "fix" ? false : true}>
              <Select
                {...register("facility_id")}
                w="80%"
                variant="filled"
                placeholder="시설을 선택해주세요"
                //   value={workType}
                // onChange={onChange}
              >
                {facilityData?.map((facility) => (
                  <option key={facility.id} value={facility.id}>
                    {facility.name}
                  </option>
                ))}
              </Select>
              <IconButton
                variant="outline"
                colorScheme="teal"
                aria-label="add facility"
                icon={<AddIcon />}
                onClick={onAddFaclityOpen}
              />
            </HStack>
            {watch("type") === "fix" ? (
              <Box>
                <InputGroup
                  w={"30%"}
                  // {...register("file")}
                  onClick={() => inputRef.current?.click()}
                >
                  <Input
                    type="file"
                    accept="image/*"
                    multiple={true}
                    // style={{ display: "none" }}
                    ref={(e) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    {...rest}
                    // onChange={handleChange}
                    hidden
                  />
                  <Button
                    w={"full"}
                    isLoading={false}
                    rightIcon={<FaUpload />}
                    // variant={"secondary"}
                    colorScheme="teal"
                  >
                    이미지 파일
                  </Button>
                </InputGroup>
                <SimpleGrid
                  mt={5}
                  spacing={4}
                  templateColumns="repeat(auto-fill, minmax(100px, 1fr))"
                >
                  {imagePreview.length > 0 && imagePreview.length < 5
                    ? imagePreview.map((image, idx) => (
                        <Image key={idx} src={image} />
                      ))
                    : null}
                </SimpleGrid>
              </Box>
            ) : watch("type") === "fix_report" ? (
              <Box>
                <InputGroup
                  w={"30%"}
                  // {...register("file")}
                  onClick={() => inputRef.current?.click()}
                >
                  <Input
                    type="file"
                    accept=".pdf"
                    multiple={false}
                    ref={(e) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    {...rest}
                    hidden
                  />
                  <Button
                    w={"full"}
                    isLoading={false}
                    rightIcon={<FaUpload />}
                    // variant={"secondary"}
                    colorScheme="teal"
                  >
                    PDF 파일
                  </Button>
                </InputGroup>
                {images && images.length > 0 ? (
                  <HStack alignItems={"center"} w={"100%"} mt={3} spacing={3}>
                    {pdfPreview && (
                      <>
                        <Button onClick={onPDFViewerOpen}>파일 보기</Button>
                        <PdfViewerModal
                          isOpen={isPDFViewerOpen}
                          onClose={onPDFViewerClose}
                          data={pdfPreview}
                        />
                      </>
                    )}
                    <Text>
                      {images[0].name.length > 40
                        ? images[0].name.slice(0, 40) + "...pdf"
                        : images[0].name}
                    </Text>
                  </HStack>
                ) : (
                  <Text>업로드 된 파일 없음</Text>
                )}
              </Box>
            ) : null}
            <InputGroup w={"80%"}>
              <Input {...register("cost")} type="number" placeholder="비용" />
              <InputRightAddon children="원" />
            </InputGroup>
            <Input
              {...register("name", {
                required: "required",
              })}
              type="text"
              placeholder="한줄 업무 내용을 작성해 주세요."
            />
            <Textarea
              {...register("description")}
              h={"30vh"}
              placeholder="추가 내용을 작성해 주세요."
            />
          </VStack>
          <HStack mt={4} mb={4}>
            <Button colorScheme={"red"} w="100%" onClick={onReset}>
              취소
            </Button>
            <Button
              //   isLoading={mutation.isLoading}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              등록
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
      <AddFacilityModal
        isOpen={isAddFaclityOpen}
        onClose={onAddFaclityClose}
        buildingId={buildingId}
        refetch={facilityRefetch}
      />
      <ImageLimitAlertDialog
        isOpen={isImageAlertOpen}
        onClose={onImageAlertClose}
      />
    </Modal>
  );
}
