import Cookie from "js-cookie";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "axios";
import {
  IBuildingFacility,
  IBuildingInfo,
  IParticipant,
  ISignUp,
} from "./types";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1"
      : // ? "http://192.168.0.75:8000/api/v1"
        process.env.REACT_APP_SERVER_API,
  withCredentials: true,
});

const WEATHER_API_URL = "https://api.openweathermap.org/data/2.5";
export interface IFindAddress {
  curPage: number;
  address: string;
}

export const getAddress = ({ queryKey }: QueryFunctionContext) => {
  const [_, address, curPage] = queryKey;
  const countPerPage = 100;
  // console.log(process.env.REACT_APP_ADDRESS_KEY);
  return axios
    .get(
      `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${process.env.REACT_APP_ADDRESS_KEY}&currentPage=${curPage}&countPerPage=${countPerPage}&keyword=${address}&resultType=json`
    )
    .then((response) => response.data);
};

export const getWeather = ({ queryKey }: QueryFunctionContext) => {
  const [_, lat, lon] = queryKey;
  return axios
    .get(
      `${WEATHER_API_URL}/weather?lat=${lat}&lon=${lon}&units=metric&appid=${process.env.REACT_APP_WEATHER_APP_ID}`
    )
    .then((response) => response.data);
};

export interface IEditMeVariables {
  name: string;
  email: string;
  phone_number: string;
}
export const editMe = (variables: IEditMeVariables) =>
  instance
    .put(`users/me`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IChangePasswordVariables {
  old_password: string;
  new_password: string;
}
export const changePassword = ({
  old_password,
  new_password,
}: IChangePasswordVariables) =>
  instance.put(
    `users/change-password`,
    { old_password, new_password },
    {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    }
  );

export const createMasterUser = (variables: ISignUp) =>
  instance
    .post(`users/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getUser = () =>
  instance.get(`users/user-management`).then((response) => response.data);

export interface ICreateUserVariables {
  username: string;
  password: string;
  role: string;
  status: string;
}

export const createUser = (variables: ICreateUserVariables) =>
  instance
    .post(`users/user-management`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IDeleteUser {
  id: number;
}
export const deleteUser = (id: IDeleteUser) =>
  instance
    .delete(`users/user-management/${id}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IBuildingInfoVariables {
  sigunguCd: string;
  bjdongCd: string;
  bun: string;
  ji: string;
}
export const getBuildingInfo = (variables: IBuildingInfoVariables) =>
  instance
    .post(`buildings/info`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const addBuilding = (variables: IBuildingInfo) =>
  instance
    .post(`buildings/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IDeleteBuilding {
  buildingId: number;
}

export const deleteBuilding = (buildingId: number) =>
  instance
    .delete(`buildings/${buildingId}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IUploadImageVariables {
  files: FileList;
  type: string;
  id: string;
  // uploadURL: string;
}
export const uploadImage = ({ files, type, id }: IUploadImageVariables) => {
  // console.log(files);
  const form = new FormData();
  Array.from(files).forEach((file) => {
    form.append("files", file);
  });
  form.append("type", type);
  form.append("id", id);
  // form.append("file", file);
  // console.log(uploadURL);
  // return axios
  return instance
    .post("medias/photos/upload-photo", form, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
};

export interface IFileURL {
  file_url: string;
}

export interface ICreateBuildingPhotoVariables {
  data: IFileURL[];
  building_id: number;
}

export const createBuildingPhoto = (variables: ICreateBuildingPhotoVariables) =>
  instance
    .post(`buildings/${variables.building_id}/photos`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IBuildingElevatorGeneralInfoVariable {
  address: string;
  building_id: number;
  type?: string;
}

export const addBuildingElevatorGeneralInfo = (
  variables: IBuildingElevatorGeneralInfoVariable
) =>
  instance
    .post(
      `buildings/${variables.building_id}/elevator-general-info`,
      variables,
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);
export interface IAddBuildingUtility {
  building_id: number;
  sigungu_code: string;
  bjdong_code: string;
  bun: string;
  ji: string;
}
export const addBuildingUtilityInfo = (variables: IAddBuildingUtility) =>
  instance
    .post(`buildings/${variables.building_id}/utility`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getMe = () =>
  instance.get(`users/me`).then((response) => response.data);

export interface IUsernameLoginVariables {
  username: string;
  password: string;
}
export const logIn = ({ username, password }: IUsernameLoginVariables) =>
  instance
    .post(
      `users/log-in`,
      { username, password },
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
          // "X-CSRFToken": getCSRFToken() || "",
        },
      }
    )
    .then((response) => response.data);

export const logOut = () =>
  instance
    .post(`users/log-out`, null, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
        // "X-CSRFToken": getCSRFToken() || "",
      },
    })
    .then((response) => response.data);

export const getBuildingList = () => {
  return instance.get(`buildings/`).then((response) => response.data);
};

export const getBuilding = ({ queryKey }: QueryFunctionContext) => {
  const [_, buildingId] = queryKey;
  return instance
    .get(`buildings/${buildingId}`, { timeout: 2000 })
    .then((response) => response.data);
};

export const getBuildingName = ({ queryKey }: QueryFunctionContext) => {
  const [_, buildingId] = queryKey;
  return instance
    .get(`buildings/${buildingId}/name`)
    .then((response) => response.data);
};

export interface IRefreshBuildingInfo {
  building_id: number;
  sigunguCd: string;
  bjdongCd: string;
  bun: string;
  ji: string;
}
export const RefreshBuildingInfo = (variables: IRefreshBuildingInfo) =>
  instance
    .put(`buildings/`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getBuildingFloorDetail = async ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, buildingId] = queryKey;
  return await instance
    .get(`buildings/${buildingId}/floor-info`)
    .then((response) => response.data);
};

export interface IEditBuildingInfoVariable {
  name?: string;
  elevator_cnt?: number;
  emergency_elevator_cnt?: number;
  fire_safety_rate?: number;
  buildingId: number;
}
export const editBuilding = (variables: IEditBuildingInfoVariable) =>
  instance
    .put(`buildings/${variables.buildingId}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IEditFloorName {
  id: number;
  name: string;
}
export interface IEditBuildingFloorVariable {
  data: IEditFloorName[];
  buildingId: number;
}
export const editBuildingFloor = (variables: IEditBuildingFloorVariable) =>
  instance
    .put(`buildings/${variables.buildingId}/floor-info`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getBuildingElevator = ({ queryKey }: QueryFunctionContext) => {
  const [_, id] = queryKey;
  return instance
    .get(`buildings/${id}/elevator-info`)
    .then((response) => response.data);
};

export const createBuildingFacility = (variables: IBuildingFacility) =>
  instance
    .post(`buildings/${variables.building}/facilities`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
export const editBuildingFacility = (variables: IBuildingFacility) =>
  instance
    .put(
      `buildings/${variables.building}/facilities/${variables.id}`,
      variables,
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface IDeleteFacility {
  facilityId: number;
  buildingId: number;
}
export const deleteFacility = (variables: IDeleteFacility) =>
  instance
    .delete(
      `buildings/${variables.buildingId}/facilities/${variables.facilityId}`,
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getBuildingUtilityInfoDetail = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, building_id, start_date, end_date, search_type] = queryKey;
  return instance
    .get(
      `buildings/${building_id}/utility-detail?start_date=${start_date}&end_date=${end_date}&search_type=${search_type}`
    )
    .then((response) => response.data);
};
export interface IRefetchBuildingEnergy {
  building_id: string;
  start_date: string;
  end_date: string;
}
export const refetchBuildingUtilityInfo = (variables: IRefetchBuildingEnergy) =>
  instance
    .post(`buildings/${variables.building_id}/utility-detail`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getBuildingFacilityCategory = () => {
  return instance
    .get(`buildings/facility-categories`)
    .then((response) => response.data);
};

export interface IEditBuildingUtility {
  id?: number;
  search_date?: string;
  water_usage?: number;
  water_cost?: number;
  electricity_usage?: number;
  electricity_cost?: number;
  gas_usage?: number;
  gas_cost?: number;
}
export interface IEditBuildingUtilityVariable {
  data: IEditBuildingUtility;
  building_id: number;
}
// 유틸리티 데이터 모달 창 테이블 내 개별 데이터 수정
export interface IEditBuildingUtilityVariableList {
  data: IEditBuildingUtility[];
  building_id: number;
}

export const editBuildingUtility = (variables: IEditBuildingUtilityVariable) =>
  instance
    .post(`buildings/${variables.building_id}/utility-custom`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IDeleteUtility {
  utility_id: number;
  building_id: number;
}
export const deleteBuildingUtility = (variables: IDeleteUtility) =>
  instance
    .delete(
      `buildings/${variables.building_id}/utility-custom/${variables.utility_id}`,
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export const getBuildingUtilityExcel = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id, start_date, end_date] = queryKey;
  return instance
    .get(
      `buildings/${building_id}/utility-excel?start_date=${start_date}&end_date=${end_date}`
    )
    .then((response) => response.data);
};

export const getBuildingFacility = ({ queryKey }: QueryFunctionContext) => {
  const [_, id, type, searchName] = queryKey;
  // console.log(searchName);
  // if (searchName === "" || searchName === undefined) {
  if (!searchName) {
    return instance
      .get(`buildings/${id}/facilities?type=${type}`)
      .then((response) => response.data);
  } else {
    return instance
      .get(`buildings/${id}/facilities?type=${type}&search_name=${searchName}`)
      .then((response) => response.data);
  }
};

export const getBuildingFacilityById = ({ queryKey }: QueryFunctionContext) => {
  const [_, id, facility_id] = queryKey;
  return instance
    .get(`buildings/${id}/facilities/${facility_id}`)
    .then((response) => response.data);
};

export const getBuildingFacilityReportCost = ({
  queryKey,
}: QueryFunctionContext) => {
  const [_, building_id, start_date, end_date, search_type] = queryKey;
  return instance
    .get(
      `buildings/${building_id}/facilities/cost?start_date=${start_date}&end_date=${end_date}&search_type=${search_type}`
    )
    .then((response) => response.data);
};

export interface ICreateFacilityPhotoVariables {
  data: IFileURL[];
  building_id: number;
  facility_id: number;
}

export const createFacilityPhoto = (variables: ICreateFacilityPhotoVariables) =>
  instance
    .post(
      `buildings/${variables.building_id}/facilities/${variables.facility_id}/photo`,
      variables,
      {
        headers: {
          "X-CSRFToken": Cookie.get("csrftoken") || "",
        },
      }
    )
    .then((response) => response.data);

export interface ICreateReport {
  name?: string;
  files: File;
  file?: string;
  type: string;
  date: string;
  cost?: number;
  description?: string;
  building_id: number;
  facility_id?: number;
}
export const createReport = (variables: ICreateReport) => {
  return instance
    .post(`managements/report`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);
  // if (variables.type === "fix_report") {
  //   const form = new FormData();
  //   form.append("name", variables.name ? variables.name : "");
  //   form.append("files", variables.files);
  //   form.append("type", variables.type);
  //   form.append("date", variables.date);
  //   form.append("cost", variables.cost ? variables.cost.toString() : "0");
  //   form.append(
  //     "description",
  //     variables.description ? variables.description : ""
  //   );
  //   form.append("building_id", variables.building_id.toString());
  //   form.append(
  //     "facility_id",
  //     variables.facility_id ? variables.facility_id.toString() : ""
  //   );
  //   // form.append("file", file);
  //   // console.log(uploadURL);
  //   // return axios
  //   return instance
  //     .post("managements/report", form, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //         "X-CSRFToken": Cookie.get("csrftoken") || "",
  //       },
  //     })
  //     .then((response) => response.data);
  // } else {
  //   return instance
  //     .post(`managements/report`, variables, {
  //       headers: {
  //         "X-CSRFToken": Cookie.get("csrftoken") || "",
  //       },
  //     })
  //     .then((response) => response.data);
  // }
};

export interface ICreateReportPhotoVariables {
  // description: string;
  // file_url: FileList[];
  data: IFileURL[];
  report_id: number;
}

export const createReportPhoto = (variables: ICreateReportPhotoVariables) =>
  instance
    .post(`managements/report/${variables.report_id}/photos`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getReportAll = ({ queryKey }: QueryFunctionContext) => {
  const [_, report_type] = queryKey;
  return instance
    .get(`managements/report?report_type=${report_type}`)
    .then((response) => response.data);
};
export const getReport = ({ queryKey }: QueryFunctionContext) => {
  const [_, start_date, end_date, report_type, building_id] = queryKey;
  // console.log(report_type);
  if (report_type) {
    return instance
      .get(
        `managements/report?building_id=${building_id}&start_date=${start_date}&end_date=${end_date}&report_type=${report_type}`
      )
      .then((response) => response.data);
  } else {
    return instance
      .get(
        `managements/report?building_id=${building_id}&start_date=${start_date}&end_date=${end_date}`
      )
      .then((response) => response.data);
  }
};

export const getReportById = ({ queryKey }: QueryFunctionContext) => {
  const [_, report_id] = queryKey;
  return instance
    .get(`managements/report/${report_id}`)
    .then((response) => response.data);
};

export interface editReport {
  report_id: number;
  name: string;
  description: string;
  // files?: IFileURL[];
}

export const editReport = (variables: editReport) =>
  instance
    .put(`managements/report/${variables.report_id}`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IDeletePhoto {
  photoId: number;
}
export const deletePhoto = (photoId: number) =>
  instance
    .delete(`medias/photos/${photoId}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface IDeleteReport {
  reportId: number;
}
export const deleteReport = (reportId: number) =>
  instance
    .delete(`managements/report/${reportId}`, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export interface ICreateReportPDF {
  report_id: number;
}
export const createReportPDF = (variables: ICreateReportPDF) =>
  instance
    .post(`managements/report/${variables.report_id}/pdf`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getDashboardNotice = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id] = queryKey;
  return instance
    .get(`buildings/${building_id}/notice`)
    .then((response) => response.data);
};

export const getDashboardTodos = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id, date] = queryKey;
  return instance
    .get(`managements/todo?building_id=${building_id}&date=${date}`)
    .then((response) => response.data);
};

export const getTodoDates = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id] = queryKey;
  return instance
    .get(`managements/todo-dates?building_id=${building_id}`)
    .then((response) => response.data);
};

export const getDashboardTodosMonth = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id, date] = queryKey;
  return instance
    .get(`managements/todo-month?building_id=${building_id}&date=${date}`)
    .then((response) => response.data);
};

export interface ICreateTodoVariables {
  title: string;
  description: string;
  building_id: number;
  date: string;
  participants?: IParticipant[];
}

export const createTodo = (variables: ICreateTodoVariables) =>
  instance
    .post(`managements/todo`, variables, {
      headers: {
        "X-CSRFToken": Cookie.get("csrftoken") || "",
      },
    })
    .then((response) => response.data);

export const getDashboardStat = ({ queryKey }: QueryFunctionContext) => {
  const [_, building_id, date, search_type] = queryKey;
  return instance
    .get(
      `buildings/${building_id}/stat?date=${date}&search_type=${search_type}`
    )
    .then((response) => response.data);
};

export const getBuildingLastUpdated = ({ queryKey }: QueryFunctionContext) => {
  const [_, buildingId] = queryKey;
  return instance
    .get(`buildings/${buildingId}/last-updated`)
    .then((response) => response.data);
};
