import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { IBuildingInfo, IModalProps, ISearchAddressInfo } from "../../types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  QueryCache,
  QueryClient,
  useIsFetching,
  useIsMutating,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import Paginator from "../../lib/paginator";
import { getAddress, getBuildingInfo } from "../../api";
import AddBuildingInfoModal from "./AddBuildingInfoModal";

export interface IFindAddressProps {
  isOpen: boolean;
  onClose: () => void;
}
export default function FindAddressModal({ isOpen, onClose }: IModalProps) {
  const {
    isOpen: isBuildingInfoOpen,
    onOpen: onBuildingInfoOpen,
    onClose: onBuildingInfoClose,
  } = useDisclosure();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [address, setAddress] = useState("");
  const [searchList, setSearchList] = useState<ISearchAddressInfo[]>([]);
  const [buildingInfo, setBuildingInfo] = useState<IBuildingInfo | null>(null);
  const [roadAddr, setRoadAddr] = useState<string>("");
  const [zipNo, setZipNo] = useState<string>("");
  const [totalNum, setTotalNum] = useState<number>(0);
  const toast = useToast();
  const isFetching = useIsFetching({ queryKey: ["find-address"] });
  const { page, setPage, paginator } = Paginator({
    totalNum: totalNum,
    itemPerPage: 100,
    pageShow: 5,
  });
  const {
    isPending: isAddressPending,
    data: addressData,
    refetch: refetchAddress,
  } = useQuery({
    queryKey: ["find-address", address, page],
    queryFn: getAddress,
    // retry: false,
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
    // staleTime: Infinity,
    meta: {
      successMessage: "주소 찾기 완료!",
      errorMessage: "다시 검색해주세요.",
    },
  });

  // TODO 호출이 길어질 시, 타임아웃 걸어야 할 필요 있을 듯?
  const mutation = useMutation({
    mutationFn: getBuildingInfo,
    onSuccess: (data) => {
      if (Object.keys(data).length > 0) {
        setBuildingInfo({ ...data, road_address: roadAddr, zip_code: zipNo });
        toast({
          status: "success",
          title: "건축물 대장 가져오기 완료!",
          duration: 1500,
        });
      } else {
        setBuildingInfo({ ...data, road_address: roadAddr, zip_code: zipNo });
        toast({
          status: "info",
          title: "빈칸을 채워주세요!",
          duration: 3000,
        });
      }
      onCloseReset();
      onBuildingInfoOpen();
    },
    onError: (e: any) => {
      console.log(e.message);
      toast({
        status: "error",
        title: "다시 시도해주세요.",
        description: "호출 오류.",
      });
      setAddress(""); // 검색 주소 리셋
      setSearchList([]); // 주소 리스트 리셋
      setPage(1); // page num 0 으로 바꾸기
      setTotalNum(0); // page 전체 갯수 0 으로 돌리기
      setBuildingInfo(null); // 데이터 빈 값 세팅
    },
  });

  const onChangeAddress = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAddress(e.target.value);
    },
    []
  );
  const onClickFindAddress = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(address);
    // refetch();
    // console.log(inputRef?.current?.value);
    // if (inputRef?.current?.value) {
    //   // setAddress(inputRef?.current?.value);
    //   // console.log(address);
    //   refetchAddress();
    // }
    refetchAddress();

    // mutation.mutate({ address: address, curPage: page + 1 });
    // 주소 찾기 API 호출
  };
  const onClick = (result: ISearchAddressInfo) => {
    console.log(result);
    // setBuildingInfo(result);
    if (result) {
      const sigunguCd = result.admCd.substring(0, 5);
      const bjdongCd = result.admCd.substring(5);
      const bun = result.lnbrMnnm;
      const ji = result.lnbrSlno;
      // 건축물 대장 정보 가져오기 (내부적으로 층별 정보도 같이 저장)
      mutation.mutate({ sigunguCd, bjdongCd, bun, ji });
      // 주소 검색에서 내려주는 정보 추가
      setRoadAddr(result.roadAddr);
      setZipNo(result.zipNo);
    }
    // onBuildingInfoOpen();
    // onCloseReset();
  };
  const onCloseReset = () => {
    setAddress(""); // 검색 주소 리셋
    setSearchList([]); // 주소 리스트 리셋
    setPage(1); // page num 0 으로 바꾸기
    setTotalNum(0); // page 전체 갯수 0 으로 돌리기
    onClose();
  };
  const onBuildingInfoCloseReset = () => {
    setBuildingInfo(null); // 데이터 빈 값 세팅
    onBuildingInfoClose();
  };
  useEffect(() => {
    if (address !== "" && totalNum !== 0) {
      refetchAddress();
    }
  }, [page]);

  useEffect(() => {
    if (addressData) {
      // TODO 캐시 삭제할지는 선택
      setSearchList([]);
      if (addressData.results.juso === null) {
        toast({
          status: "error",
          title: "다시 검색해주세요.",
          description: addressData.results.common.errorMessage,
        });
      } else {
        setSearchList(addressData.results.juso);
        setTotalNum(Number(addressData.results.common.totalCount)); // 총 데이터 갯수
      }
    }
  }, [addressData]);

  return (
    <>
      <Modal size={"xl"} onClose={onCloseReset} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>주소 검색</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody as="form" onSubmit={onClickFindAddress}> */}
          <ModalBody as="form">
            <VStack>
              <HStack w="100%">
                <InputGroup>
                  <InputLeftElement
                    children={
                      <Box color="gray.500">
                        <FaSearch />
                      </Box>
                    }
                  />
                  <Input
                    // ref={inputRef}
                    variant={"filled"}
                    type="text"
                    value={address}
                    placeholder="address"
                    // onChange={() => {
                    //   setAddress(inputRef?.current?.value);
                    // }}
                    onChange={onChangeAddress}
                  />
                </InputGroup>
                <Button
                  isLoading={isFetching === 1 ? true : false}
                  type="submit"
                  colorScheme="blue"
                  onClick={onClickFindAddress}
                >
                  <FaSearch />
                </Button>
              </HStack>
              <Box w="100%" maxH={"50vh"} overflowY="auto">
                {mutation.isPending ? (
                  <HStack mt={10} justifyContent={"center"}>
                    <Spinner
                      // display={display}
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </HStack>
                ) : (
                  <List fontSize="1.2em" spacing={2}>
                    {searchList?.map((result, idx) => (
                      <React.Fragment key={idx}>
                        <ListItem key={result.bdMgtSn}>
                          <ButtonGroup
                            variant={"unstyled"}
                            w={"100%"}
                            isAttached
                            justifyContent={"space-around"}
                            onClick={() => onClick(result)}
                          >
                            <Button
                              // isLoading={mutation.isLoading}
                              w={"70%"}
                              key={result.roadAddr}
                            >
                              <Box maxW={"100%"}>
                                <Heading noOfLines={1} size="sm">
                                  {result.roadAddr}
                                </Heading>
                              </Box>
                            </Button>
                            <Button key={idx}>{result.zipNo}</Button>
                          </ButtonGroup>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </Box>
              <Box>
                {/* <Paginator
                    totalNum={totalNum}
                    itemPerPage={100}
                    pageShow={5}
                    page={page}
                    setPage={setPage}
                  /> */}
                {paginator}
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      {buildingInfo ? (
        <AddBuildingInfoModal
          isOpen={isBuildingInfoOpen}
          onClose={onBuildingInfoCloseReset}
          buildingInfo={buildingInfo}
        />
      ) : null}
    </>
  );
}
