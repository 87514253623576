import { ExternalLinkIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IFireSafetyJson } from "../../types";

export interface FireSafetyDataProps {
  info: IFireSafetyJson[];
}
export default function FireSafetyPopover({ info }: FireSafetyDataProps) {
  const initialFocusRef = useRef<HTMLButtonElement>(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState(info[page]);
  const totalPage = info.length;
  const onClickNext = () => {
    if (page < totalPage - 1) {
      setPage(page + 1);
    }
  };
  const onClickPrev = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    setData(info[page]);
  }, [page]);
  //   console.log(page);
  //   console.log(info);
  return (
    <Popover arrowSize={10}>
      <PopoverTrigger>
        <IconButton
          aria-label="Search Info"
          variant="outline"
          colorScheme="teal"
          // borderWidth={2}
          size="xs"
          icon={<ExternalLinkIcon />}
          mb={1}
          mr={1}
        />
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800">
        <PopoverHeader pt={7} fontSize={"sm"} fontWeight="semibold" border="0">
          {data.description}
        </PopoverHeader>
        <PopoverArrow bg="blue.800" />
        <PopoverCloseButton />
        <PopoverBody fontSize={"sm"}>
          {data.detail?.map((d, idx) => (
            <Text key={idx}>
              {idx + 1}. {d}
            </Text>
          ))}
        </PopoverBody>
        <PopoverFooter
          border="0"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pb={4}
          fontSize={"sm"}
        >
          <Text>
            Step {page + 1} of {totalPage}
          </Text>
          <ButtonGroup size="sm">
            <Button
              colorScheme="green"
              onClick={onClickPrev}
              isDisabled={page === 0 ? true : false}
            >
              Prev
            </Button>
            <Button
              colorScheme="blue"
              ref={initialFocusRef}
              onClick={onClickNext}
              isDisabled={page === totalPage - 1 ? true : false}
            >
              Next
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}
