import { EditIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  FocusLock,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";
import { IBuildingFacility } from "../../types";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createBuildingFacility } from "../../api";

export interface IFireSafetyAssigneeProps {
  buildingId: number | undefined;
  data: IBuildingFacility[] | undefined;
}
export default function AddFireSafetyAssigneeInfoPopover({
  buildingId,
  data,
}: IFireSafetyAssigneeProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const firstFieldRef = useRef<HTMLButtonElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBuildingFacility>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createBuildingFacility,
    onSuccess: () => {
      console.log("소방안전관리자 저장 완료");
      toast({
        status: "success",
        title: "등록되었습니다!",
        description: "소방안전관리자 등록 완료!",
        duration: 1500,
      });
      onClose();
      queryClient.refetchQueries({ queryKey: ["fire_assignee"] });
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("소방안전관리자 저장 실패!");
      toast({
        status: "error",
        title: "오류!",
        description: "소방안전관리자 등록 실패!",
        duration: 1500,
      });
    },
  });
  const onSubmit = (data: IBuildingFacility) => {
    if (buildingId !== undefined) {
      mutation.mutate({
        ...data,
        type: "fire_assignee",
        building: buildingId,
      });
    }
  };
  return (
    <Popover
      initialFocusRef={firstFieldRef}
      //   returnFocusOnClose={false}
      // placement="auto"
      placement="bottom-end"
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          onClick={onOpen}
          size={"sm"}
          variant={"outline"}
          colorScheme="purple"
        >
          등록
        </Button>
      </PopoverTrigger>
      <PopoverContent p={5}>
        <FocusLock persistentFocus={false}>
          <PopoverArrow />
          <PopoverCloseButton />
          <Stack as="form" onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel>이름</FormLabel>
              <Input
                isInvalid={Boolean(errors.name?.message)}
                {...register("name", {
                  required: true,
                })}
                type="text"
                // defaultValue="-"
              />
              {/* <FormHelperText></FormHelperText> */}
            </FormControl>
            <HStack mt={3} justifyContent="end">
              <Button type="submit" colorScheme="teal">
                등록
              </Button>
            </HStack>
          </Stack>
        </FocusLock>
      </PopoverContent>
    </Popover>
  );
}
