import {
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Select,
  Tag,
  TagLabel,
  Text,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { IBuildingFacilityCategory, IBuildingFacilityRead } from "../types";
import { getBuildingFacility, getBuildingFacilityCategory } from "../api";
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";
import AddBuildingFacilityModal from "../components/modal/AddBuildingFacilityModal";
import FacilityDetail from "../components/facility/FacilityDetail";

export default function Facility() {
  const {
    isOpen: isFacilityAddOpen,
    onOpen: onFacilityAddOpen,
    onClose: onFacilityAddClose,
  } = useDisclosure();
  const { buildingId } = useParams();
  const [category, setCategory] = useState<string>("all");
  const [searchName, setSearchName] = useState<string>("");
  const [facilityItem, setFacilityItem] = useState<
    IBuildingFacilityRead | undefined
  >(undefined);
  const { pathname } = useLocation();
  const {
    isPending: isFacilityPending,
    data: facilityCategoryData,
    refetch: facilityCategoryRefetch,
  } = useQuery<IBuildingFacilityCategory[]>({
    queryKey: ["facility_category"],
    queryFn: getBuildingFacilityCategory,
    // retry: false,
    //   enabled: false,
    refetchOnWindowFocus: false,
  });
  const {
    isPending: isFacilityLoading,
    data: facilityData,
    refetch: facilityRefetch,
  } = useQuery<IBuildingFacilityRead[]>({
    queryKey: ["facility_list", buildingId, category, searchName],
    queryFn: getBuildingFacility,
    enabled: false,
  });
  const onChangeCategory = (event: any) => {
    setSearchName("");
    setCategory(event.target.value);
    // facilityCategoryRefetch();
  };
  const onChangeSearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };
  const onClickItem = (item: IBuildingFacilityRead) => {
    setFacilityItem(item);
  };
  const onClickClear = () => {
    setSearchName("");
  };
  const onClickSearchName = () => {
    // console.log(inputRef?.current?.value);
    facilityRefetch();
  };
  const isBreakPoint = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: "md",
    }
  );
  useEffect(() => {
    if (category) {
      setFacilityItem(undefined);
      facilityRefetch();
    }
  }, [category]);
  useEffect(() => {
    setFacilityItem(undefined);
    facilityRefetch();
  }, [pathname]);

  //   console.log(category);
  console.log(facilityItem);
  return (
    <Box
      py={5}
      px={{ base: 5, md: 10 }}
      h={isBreakPoint ? "80vh" : "90vh"}
      overflowY={"scroll"}
    >
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "1fr 1fr",
          lg: "repeat(2, 1fr)",
          xl: "repeat(2, 1fr)",
          //   "2xl": "repeat(5, 1fr)",
        }}
        gap={7}
        h={"82vh"}
      >
        <Box px={2} py={2} borderWidth={2} borderRadius={"lg"}>
          <HStack w={"100%"} justifyContent={"space-between"}>
            <Box w="80%" display={"flex"}>
              <Select
                w={{ sm: "100%", md: "100%", lg: "70%" }}
                value={category}
                onChange={onChangeCategory}
                mr={2}
              >
                <option value={"all"}>전체</option>
                {facilityCategoryData?.map((category) => (
                  <option key={category.id} value={category.type}>
                    {category.name}
                  </option>
                ))}
              </Select>

              {/* <Button ml={2} onClick={onClickRefresh}>
            <RepeatIcon />
          </Button> */}
              <InputGroup mr={2}>
                <InputLeftElement
                  children={
                    <Box color="gray.500">
                      <FaSearch />
                    </Box>
                  }
                />
                <Input
                  //   ref={inputRef}
                  variant={"filled"}
                  type="text"
                  value={searchName}
                  placeholder="시설물 이름"
                  onChange={onChangeSearchName}
                />
                <InputRightElement>
                  <Button
                    h="1.75rem"
                    size="sm"
                    variant={"ghost"}
                    _hover={{
                      bg: "white",
                    }}
                    onClick={onClickClear}
                  >
                    <FaTimes />
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Button
                //   isLoading={isFetching === 1 ? true : false}
                type="submit"
                variant={"outline"}
                color={"orange.400"}
                borderWidth={2}
                borderColor={"orange.300"}
                // colorScheme="orange"
                onClick={onClickSearchName}
              >
                <FaSearch />
              </Button>
            </Box>
            <Button onClick={onFacilityAddOpen}>추가</Button>
          </HStack>
          <VStack w={"100%"} h={"72vh"} overflowY={"scroll"}>
            <List spacing={2} mt={3} w={"100%"}>
              {facilityData
                ? facilityData.map((item, idx) => (
                    <ListItem key={item.id}>
                      <Card
                        as="button"
                        width={"100%"}
                        _hover={{
                          bg: "orange.100",
                        }}
                        _focus={{
                          bg: "orange.100",
                        }}
                        // _activeLink={{ bg: "orange.300", color: "white" }}
                        onClick={() => onClickItem(item)}
                      >
                        <CardBody>
                          <HStack spacing={4}>
                            <Tag
                              size={"sm"}
                              variant={"solid"}
                              colorScheme="teal"
                            >
                              <TagLabel>{item.category.name}</TagLabel>
                            </Tag>
                            <Text>{item.name}</Text>
                          </HStack>
                        </CardBody>
                      </Card>
                    </ListItem>
                  ))
                : null}
            </List>
          </VStack>
          <AddBuildingFacilityModal
            isOpen={isFacilityAddOpen}
            onClose={onFacilityAddClose}
            buildingId={Number(buildingId)}
            categoryData={facilityCategoryData}
            refetch={facilityRefetch}
          />
        </Box>
        <Box px={2} py={2} borderWidth={2} borderRadius={"lg"}>
          <FacilityDetail
            facilityItem={facilityItem}
            setFacilityItem={setFacilityItem}
            buildingId={Number(buildingId)}
            facilityRefetch={facilityRefetch}
          />
        </Box>
      </Grid>
    </Box>
  );
}
