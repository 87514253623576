import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { IBuildingFacility } from "../../types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { createBuildingFacility } from "../../api";
import { useNavigate } from "react-router-dom";

export interface IFacilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
  refetch: () => void;
}

export default function AddFacilityModal({
  isOpen,
  onClose,
  buildingId,
  refetch,
}: IFacilityModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IBuildingFacility>();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: createBuildingFacility,
    onSuccess: () => {
      toast({
        title: "시설물이 추가되었습니다!",
        status: "success",
      });
      onClose();
      //   queryClient.refetchQueries(["facility_report", buildingId, "facility"]);
      refetch();
      reset();
    },
    onError: (error) => {
      console.log(error);
      console.log("mutation failed");
    },
  });
  const onSubmit = (data: IBuildingFacility) => {
    mutation.mutate({ ...data, building: buildingId });
  };
  const onCloseReset = () => {
    reset();
    onClose();
  };
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>시설물 추가</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <Input
              isInvalid={Boolean(errors.name?.message)}
              {...register("name", {
                required: "required",
              })}
              type="text"
              variant={"filled"}
              placeholder="시설물 명"
            />
            <Select
              {...register("type", {
                required: "required",
              })}
              placeholder="분류를 선택해주세요"
            >
              <option value="fire_facility">소방시설</option>
              <option value="etc_facility">기타시설</option>
            </Select>
          </VStack>
          {/* {mutation.isError ? (
              <Text color="red.500" textAlign={"center"} fontSize="sm">
                비밀번호를 확인해 주세요.
              </Text>
            ) : null} */}
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={mutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              등록
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
