import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { RefreshBuildingInfo, addBuildingElevatorGeneralInfo } from "../../api";
import { IBuildingInfo } from "../../types";

interface IAlertProps {
  isOpen: boolean;
  onClose: () => void;
  buildingInfo: IBuildingInfo | undefined;
  buildingRefetch: () => void;
  buildingFloorRefetch: () => void;
}
export default function RefreshBuildingInfoAlertDialog({
  isOpen,
  onClose,
  buildingInfo,
  buildingRefetch,
  buildingFloorRefetch,
}: IAlertProps) {
  const cancelRef = useRef(null);
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: RefreshBuildingInfo,
    onSuccess: () => {
      // toast({
      //   status: "success",
      //   title: "새로 불러오기 성공!",
      //   description: `업데이트 완료`,
      // });
      if (buildingInfo) {
        elevatorInfoMutation.mutate({
          address: buildingInfo.road_address,
          building_id: buildingInfo.id,
          type: "refresh",
        });
      }
      //   queryClient.invalidateQueries([`building`, buildingInfo?.id]);
      buildingRefetch();
      onClose();
      Sentry.captureMessage("건축물 정보 다시 가져오기 완료", "log");
    },
    onError: (e: any) => {
      console.log(e.response.data.non_field_errors);
      if (e.response.data.non_field_errors) {
        toast({
          status: "error",
          title: "건축물 정보 다시 가져오기 실패",
          description: e.response.data.non_field_errors[0],
        });
        Sentry.captureException(e.response.data.non_field_errors);
      } else {
        toast({
          status: "error",
          title: "건축물 정보 다시 가져오기 실패",
          description: "다시 시도해주세요.",
        });
        Sentry.captureException(e.response.data);
      }
      onClose();
    },
  });
  const elevatorInfoMutation = useMutation({
    mutationFn: addBuildingElevatorGeneralInfo,
    onSuccess: () => {
      console.log("엘레베이터 기본 정보 다시 불러오기 완료");

      //   queryClient.refetchQueries([`building_floor`, buildingInfo?.id]);
      buildingFloorRefetch();
      queryClient.refetchQueries({ queryKey: ["elevators", buildingInfo?.id] });
      toast({
        status: "success",
        title: "새로 불러오기 성공!",
        description: `업데이트 완료`,
      });
      navigate("/building"); // 새로 고침
    },
    onError: (e: any) => {
      console.log(e.response.data);
      console.log("엘레베이터 정보 다시불러오기 실패!");
      // TODO 엘레베이터 수집 오류시 빌딩 정보는 생성 할것인지?
      // queryClient.refetchQueries(["building_info_list"]);
    },
  });
  const onClick = () => {
    if (buildingInfo) {
      mutation.mutate({
        building_id: buildingInfo.id,
        sigunguCd: buildingInfo.sigungu_code.toString(),
        bjdongCd: buildingInfo.bjdong_code.toString(),
        bun: buildingInfo.bun.toString(),
        ji: buildingInfo.ji.toString(),
      });
    }
  };
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            새로고침
          </AlertDialogHeader>
          <AlertDialogBody>
            건축물 정보를 다시 가져오겠습니까? 수정사항이 초기화 됩니다.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button colorScheme="red" ml={3} onClick={onClick}>
              가져오기
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
