import { extendTheme } from "@chakra-ui/react";
// import "@fontsource/nanum-gothic";
// import "@fontsource/noto-sans-kr";
import "@fontsource/ibm-plex-sans-kr";

const theme = extendTheme({
  //   fonts: {
  //     heading: `'Noto Sans Korean', sans-serif`,
  //     body: `'Noto Sans Korean', sans-serif`,
  //   },
  //   fonts: {
  //     // heading: `'Nanum Gothic', sans-serif`,
  //     body: `'IBM Plex Sans KR', sans-serif`,
  //   },
  fonts: {
    heading: `'IBM Plex Sans KR', sans-serif`,
    body: `'IBM Plex Sans KR', sans-serif`,
  },
});

export default theme;
