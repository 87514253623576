import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { createBuildingPhoto, uploadImage } from "../../api";

export interface IPhotoUploadProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
  buildingRefetch: () => void;
}
export default function BuildingPhotoUpload({
  isOpen,
  onClose,
  buildingId,
  buildingRefetch,
}: IPhotoUploadProps) {
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { ref, ...rest } = register("files");
  const images = watch("files");
  const toast = useToast();
  const queryClient = useQueryClient();
  const createPhotoMutation = useMutation({
    mutationFn: createBuildingPhoto,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ["building"] }); // enabled:false 여서 작동 안됨
      buildingRefetch();
      toast({
        status: "success",
        title: "빌딩 사진 변경 완료!",
        isClosable: true,
        // description: "",
      });
      onCloseReset();
    },
  });
  const uploadImageMutation = useMutation({
    mutationFn: uploadImage,
    onSuccess: (result: any) => {
      if (buildingId) {
        createPhotoMutation.mutate({ data: result, building_id: buildingId });
      }
    },
  });
  const onCloseReset = () => {
    reset();
    setImagePreview([]);
    onClose();
  };
  const onSubmit = (data: any) => {
    console.log(data);
    if (buildingId) {
      uploadImageMutation.mutate({
        files: images,
        type: "building",
        id: buildingId.toString(),
      });
    }
  };
  useEffect(() => {
    if (images && images.length > 0) {
      setImagePreview([]); // clear preview
      const imageArray = Array.from(images).map((file: any) =>
        URL.createObjectURL(file)
      );
      setImagePreview((prevImages) => prevImages.concat(imageArray));
    }
  }, [images]);
  //   console.log(images);
  return (
    <Modal onClose={onCloseReset} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>건물 사진 변경</ModalHeader>
        <ModalCloseButton />
        <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems={"start"} h={"60vh"}>
            <Input
              type="file"
              accept="image/*"
              // multiple={true}
              ref={(e) => {
                ref(e);
                inputRef.current = e;
              }}
              {...rest}
              hidden
            />
            <Button h={"10vh"} onClick={() => inputRef.current?.click()}>
              사진 업로드
            </Button>
            <Box minH={"150"}>
              {imagePreview.length > 0
                ? imagePreview.map((image, idx) => (
                    <Image
                      objectFit={"cover"}
                      w="100%"
                      h="100%"
                      key={idx}
                      src={image}
                    />
                  ))
                : null}
            </Box>
          </VStack>
          <HStack mt={4} mb={4}>
            <Button onClick={onCloseReset} colorScheme={"red"} w="100%">
              취소
            </Button>
            <Button
              isLoading={createPhotoMutation.isPending}
              type="submit"
              colorScheme={"green"}
              w="100%"
            >
              등록
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
