import {
  Avatar,
  Badge,
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  ToastId,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import LoginModal from "./modal/LoginModal";
import useUser from "../lib/useUser";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { logOut } from "../api";
import { BellIcon, HamburgerIcon } from "@chakra-ui/icons";
import Sidebar from "./Sidebar";
import FindAddressModal from "./modal/FindAddressModal";
import ProfileModal from "./modal/ProfileModal";
import ChangePasswordModal from "./modal/ChangePasswordModal";
import CreateUserModal from "./modal/CreateUserModal";
import CreateTodoModal from "./modal/CreateTodoModal";
import CountDownTimer from "../lib/countdownTimer";
import moment from "moment";
import AddBuildingInfoModal from "./modal/AddBuildingInfoModal";

export default function Header() {
  const { userLoading, user, isLoggedIn, logInTime } = useUser();
  const [expirationTime, setExpirationTime] = useState<Date>();
  //   const {
  //     isOpen: isDrawerOpen,
  //     onOpen: onDrawerOpen,
  //     onClose: onDrawerClose,
  //   } = useDisclosure();
  //   const drawerRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen: isDrawerOpen, onToggle } = useDisclosure();
  const {
    isOpen: isLoginOpen,
    onClose: onLoginClose,
    onOpen: onLoginOpen,
  } = useDisclosure();
  const {
    isOpen: isFindAddressModalOpen,
    onOpen: onFindAddressModalOpen,
    onClose: onFindAddressModalClose,
  } = useDisclosure();
  const {
    isOpen: isProfileOpen,
    onClose: onProfileClose,
    onOpen: onProfileOpen,
  } = useDisclosure();
  const {
    isOpen: isChangePasswordOpen,
    onClose: onChangePasswordClose,
    onOpen: onChangePasswordOpen,
  } = useDisclosure();
  const {
    isOpen: isCreateUserOpen,
    onClose: onCreateUserClose,
    onOpen: onCreateUserOpen,
  } = useDisclosure();
  const {
    isOpen: isCreateTodoOpen,
    onClose: onCreateTodoClose,
    onOpen: onCreateTodoOpen,
  } = useDisclosure();
  const {
    isOpen: isAddBuildingOpen,
    onClose: onAddBuildingClose,
    onOpen: onAddBuildingOpen,
  } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const toastId = useRef<ToastId>();
  const mutation = useMutation({
    mutationFn: logOut,
    onMutate: () => {
      toastId.current = toast({
        title: "Login out...",
        description: "Sad to see you go...",
        status: "loading",
        position: "bottom-right",
      }); // setTimeout 으로 시간 설정 가능
    },
    onSuccess: () => {
      if (toastId.current) {
        // queryClient.refetchQueries({ queryKey: ["me"] });
        toast.update(toastId.current, {
          status: "success",
          title: "로그아웃 완료!",
          description: "See you later",
        });
        queryClient.removeQueries({ queryKey: ["building"] }); // 로그아웃 시 캐시 삭제
        queryClient.removeQueries({ queryKey: ["me"] }); // 로그아웃 시 캐시 삭제
        navigate("/");
      }
    },
  });
  const onLogOut = async () => {
    mutation.mutate();
    // navigate("/");
  };
  const onClick = () => {
    if (isDrawerOpen) {
      onToggle();
    }
  };
  const onClickUserManagement = () => {
    onCreateUserOpen();
    queryClient.invalidateQueries({ queryKey: ["user_managements"] });
  };
  // console.log(logInTime);
  return (
    <>
      <Stack
        justifyContent={"space-between"}
        alignItems={"center"}
        py={5}
        px={{ base: 5, md: 10 }}
        direction={{
          sm: "column",
          md: "row",
        }}
        spacing={{
          sm: 4,
          md: 0,
        }}
        borderBottomWidth={1}
      >
        <Box display={"flex"}>
          <Button
            // hidden={isBreakPoint || !isLoggedIn || user?.status !== "approved"}
            hidden={!isLoggedIn || user?.status !== "approved"}
            // ref={drawerRef}
            variant={"unstyled"}
            mr={3}
            onClick={onToggle}
          >
            <HamburgerIcon boxSize={6} color={"gray.500"} />
          </Button>

          <Link to="/" onClick={onClick}>
            <Box color="orange.300">
              <FaRegBuilding size={"40"} />
            </Box>
          </Link>
        </Box>
        <HStack spacing={5}>
          {!userLoading ? (
            !isLoggedIn ? (
              <Button onClick={onLoginOpen} colorScheme="green">
                로그인
              </Button>
            ) : (
              <Box display={"flex"} zIndex={10}>
                <CountDownTimer
                  targetDate={
                    new Date(moment().add(1, "h").format("YYYY-MM-DD HH:mm:ss"))
                  }
                />
                {/* <Menu>
                  <MenuButton>
                    <Box display={"flex"} mr={4}>
                      <BellIcon boxSize={"6"} />
                      <Badge
                        variant={"solid"}
                        position={"absolute"}
                        // right={0.4}
                        // top={-0.2}
                        colorScheme="red"
                        rounded={"xl"}
                        fontSize={"0.7em"}
                        ml={4}
                      >
                        7
                      </Badge>
                    </Box>
                  </MenuButton>
                  <MenuList>
                    {user?.role === "master" && user?.status === "approved" ? (
                      <MenuItem onClick={onCreateUserOpen}>
                        사용자 추가dddddddddddddddddddd
                      </MenuItem>
                    ) : null}
                    <MenuItem onClick={onProfileOpen}>내 정보</MenuItem>
                    <MenuItem onClick={onChangePasswordOpen}>
                      비밀번호 변경
                    </MenuItem>
                    <MenuItem onClick={onLogOut}>Log out</MenuItem>
                  </MenuList>
                </Menu> */}
                <Menu>
                  <MenuButton onClick={onClick}>
                    <Avatar name={user?.name} size={"sm"} />
                  </MenuButton>
                  <MenuList>
                    {user?.role === "master" && user?.status === "approved" ? (
                      <>
                        <MenuItem onClick={onClickUserManagement}>
                          사용자 추가
                        </MenuItem>
                        <MenuItem onClick={onFindAddressModalOpen}>
                          빌딩 추가
                        </MenuItem>
                      </>
                    ) : null}
                    {user?.status === "approved" ? (
                      <>
                        <MenuItem onClick={onAddBuildingOpen}>
                          빌딩 추가 (직접)
                        </MenuItem>
                        <MenuItem onClick={onCreateTodoOpen}>
                          일정 추가
                        </MenuItem>
                      </>
                    ) : null}
                    <MenuItem onClick={onProfileOpen}>내 정보</MenuItem>
                    <MenuItem onClick={onChangePasswordOpen}>
                      비밀번호 변경
                    </MenuItem>
                    <MenuItem onClick={onLogOut}>로그아웃</MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            )
          ) : null}
        </HStack>
        <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
        <FindAddressModal
          isOpen={isFindAddressModalOpen}
          onClose={onFindAddressModalClose}
        />
        <ProfileModal
          isOpen={isProfileOpen}
          onClose={onProfileClose}
          user={user}
        />
        <ChangePasswordModal
          isOpen={isChangePasswordOpen}
          onClose={onChangePasswordClose}
        />
        <CreateUserModal
          isOpen={isCreateUserOpen}
          onClose={onCreateUserClose}
        />
        <CreateTodoModal
          isOpen={isCreateTodoOpen}
          onClose={onCreateTodoClose}
        />
        <AddBuildingInfoModal
          isOpen={isAddBuildingOpen}
          onClose={onAddBuildingClose}
          buildingInfo={undefined}
        />
      </Stack>
      <Sidebar isOpen={isDrawerOpen} onToggle={onToggle} />
    </>
  );
}
