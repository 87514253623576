import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { deleteBuilding } from "../../api";
import { useNavigate } from "react-router-dom";

interface IAlertProps {
  isOpen: boolean;
  onClose: () => void;
  buildingId: number;
}
export default function BuildingDeleteAlertDialog({
  isOpen,
  onClose,
  buildingId,
}: IAlertProps) {
  const navigate = useNavigate();
  const cancelRef = useRef(null);
  const toast = useToast();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: deleteBuilding,
    onSuccess: (data) => {
      toast({
        title: "삭제되었습니다!",
        status: "success",
        description: "건물 삭제 완료!",
      });
      queryClient.refetchQueries({ queryKey: ["building_list"] });
      // queryClient.removeQueries({ queryKey: ["building", buildingId] });
      navigate("/building");
    },
    onError: (error) => {
      console.log(error);
      console.log("건물 삭제 실패!");
      toast({
        title: "건물 삭제 실패!",
        status: "error",
        description: "건물 삭제 실패, 다시 시도해 주세요!",
      });
    },
    onSettled: () => {
      onClose();
    },
  });
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            삭제
          </AlertDialogHeader>
          <AlertDialogBody>정말로 삭제하시겠습니까?</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              취소
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => mutation.mutate(buildingId)}
            >
              삭제
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
